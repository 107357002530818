export const DigitsNumberValidation = (value, error, min, max) => {
    const reqString = value?.toString();
    const regex = /^[0-9]+$/;
        if (!value) {
            return `${error} is required`;
        } else if (!regex.test(value)) {
            return `${error} can only contain numbers`;
        } else if (value < 1) {
            return `${error} should be greater than 1 characters`;
        } else if (min && reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } 
        return '';
}

export const notRequiredNumberValidation = (value, error, min, max) => {
    const reqString = value?.toString();
    const regex = /^[0-9]+$/;
        if (!regex.test(value)) {
            return `${error} can only contain numbers`;
        } else if (value < 1) {
            return `${error} should be greater than 1 characters`;
        } else if (min && reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } 
        return '';
}

export const charactersRequiredStringValidation = (value, error, min, max) => {
    const reqString = value?.toString();
        if (!value) {
            return `${error} is required`;
        } else if (max && reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } 
        return '';
}

export const charactersStringValidation = (value, error, min, max) => {
    const reqString = value?.toString();
        if (reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } 
        return '';
}

export const charactersDigitsValidation = (value, error, min, max) =>{
    const reqString = value?.toString();
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (!value) {
        return `${error} is required`;
    } else if (!regex.test(value)) {
        return `${error} can only contain alphabets and numbers`;
    } else if (min && reqString?.length < min) {
        return `${error} should be greater than ${min} characters`;
    } else if (max && reqString?.length > max) {
        return `${error} should be less than ${max} characters`;
    } 
    return '';
}