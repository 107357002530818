import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Marker,useJsApiLoader } from '@react-google-maps/api';
import axios from "axios";

const containerStyle = {
  width: '100%',
  height: '400px'
};

// Set initial coordinates (this can be from your place ID or another source)
const initialCenter = {
  lat: 17.387140,
  lng: 78.491684 
}

const GoogleMapNew = ({markData,handleLocationSelect}) => {

  const reqKey = process.env.REACT_APP_G_KEY;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'YOUR_API_KEY',
  })

  const [map, setMap] = useState(null)
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(initialCenter)
    map.fitBounds(bounds)

    setMap(map)
  }, [])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [markerPosition, setMarkerPosition] = useState(initialCenter);

  const handleGetPlaceID = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${reqKey}`
      );
      if (response.data && response.data.results && response.data.results.length > 0) {
        const value = {
          place_id : response.data.results[0].place_id
        }
        handleLocationSelect(value)
      } else {
        console.error('No results found');
      }
    } catch (error) {
      console.error('Error fetching Place ID:', error);
    }
  };
  const handleMarkerDragEnd = useCallback((e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };
    setMarkerPosition(newPosition);
    handleGetPlaceID(newPosition?.lat, newPosition?.lng)
  }, []);

  useEffect(() => {
    if(markData?.lat && markData?.lng){
      setMarkerPosition(markData)
    }
  }, [markData]);

  const mapOptions = {
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  return isLoaded ?(
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={17}
        disableDefaultUI={true}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          position={markerPosition}
          draggable
          onDragEnd={handleMarkerDragEnd}
          icon={{
            // url: 'https://i.ibb.co/V24Ydt7/Pin-Drop3.png',
            url: 'https://spotlet-files.s3.amazonaws.com/spotlet/images/mapMarker/SpotletMapLogo.png',
            // anchor: new google.maps.Point(30,30),
            scaledSize: new window.google.maps.Size(230, 90)
          }}
        />
      </GoogleMap>
  ) : <span>Google map loading...</span>
};

export default React.memo(GoogleMapNew);
