import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty, updateProperty } from "../../services/api";
import { useState,useEffect } from "react";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_5_sp_listing_film from "../Images/BecomeHost/step_5_sp_listing_film.webp";
//images end
import { DigitsNumberValidation } from "../Validations";

const TIME_OPTIONS = [
    { value: "00:00 AM", label: "00:00 AM" },
    { value: "01:00 AM", label: "01:00 AM" },
    { value: "02:00 AM", label: "02:00 AM" },
    { value: "03:00 AM", label: "03:00 AM" },
    { value: "04:00 AM", label: "04:00 AM" },
    { value: "05:00 AM", label: "05:00 AM" },
    { value: "06:00 AM", label: "06:00 AM" },
    { value: "07:00 AM", label: "07:00 AM" },
    { value: "08:00 AM", label: "08:00 AM" },
    { value: "09:00 AM", label: "09:00 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "01:00 PM", label: "01:00 PM" },
    { value: "02:00 PM", label: "02:00 PM" },
    { value: "03:00 PM", label: "03:00 PM" },
    { value: "04:00 PM", label: "04:00 PM" },
    { value: "05:00 PM", label: "05:00 PM" },
    { value: "06:00 PM", label: "06:00 PM" },
    { value: "07:00 PM", label: "07:00 PM" },
    { value: "08:00 PM", label: "08:00 PM" },
    { value: "09:00 PM", label: "09:00 PM" },
    { value: "10:00 PM", label: "10:00 PM" },
    { value: "11:00 PM", label: "11:00 PM" },
    { value: "11:59 PM", label: "11:59 PM" },
];

const INITIAL_DATA = {
    full_day : false,
    half_day : false,
    per_hour : false, 
    full_day_from : "",
    full_day_to : "",
    full_day_price : "",
    half_day_from : "",
    half_day_to : "",
    half_day_price : "",
    per_hour_price : "",
    per_hour_minimum_booking_hours : "",

    // extra_hours_film_charge
    extra_hours_film_charge_availability : "",
    extra_hours_film_price : "",

    //tvExtraDiscount
    tv_discount : "",
    tv_discount_percentage : "",

    // filming_outside_standard_hours
    filming_outside_standard_hours : "",
    
    // extra_hours_tv_charge
    extra_hours_tv_charge_availability : "",
    extra_hours_tv_price : "",

    //property capacity
    special_discount : "",
    
    //property capacity
    property_capacity : "",

    // facility_features
    venue_type : "",
    venue_ac : "",

    // heavy equipment
    heavy_equipment : "",

    // damage settlement
    damages_settlement : "",
    damages_settlement_other : "",

    // noise restrictions
    noise_restrictions : "",

    //Additional Requirements & Policies
    security_deposit_required : "",
    insurance_proof_required : "",
    permits_required : "",
    
    //  policies you wish to apply for your location
    location_access_filming_allowed : "",
    location_access_filming_hours : "",
    dismantling_time : "",
    dismantling_restoration : "",
    permits_approval : "",
    permits_promotional : "",
    insurance_proof : "",
    insurance_security_deposit : "",
    health_safety_regulations : "",
    guest_access_closed : "",
    guest_access_pre_approved : "",
    cleanliness_waste_same_condition : "",
    cleanliness_waste_proper_disposal : "",

    full_refund : false,
    partial_refund : false,
    no_refund : false,
    full_refund_days : '',
    partial_refund_percentage : '',
    partial_refund_days : '',
    // no_refund_days : '',

    // custom policies
    custom_policies : '',
}

const FilmNew = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [bookingTypes,setBookingTypes] = useState({
        film : null,
        event : null,
        stay : null,
    })

    const [showLoader,setShowLoader] = useState(false);
    const [formData,setFormData] = useState({...INITIAL_DATA});
    const [errors,setErrors] = useState({});

    const onBack = () => {
        if(bookingTypes?.event){
            navigate(`/apps/property/event/${location_id}`)
            return null
        }
        if(bookingTypes?.stay){
            navigate(`/apps/property/stay/${location_id}`)
            return null
        }
        navigate(`/apps/property/amenities/${location_id}`)
    }

    const validateNumber = (value) => {
        if (value && Number.isInteger(Number(value)) && parseInt(value) > 0) {
            return '';
        } else {
            return 'Enter valid data';
        }
    }

    const validateMinBookingHrs = (value) => {
        if (value && ( parseInt(value) > 0 && parseInt(value) <25 ) ) {
            return '';
        } else {
            return 'Invalid data';
        }
    }
    // const validateHalfDayTimings = (from, to) => {
    //     // Helper function to convert time string to minutes since midnight
    //     const timeToMinutes = (time) => {
    //         const [hour, minute] = time.match(/\d+/g).map(Number);
    //         const isPM = time.includes("PM");
    //         const isMidnight = time.includes("12") && !isPM;
    
    //         // Convert to 24-hour format
    //         const adjustedHour = isPM && !isMidnight ? (hour % 12) + 12 : hour % 12;
    //         return adjustedHour * 60 + minute;
    //     };
    
    //     // Get minutes for 'from' and 'to'
    //     const fromMinutes = timeToMinutes(from);
    //     const toMinutes = timeToMinutes(to);
    
    //     // Calculate difference considering overnight wrap
    //     let difference = toMinutes - fromMinutes;
    //     if (difference < 0) {
    //         // If 'to' is the next day
    //         difference += 24 * 60;
    //     }
    
    //     // Validate against the 12-hour limit
    //     return difference <= 720; // 720 minutes = 12 hours
    // }

    const validateForm = () => {
        const newErrors = {
            film_type : (formData?.full_day || formData?.half_day || formData?.per_hour ) ? '' : 'Select any one of the above options',
            ...(formData?.full_day && {
                full_day_from : formData?.full_day_from ? "" : "Please select start timing",
                full_day_to : formData?.full_day_to ? "" : "Please select end timing",
                full_day_price : formData?.full_day_price ? validateNumber(formData?.full_day_price) : "Please enter price",
            }),
            ...(formData?.half_day && {
                half_day_from : formData?.half_day_from ? "" : "Please select start timing",
                half_day_to : formData?.half_day_to ? "" : "Please select end timing",
                half_day_price : formData?.half_day_price ? validateNumber(formData?.half_day_price) : "Please enter price",
                // ...((formData?.half_day_from && formData?.half_day_to) && {
                //     half_day_timings : validateHalfDayTimings(formData?.half_day_from, formData?.half_day_to) ? "" : "From and to difference should not be greater than 12 hours" ,
                // })
            }),
            ...(formData?.per_hour && {
                per_hour_price : formData?.per_hour_price ? DigitsNumberValidation(formData?.per_hour_price,"Price",1,8) : "Please enter price",
                per_hour_minimum_booking_hours : formData?.per_hour_minimum_booking_hours ? DigitsNumberValidation(formData?.per_hour_minimum_booking_hours,"Hours",1,5) : "Please enter minimum booking hours",
            }),
            ...(formData?.extra_hours_film_charge_availability === "Yes" && {
                extra_hours_film_price : formData?.extra_hours_film_price ? DigitsNumberValidation(formData?.extra_hours_film_price,"Price",1,8) : "Please enter hourly extra price",
            }),
            ...(formData?.tv_discount === "Yes, discounted rates are available for TV shoots" && {
                tv_discount_percentage : formData?.tv_discount_percentage ? formData?.tv_discount_percentage < 0 || formData?.tv_discount_percentage > 99 ? 'Discount should be 0 to 99' : ''  : "Please enter discount",
            }),
            ...(formData?.extra_hours_tv_charge_availability === "Yes" && {
                extra_hours_tv_price : formData?.extra_hours_tv_price ? DigitsNumberValidation(formData?.extra_hours_tv_price,"Price",1,8) : "Please enter hourly extra price",
            }),
            special_discount : formData?.special_discount ? formData?.special_discount < 0 || formData?.special_discount > 99 ? 'Discount should be 0 to 99' : '' : '',
            property_capacity : formData?.property_capacity ? DigitsNumberValidation(formData?.property_capacity,"People",1,5) : "Please enter property capacity",
            ...((formData?.venue_type === "Indoor" || formData?.venue_type === "Both Indoor & Outdoor" )  && {
                venue_ac : formData?.venue_ac ? "" : "Please select any of the above options",
            }),
            venue_type : formData?.venue_type ? '' : 'Venue type is required',
            damages_settlement_other : formData?.damages_settlement_other ? validateNumber(formData?.damages_settlement_other) : '',

            cancellation : (formData?.full_refund||formData?.partial_refund|| formData?.no_refund) ? "" : 'Select minimum one cancellation policy',
            full_refund_days : formData?.full_refund === true ? formData?.full_refund_days ?  validateNumber(formData?.full_refund_days) : 'Add days' : '',
            partial_refund_percentage : formData?.partial_refund === true ? formData?.partial_refund_percentage ? (formData?.partial_refund_percentage < 0 || formData?.partial_refund_percentage > 99) ? 'Percentage should be 0 to 99' : '' : 'Add percentage' : '',
            partial_refund_days : formData?.partial_refund === true ? formData?.partial_refund_days ? validateNumber(formData?.partial_refund_days) : 'Add days' : '',
            // no_refund_days : formData?.no_refund === true ? formData?.no_refund_days ? validateNumber(formData?.no_refund_days) : 'Add hours' : '',
        }
        console.log(newErrors)
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                // const inputElement = document.getElementById(key);
                // if (inputElement){
                //     const headerHeight = 130;
                //     scrollToElementWithOffset(inputElement, headerHeight);
                // }
                return false
            }
        }
        return true
    }
    function trimFormData(formData) {
        // Check if the formData is an object
        if (typeof formData === 'object' && formData !== null) {
          if (Array.isArray(formData)) {
            // If it's an array, loop through each element and trim spaces in strings
            return formData.map(trimFormData);
          } else {
            // If it's an object, loop through each key-value pair
            const trimmedObj = {};
            for (const key in formData) {
              if (formData.hasOwnProperty(key)) {
                trimmedObj[key] = trimFormData(formData[key]);
              }
            }
            return trimmedObj;
          }
        }
      
        // If it's a string, trim leading and trailing spaces
        if (typeof formData === 'string') {
          return formData.trim();
        }
      
        // If it's null or undefined, return as-is
        return formData;
    }
    const onSubmit = async() => {
        if(!validateForm()){
            // scrollToTop()
            return null
        }
        setShowLoader(true)
        try{
            const resp = await updateProperty({film_shooting : trimFormData(formData)},location_id)
        }catch(error){
            console.log(error)
            return null
        }
        setShowLoader(false)
        navigate(`/apps/property/description/${location_id}`)
            return null
    }
    const getData = async () => {
        try{
            const resp = await getProperty(location_id);
            if(resp.data?.film_shooting?.property_capacity){
                setFormData({
                    ...resp.data?.film_shooting,
                })
            }
            setBookingTypes({
                film : resp?.data?.film || null,
                event : resp?.data?.event || null,
                stay : resp?.data?.stay || null,
            })
        }catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id]);


    const onChange = (name, value) => {
        setFormData({
            ...formData,
            [name] : value,
            ...((name === "venue_type" && value === "Outdoor") && { "venue_ac" : "" }),
            ...((name === "extra_hours_film_charge_availability" && value === "No, extra hours are included in the existing rate") && { "extra_hours_film_price" : "" }),
            ...((name === "extra_hours_tv_charge_availability" && value === "No, extra hours are included in the existing rate") && { "extra_hours_tv_price" : "" }),
            ...((name === "tv_discount" && value === "No, extra hours are included in the existing rate") && { "tv_discount_percentage" : "" }),
            ...(name === "damages_settlement_other" && { "damages_settlement" : "" }),
            ...(name === "damages_settlement" && { "damages_settlement_other" : "" }),
            ...((name === "full_refund" && value === true ) && { "no_refund" : false }),
            ...((name === "partial_refund" && value === true ) && { "no_refund" : false }),
            ...((name === "no_refund" && value === true ) && { "full_refund" : false, "full_refund_days" : "", "partial_refund" : false, "partial_refund_percentage" : "",  "partial_refund_days" : ""  }),
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }

    const onChange2 = (e, value) => {
        const {checked, name} = e?.target;
        setFormData({
            ...formData,
            [name] : checked ? value : "",
        })
    }

    const onChange3 = (e) => {
        const {checked, name} = e?.target;
        setFormData({
            ...formData,
            [name] : checked,
            ...((name === "full_day" && !checked) && { 
                full_day_from : "",
                full_day_to : "",
                full_day_price : "",
            }),
            ...((name === "half_day" && !checked) && { 
                half_day_from : "",
                half_day_to : "",
                half_day_price : "",
            }),
            ...((name === "per_hour" && !checked) && { 
                per_hour_price : "",
                per_hour_minimum_booking_hours : "",
            }),
        })
        setErrors({ ...errors, "film_type": '' });
    }


    const fullDayFilm = () => {
        return(
            <>
                <span className="content"> Full Day Filming: </span>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100" >
                    <div className="mt mr-1 w-100">
                        <span className="content text_color_1">From{' '}<label className="required">*</label></span>
                        <select
                            className="become_a_host_text_input"
                            name="full_day_from"
                            placeholder="Select time"
                            value={formData?.full_day_from}
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                        >  
                            <option value={''} selected disabled className="content text_color_1">Select time</option>
                            {TIME_OPTIONS?.map((each,index) => {
                                return(
                                    <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                )
                            })}
                        </select>
                        {errors?.full_day_from && <span className="content required">{errors?.full_day_from}</span>}
                    </div>
                    <div className="mt w-100 mr-1">
                        <span className="content text_color_1">To{' '}<label className="required">*</label></span>
                        <select
                            className="become_a_host_text_input"
                            name="full_day_to"
                            placeholder="Select time"
                            value={formData?.full_day_to}
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                        >
                            <option value={''} selected disabled className="content text_color_1">Select time</option>
                            {TIME_OPTIONS?.map((each,index) => {
                                return(
                                    <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                )
                            })}
                        </select>
                        {errors?.full_day_to && <span className="content required">{errors?.full_day_to}</span>}
                    </div>
                    <div className="w-100 ml-2 mt">
                        <label htmlFor='full_day_price' className="content text_color_1">Price for Full Day Filming:{' '}<label className="required">*</label></label>
                        <input
                            type="number"
                            className='become_a_host_text_input'
                            name="full_day_price"
                            value={formData?.full_day_price }
                            id="full_day_price"
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                            placeholder="₹"
                            autoComplete="off"
                        />
                        {errors?.full_day_price && <span className="content required">{errors?.full_day_price}</span>}
                    </div>
                </div>
            </>
        )
    }

    const halfDayFilm = () => {
        return(
            <>
                <span className="content"> Half Day Filming: </span>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100" style={{marginTop : "-10px"}}>
                    <div className="mt mr-1 w-100">
                        <span className="content text_color_1">From{' '}<label className="required">*</label></span>
                        <select
                            className="become_a_host_text_input"
                            name="half_day_from"
                            placeholder="Select time"
                            value={formData?.half_day_from}
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                        >  
                            <option value={''} selected disabled className="content text_color_1">Select time</option>
                            {TIME_OPTIONS?.map((each,index) => {
                                return(
                                    <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                )
                            })}
                        </select>
                        {errors?.half_day_from && <span className="content required">{errors?.half_day_from}</span>}
                    </div>
                    <div className="mt w-100 mr-1">
                        <span className="content text_color_1">To{' '}<label className="required">*</label></span>
                        <select
                            className="become_a_host_text_input"
                            name="half_day_to"
                            placeholder="Select time"
                            value={formData?.half_day_to}
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                        >
                            <option value={''} selected disabled className="content text_color_1">Select time</option>
                            {TIME_OPTIONS?.map((each,index) => {
                                return(
                                    <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                )
                            })}
                        </select>
                        {errors?.half_day_to && <span className="content required">{errors?.half_day_to}</span>}
                    </div>
                    <div className="w-100 ml-2 mt">
                        <label htmlFor='half_day_price' className="content text_color_1">Price for Half Day Filming:{' '}<label className="required">*</label></label>
                        <input
                            type="number"
                            className='become_a_host_text_input'
                            name="half_day_price"
                            value={formData?.half_day_price }
                            id="half_day_price"
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                            placeholder="₹"
                            autoComplete="off"
                        />
                        {errors?.half_day_price && <span className="content required">{errors?.half_day_price}</span>}
                    </div>
                </div>
                {errors?.half_day_timings && <span className="content required"> {errors?.half_day_timings} </span>}
            </>
        )
    }

    const perHourFilm = () => {
        return(
            <>
                <span className="content"> Per Hour Filming: </span>
                {/* <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center w-100" style={{marginTop : "-10px"}}>
                </div> */}
                <div className="w-100" >
                    <label htmlFor='per_hour_price' className="content text_color_1">Price Per Hour:{' '}<label className="required">*</label></label>
                    <input
                        type="number"
                        className='become_a_host_text_input'
                        name="per_hour_price"
                        value={formData?.per_hour_price }
                        id="per_hour_price"
                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                        placeholder="₹"
                        autoComplete="off"
                    />
                    {errors?.per_hour_price && <span className="content required">{errors?.per_hour_price}</span>}
                </div>
                <div className="w-100 mt mr-1">
                    <label htmlFor='per_hour_minimum_booking_hours' className="content text_color_1">Minimum Booking Hours for Per-Hour Rentals{' '}<label className="required">*</label></label>
                    <input
                        type="number"
                        className='become_a_host_text_input'
                        name="per_hour_minimum_booking_hours"
                        value={formData?.per_hour_minimum_booking_hours }
                        id="per_hour_minimum_booking_hours"
                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                        placeholder="Enter Number"
                        autoComplete="off"
                    />
                    {errors?.per_hour_minimum_booking_hours && <span className="content required">{errors?.per_hour_minimum_booking_hours}</span>}
                </div>
            </>
        )
    }

    const filmExtraHours = () => {
        return(
            <>
                <span className="content text_color_1 mt">For extra hours, do you have specific charges for Film?</span>
                <div className="d-flex flex-column justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt mr-2">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='extra_hours_film_yes'
                            name='extra_hours_film_charge_availability'
                            checked={'Yes' === formData?.extra_hours_film_charge_availability}
                            onChange={(e)=>onChange(e.target.name,'Yes')}
                        />
                        <label htmlFor='extra_hours_film_yes' className={`content text_color_4`}>Yes</label>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap ml-2">
                            <input
                                type="number"
                                className="price_input"
                                name="extra_hours_film_price"
                                placeholder="Price for per extra hour"
                                value={formData?.extra_hours_film_price}
                                onChange={(e)=> onChange(e.target.name, e.target.value)}
                            />
                        </div>
                    </div>
                    {errors?.extra_hours_film_price && <span className="content required"> {errors?.extra_hours_film_price} </span>}
                    <div className="TP_main_check_box_wrap mt w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='extra_hours_film_no'
                            name='extra_hours_film_charge_availability'
                            checked={'No, extra hours are included in the existing rate' === formData?.extra_hours_film_charge_availability}
                            onChange={(e)=>onChange(e.target.name,'No, extra hours are included in the existing rate')}
                        />
                        <label htmlFor='extra_hours_film_no' className={`content text_color_4`}>No, extra hours are included in the existing rate</label>
                    </div>
                </div>
            </>
        )
    }

    const tvExtraDiscount = () => {
        return(
            <>
                <span className="content text_color_1 mt">Is there a discounted rate for TV shoots compared to film shoots?</span>
                <div className="d-flex flex-column justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='tv_discoumt_yes'
                            name='tv_discount'
                            checked={'Yes, discounted rates are available for TV shoots' === formData?.tv_discount}
                            onChange={(e)=>onChange(e.target.name,'Yes, discounted rates are available for TV shoots')}
                        />
                        <label htmlFor='tv_discoumt_yes' className={`content text_color_4`}>Yes, discounted rates are available for TV shoots</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='tv_discoumt_no'
                            name='tv_discount'
                            checked={'No, extra hours are included in the existing rate' === formData?.tv_discount}
                            onChange={(e)=>onChange(e.target.name,'No, extra hours are included in the existing rate')}
                        />
                        <label htmlFor='tv_discoumt_no' className={`content text_color_4`}>No, extra hours are included in the existing rate</label>
                    </div>
                </div>
                { formData?.tv_discount === "Yes, discounted rates are available for TV shoots" && (
                    <>
                        <div className="mt">
                            <label htmlFor='' className={`content text_color_1`}>How much of discount do you offer for TV Shoots? {" "} <span className="required"> * </span></label>
                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <input
                                    type="number"
                                    className="price_input"
                                    name="tv_discount_percentage"
                                    placeholder="Enter % Number"
                                    value={formData?.tv_discount_percentage}
                                    onChange={(e)=> onChange(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                        {errors?.tv_discount_percentage && <span className="content required"> {errors?.tv_discount_percentage} </span>}
                    </>

                )}
            </>
        )
    }

    const tvExtraHours = () => {
        return(
            <>
                <span className="content text_color_1 mt">For extra hours, do you have specific charges for Tv?</span>
                <div className="d-flex flex-column justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt mr-2">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='extra_hours_tv_yes'
                            name='extra_hours_tv_charge_availability'
                            checked={'Yes' === formData?.extra_hours_tv_charge_availability}
                            onChange={(e)=>onChange(e.target.name,'Yes')}
                        />
                        <label htmlFor='extra_hours_tv_yes' className={`content text_color_4`}>Yes</label>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap ml-2">
                            <input
                                type="number"
                                className="price_input"
                                name="extra_hours_tv_price"
                                placeholder="Price for per extra hour"
                                value={formData?.extra_hours_tv_price}
                                onChange={(e)=> onChange(e.target.name, e.target.value)}
                            />
                        </div>
                        {errors?.extra_hours_tv_price && <span className="required"> {errors?.extra_hours_tv_price} </span>}
                    </div>
                    <div className="TP_main_check_box_wrap mt w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='extra_hours_tv_no'
                            name='extra_hours_tv_charge_availability'
                            checked={'No, extra hours are included in the existing rate' === formData?.extra_hours_tv_charge_availability}
                            onChange={(e)=>onChange(e.target.name,'No, extra hours are included in the existing rate')}
                        />
                        <label htmlFor='extra_hours_tv_no' className={`content text_color_4`}>No, extra hours are included in the existing rate</label>
                    </div>
                </div>
            </>
        )
    }

    const facilityFeatures = () => {
        return(
            <>
                <span className="content heading_color_1">Facility Features</span>
                <span className="content text_color_1 mt">Is the venue indoor or outdoor?{' '}<span className="required">*</span></span>
                <div className="d-flex flex-column justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt mr-2">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='venue_type_indoor'
                            name='venue_type'
                            checked={'Indoor' === formData?.venue_type}
                            onChange={(e)=>onChange(e.target.name,'Indoor')}
                        />
                        <label htmlFor='venue_type_indoor' className={`content text_color_4`}>Indoor</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='venue_type_outdoor'
                            name='venue_type'
                            checked={'Outdoor' === formData?.venue_type}
                            onChange={(e)=>onChange(e.target.name,'Outdoor')}
                        />
                        <label htmlFor='venue_type_outdoor' className={`content text_color_4`}>Outdoor</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='venue_type_both'
                            name='venue_type'
                            checked={'Both Indoor & Outdoor' === formData?.venue_type}
                            onChange={(e)=>onChange(e.target.name,'Both Indoor & Outdoor')}
                        />
                        <label htmlFor='venue_type_both' className={`content text_color_4`}>Both</label>
                    </div>
                </div>
                {errors?.venue_type && <span className="content required"> {errors?.venue_type} </span>}
                {(formData?.venue_type === "Indoor" || formData?.venue_type === "Both Indoor & Outdoor")  && (
                    <>
                        <span className="content text_color_1 mt">If indoor, is the venue air-conditioned? {" "}<span className="required"> * </span> </span>
                        <div className="d-flex flex-row justify-content-start align-items-center w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='venue_ac_yes'
                                    name='venue_ac'
                                    checked={'Yes' === formData?.venue_ac}
                                    onChange={(e)=>onChange(e.target.name,'Yes')}
                                />
                                <label htmlFor='venue_ac_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='venue_ac_no'
                                    name='venue_ac'
                                    checked={'No' === formData?.venue_ac}
                                    onChange={(e)=>onChange(e.target.name,'No')}
                                />
                                <label htmlFor='venue_ac_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>
                        {errors?.venue_ac && <span className="content required"> {errors?.venue_ac} </span>}
                    </>
                )}
            </>
        )
    }

    const equipmentPolicies = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1">Equipment Policies</span>
                <span className="content text_color_1 mt">Does the property allow heavy equipment (cranes, rigs, large machinery)?</span>
                <div className="d-flex flex-column flex-md-row justify-content-start align-items-center w-100">
                    <div className="TP_main_check_box_wrap mt mr-2">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='heavy_equipment_yes'
                            name='heavy_equipment'
                            checked={'Yes, with pre-approval' === formData?.heavy_equipment}
                            onChange={(e)=>onChange(e.target.name,'Yes, with pre-approval')}
                        />
                        <label htmlFor='heavy_equipment_yes' className={`content text_color_4`}>Yes, with pre-approval</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='heavy_equipment_no'
                            name='heavy_equipment'
                            checked={'No' === formData?.heavy_equipment}
                            onChange={(e)=>onChange(e.target.name,'No')}
                        />
                        <label htmlFor='heavy_equipment_no' className={`content text_color_4`}>No</label>
                    </div>
                </div>
            </>
        )
    }

    const damageAndRestorationPolicies = () => {
        return(
            <>
                <span className="content heading_color_1">Damage & Restoration Policies</span>
                <span className="content text_color_1 mt">Are minor painting changes allowed for shoots, with the condition of restoring to the original color?</span>
                <div className="d-flex flex-column flex-md-row justify-content-start align-items-center w-100">
                    <div className="TP_main_check_box_wrap mt mr-2">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='painting_changes_yes'
                            name='painting_changes'
                            checked={'Yes, with prior approval' === formData?.painting_changes}
                            onChange={(e)=>onChange(e.target.name,'Yes, with prior approval')}
                        />
                        <label htmlFor='painting_changes_yes' className={`content text_color_4`}>Yes, with prior approval</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='painting_changes_no'
                            name='painting_changes'
                            checked={'No' === formData?.painting_changes}
                            onChange={(e)=>onChange(e.target.name,'No')}
                        />
                        <label htmlFor='painting_changes_no' className={`content text_color_4`}>No</label>
                    </div>
                </div>
                <span className="content heading_color_1 mt">Do you require damage payments to be settled on the same day before leaving the premises?</span>
                <div className="d-flex flex-column justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='damages_settlement_yes'
                            name='damages_settlement'
                            checked={'Yes, all damages must be paid before departure' === formData?.damages_settlement}
                            onChange={(e)=>onChange(e.target.name,'Yes, all damages must be paid before departure')}
                        />
                        <label htmlFor='damages_settlement_yes' className={`content text_color_4`}>Yes, all damages must be paid before departure</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='damages_settlement_no'
                            name='damages_settlement'
                            checked={'No, payment can be arranged after the shoot' === formData?.damages_settlement}
                            onChange={(e)=>onChange(e.target.name,'No, payment can be arranged after the shoot')}
                        />
                        <label htmlFor='damages_settlement_no' className={`content text_color_4`}>No, payment can be arranged after the shoot</label>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center flex-wrap mt" style={{gap : "10px"}}>
                    <label htmlFor='' className={`content text_color_1`}>Other:</label>
                    <input
                        type="text"
                        className="price_input"
                        name="damages_settlement_other"
                        placeholder="Others"
                        value={formData?.damages_settlement_other}
                        onChange={(e)=> onChange(e.target.name, e.target.value)}
                    />
                </div>
                {errors?.damages_settlement_other && <span className="content required"> {errors?.damages_settlement_other} </span>}
            </>
        )
    }

    const noiseRestrictions = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1 mt">Noise Restrictions</span>
                <div className="d-flex flex-column justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='noise_restrictions_yes'
                            name='noise_restrictions'
                            checked={'Noise levels must be kept low, especially after 10:00 PM' === formData?.noise_restrictions}
                            onChange={(e)=>onChange(e.target.name,'Noise levels must be kept low, especially after 10:00 PM')}
                        />
                        <label htmlFor='noise_restrictions_yes' className={`content text_color_4`}>Noise levels must be kept low, especially after 10:00 PM</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='noise_restrictions_no'
                            name='noise_restrictions'
                            checked={'No restrictions' === formData?.noise_restrictions}
                            onChange={(e)=>onChange(e.target.name,'No restrictions')}
                        />
                        <label htmlFor='noise_restrictions_no' className={`content text_color_4`}>No restrictions</label>
                    </div>
                </div>
            </>
        )
    }

    const additionalRequirementsPolicies = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1 mt">Additional Requirements & Policies</span>
                <span className="content text_color_1 mt">Is a security deposit required?</span>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='security_deposit_yes'
                            name='security_deposit_required'
                            checked={'Yes, refundable upon property inspection' === formData?.security_deposit_required}
                            onChange={(e)=>onChange(e.target.name,'Yes, refundable upon property inspection')}
                        />
                        <label htmlFor='security_deposit_yes' className={`content text_color_4`}>Yes, refundable upon property inspection</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='security_deposit_no'
                            name='security_deposit_required'
                            checked={'No' === formData?.security_deposit_required}
                            onChange={(e)=>onChange(e.target.name,'No')}
                        />
                        <label htmlFor='security_deposit_no' className={`content text_color_4`}>No</label>
                    </div>
                </div>
                
                {/* Proof on insurance start */}
                <span className="content text_color_1 mt">Is proof of insurance required for film production?</span>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='proof_of_insurance_yes'
                            name='insurance_proof_required'
                            checked={'Yes' === formData?.insurance_proof_required}
                            onChange={(e)=>onChange(e.target.name,'Yes')}
                        />
                        <label htmlFor='proof_of_insurance_yes' className={`content text_color_4`}>Yes</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='proof_of_insurance_no'
                            name='insurance_proof_required'
                            checked={'No' === formData?.insurance_proof_required}
                            onChange={(e)=>onChange(e.target.name,'No')}
                        />
                        <label htmlFor='proof_of_insurance_no' className={`content text_color_4`}>No</label>
                    </div>
                </div>
                {/* Proof on insurance end */}

                {/* Venue permits start */}
                <span className="content text_color_1 mt">Does the venue require any permits or approvals for filming?</span>
                <div className="d-flex flex-row justify-content-start align-items-center w-100">
                    <div className="TP_main_check_box_wrap mt mr-2 ">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='permits_required_yes'
                            name='permits_required'
                            checked={'Yes, permits required' === formData?.permits_required}
                            onChange={(e)=>onChange(e.target.name,'Yes, permits required')}
                        />
                        <label htmlFor='permits_required_yes' className={`content text_color_4`}>Yes, permits required</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt ">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='permits_required_no'
                            name='permits_required'
                            checked={'No permits required' === formData?.permits_required}
                            onChange={(e)=>onChange(e.target.name,'No permits required')}
                        />
                        <label htmlFor='permits_required_no' className={`content text_color_4`}>No permits required</label>
                    </div>
                </div>
                {/* Venue permits end */}
            </>
        )
    }

    const cancellation = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1">Cancellation Policy{' '}<span className="required">*</span></span>
                <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                    <input
                        type="checkbox"
                        className="become_a_host_check"
                        id='full_refund_yes'
                        name='full_refund'
                        checked={formData?.full_refund}
                        onChange={(e)=>onChange(e.target.name,formData?.full_refund ? false : true)}
                    />
                    <label htmlFor='full_refund_yes' className={`content text_color_4`}>Full refund for cancellations made up to{' '}
                    <input
                        type="number"
                        className="price_input"
                        name="full_refund_days"
                        placeholder=""
                        style={{width : '46px'}}
                        value={formData?.full_refund_days}
                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                    />
                        {' '}days before the stay.</label>
                </div>
                {errors?.full_refund_days && <span className="content required">{errors?.full_refund_days}</span>}
                <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                    <input
                        type="checkbox"
                        className="become_a_host_check"
                        id='partial_refund_yes'
                        name='partial_refund'
                        checked={formData?.partial_refund}
                        onChange={(e)=>onChange(e.target.name,formData?.partial_refund ? false : true)}
                    />
                    <label htmlFor='partial_refund_yes' className={`content text_color_4`}>Partial refund of{' '}
                    <input
                        type="number"
                        className="price_input"
                        name="partial_refund_percentage"
                        placeholder=""
                        style={{width : '46px'}}
                        value={formData?.partial_refund_percentage}
                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                    />
                    {' '}% if canceled within{' '}
                    <input
                        type="number"
                        className="price_input"
                        name="partial_refund_days"
                        placeholder=""
                        style={{width : '46px'}}
                        value={formData?.partial_refund_days}
                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                    />
                    {' '}days before check-in.
                    </label>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-start" style={{gap : '5px'}}>
                    {errors?.partial_refund_percentage && <span className="content required">{errors?.partial_refund_percentage}</span>}
                    {errors?.partial_refund_days && <span className="content required">{errors?.partial_refund_percentage &&'and'}</span>}
                    {errors?.partial_refund_days && <span className="content required">{errors?.partial_refund_days}</span>}
                </div>
                <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                    <input
                        type="checkbox"
                        className="become_a_host_check"
                        id='no_refund_yes'
                        name='no_refund'
                        checked={formData?.no_refund}
                        onChange={(e)=>onChange(e.target.name,formData?.no_refund ? false : true)}
                    />
                    <label htmlFor='no_refund_yes' className={`content text_color_4`}>No refund 
                        {/* for cancellations made less than{' '} */}
                        {/* <input
                            type="number"
                            className="price_input"
                            name="no_refund_days"
                            placeholder=""
                            style={{width : '46px'}}
                            value={formData?.no_refund_days}
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                        /> */}
                        {/* {' '}hours before check-in. */}
                    </label>
                </div>
                {/* {errors?.no_refund_days && <span className="content required">{errors?.no_refund_days}</span>} */}
                {errors?.cancellation && <span className="content required">{errors?.cancellation}</span>}
            </>
        )
    }

    const locationGeneralPolicies = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1 mt">Select the policies you wish to apply for your location</span>

                <span className="content heading_color_1 mt">Location Access</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='location_1'
                            name='location_access_filming_allowed'
                            checked={'Filming is only allowed in approved areas. Any filming outside these areas requires prior approval from the property owner/host.' === formData?.location_access_filming_allowed}
                            onChange={(e)=>onChange2(e,'Filming is only allowed in approved areas. Any filming outside these areas requires prior approval from the property owner/host.')}
                        />
                        <label htmlFor='location_1' className={`content text_color_4`}>Filming is only allowed in approved areas. Any filming outside these areas requires prior approval from the property owner/host.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-auto">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='location_2'
                            name='location_access_filming_hours'
                            checked={'Shooting hours are typically from 6:00 AM to 6:00 PM. If you require shooting outside these hours, prior approval is required.' === formData?.location_access_filming_hours}
                            onChange={(e)=>onChange2(e,'Shooting hours are typically from 6:00 AM to 6:00 PM. If you require shooting outside these hours, prior approval is required.')}
                        />
                        <label htmlFor='location_2' className={`content text_color_4`}>Shooting hours are typically from 6:00 AM to 6:00 PM. If you require shooting outside these hours, prior approval is required.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Set-Up & Dismantling</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='setup_1'
                            name='dismantling_time'
                            checked={'Set-up and dismantling must occur within agreed-upon time slots to avoid disruptions.' === formData?.dismantling_time}
                            onChange={(e)=>onChange2(e,'Set-up and dismantling must occur within agreed-upon time slots to avoid disruptions.')}
                        />
                        <label htmlFor='setup_1' className={`content text_color_4`}>Set-up and dismantling must occur within agreed-upon time slots to avoid disruptions.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-auto">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='setup_2'
                            name='dismantling_restoration'
                            checked={'The property should be returned to its original condition, including repairs and furniture restoration.' === formData?.dismantling_restoration}
                            onChange={(e)=>onChange2(e,'The property should be returned to its original condition, including repairs and furniture restoration.')}
                        />
                        <label htmlFor='setup_2' className={`content text_color_4`}>The property should be returned to its original condition, including repairs and furniture restoration.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Permits & Licensing</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='permits_1'
                            name='permits_approval'
                            checked={'Ensure all necessary production permits are in place, including approvals from local authorities.' === formData?.permits_approval}
                            onChange={(e)=>onChange2(e,'Ensure all necessary production permits are in place, including approvals from local authorities.')}
                        />
                        <label htmlFor='permits_1' className={`content text_color_4`}>Ensure all necessary production permits are in place, including approvals from local authorities.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-auto">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='permits_2'
                            name='permits_promotional'
                            checked={'Permission must be obtained from the host if the property’s name or image will be used in promotional materials.' === formData?.permits_promotional}
                            onChange={(e)=>onChange2(e,'Permission must be obtained from the host if the property’s name or image will be used in promotional materials.')}
                        />
                        <label htmlFor='permits_2' className={`content text_color_4`}>Permission must be obtained from the host if the property’s name or image will be used in promotional materials.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Insurance & Liability</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='insurance_1'
                            name='insurance_proof'
                            checked={'Provide proof of insurance covering damages, injuries, or accidents during filming.' === formData?.insurance_proof}
                            onChange={(e)=>onChange2(e,'Provide proof of insurance covering damages, injuries, or accidents during filming.')}
                        />
                        <label htmlFor='insurance_1' className={`content text_color_4`}>Provide proof of insurance covering damages, injuries, or accidents during filming.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-auto">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='insurance_2'
                            name='insurance_security_deposit'
                            checked={'A refundable security deposit may be required, provided the property is in the same condition post-shoot.' === formData?.insurance_security_deposit}
                            onChange={(e)=>onChange2(e,'A refundable security deposit may be required, provided the property is in the same condition post-shoot.')}
                        />
                        <label htmlFor='insurance_2' className={`content text_color_4`}>A refundable security deposit may be required, provided the property is in the same condition post-shoot.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Health & Safety</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='health_1'
                            name='health_safety_regulations'
                            checked={'Comply with health and safety regulations, including fire and electrical safety. A first-aid kit must be available on-site.' === formData?.health_safety_regulations}
                            onChange={(e)=>onChange2(e,'Comply with health and safety regulations, including fire and electrical safety. A first-aid kit must be available on-site.')}
                        />
                        <label htmlFor='health_1' className={`content text_color_4`}>Comply with health and safety regulations, including fire and electrical safety. A first-aid kit must be available on-site.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Guest Access</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='guest_access_1'
                            name='guest_access_closed'
                            checked={'The property is closed to non-production guests unless pre-approved by the host.' === formData?.guest_access_closed}
                            onChange={(e)=>onChange2(e,'The property is closed to non-production guests unless pre-approved by the host.')}
                        />
                        <label htmlFor='guest_access_1' className={`content text_color_4`}>The property is closed to non-production guests unless pre-approved by the host.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-auto">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='guest_access_2'
                            name='guest_access_pre_approved'
                            checked={'Any visitors (media representatives or family members) must be pre-approved and registered before entering.' === formData?.guest_access_pre_approved}
                            onChange={(e)=>onChange2(e,'Any visitors (media representatives or family members) must be pre-approved and registered before entering.')}
                        />
                        <label htmlFor='guest_access_2' className={`content text_color_4`}>Any visitors (media representatives or family members) must be pre-approved and registered before entering.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Cleanliness & Waste</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='cleanliness_1'
                            name='cleanliness_waste_same_condition'
                            checked={'The property must be left in the same condition as it was found. Additional cleaning fees may apply for unsatisfactory conditions.' === formData?.cleanliness_waste_same_condition}
                            onChange={(e)=>onChange2(e,'The property must be left in the same condition as it was found. Additional cleaning fees may apply for unsatisfactory conditions.')}
                        />
                        <label htmlFor='cleanliness_1' className={`content text_color_4`}>The property must be left in the same condition as it was found. Additional cleaning fees may apply for unsatisfactory conditions.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-auto">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='cleanliness_2'
                            name='cleanliness_waste_proper_disposal'
                            checked={'Proper waste disposal, including recycling and handling hazardous materials, is required in accordance with local regulations.' === formData?.cleanliness_waste_proper_disposal}
                            onChange={(e)=>onChange2(e,'Proper waste disposal, including recycling and handling hazardous materials, is required in accordance with local regulations.')}
                        />
                        <label htmlFor='cleanliness_2' className={`content text_color_4`}>Proper waste disposal, including recycling and handling hazardous materials, is required in accordance with local regulations.</label>
                    </div>
                </div>
            </>
        )
    }

    const customPolicies = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1">Do you have any specific policies to mention?</span>
                <textarea
                    rows={4}
                    columns={55}
                    id='custom_policies'
                    name='custom_policies'
                    value={formData?.custom_policies}
                    onChange={(e)=> onChange(e.target.name,e.target.value)}
                    placeholder='Enter your custom policies'
                    className='become_a_host_text_input mt'
                />
            </>
        )
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop()
    },[])

    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Film / Tv Shooting Access & Usage Policies</span>
                        <div>
                            <span className="content" > Standard Filming Hours & Pricing </span>
                        </div>
                        <div className="" >
                            <span> Do you want to give your property for ?{" "}<span className="required"> * </span> </span>
                            <div className="d-flex flex-row justify-content-start align-items-center flex-wrap mb-3" >
                                <div className="TP_main_check_box_wrap align-items-center mt mr-3">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        id='full_day'
                                        name='full_day'
                                        checked={formData?.full_day}
                                        onChange={onChange3}
                                    />
                                    <label htmlFor='full_day' className={`sub_text text_color_1`}>Fullday</label>
                                </div>
                                <div className="TP_main_check_box_wrap align-items-center mt mr-3">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        id='half_day'
                                        name='half_day'
                                        checked={formData?.half_day}
                                        onChange={onChange3}
                                    />
                                    <label htmlFor='half_day' className={`sub_text text_color_1`}>Halfday</label>
                                </div>
                                <div className="TP_main_check_box_wrap align-items-center mt mr-3">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        id='per_hour'
                                        name='per_hour'
                                        checked={formData?.per_hour}
                                        onChange={onChange3}
                                    />
                                    <label htmlFor='per_hour' className={`sub_text text_color_1`}>Per hour</label>
                                </div>
                            </div>
                            {errors?.film_type && <span className="content required"> {errors?.film_type} </span>}
                        </div>
                        {formData?.full_day && fullDayFilm()}
                        {formData?.half_day && halfDayFilm()}
                        {formData?.per_hour && perHourFilm()}

                        <hr className="w-100 mt-4 mb-2"/>
                        {/* Extra Hours start */}
                        {filmExtraHours()}
                         {/* Extra Hours end */}
                        {/* Tv Extra Discount start */}
                        {tvExtraDiscount()}
                         {/* Tv Extra Discount end */}
                        {/*Filming Outside standard hours start */}
                        <span className="content text_color_1 mt">Is filming allowed outside the standard hours with prior approval?</span>
                        <div className="d-flex flex-row justify-content-start align-items-center w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='filming_allowed_outside_yes'
                                    name='filming_outside_standard_hours'
                                    checked={'Yes' === formData?.filming_outside_standard_hours}
                                    onChange={(e)=>onChange(e.target.name,'Yes')}
                                />
                                <label htmlFor='filming_allowed_outside_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='filming_allowed_outside_no'
                                    name='filming_outside_standard_hours'
                                    checked={'No' === formData?.filming_outside_standard_hours}
                                    onChange={(e)=>onChange(e.target.name,'No')}
                                />
                                <label htmlFor='filming_allowed_outside_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>
                        {/*Filming Outside standard hours end */}

                        {/*Tv extra hours start */}
                        {tvExtraHours()}
                        {/*Tv extra hours end */}

                        {/*spotlet extra discount start */}
                        <div className="mt">
                            <label htmlFor='' className={`content text_color_1`}>Can you offer a special discount to attract more SpotLet users?</label>
                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <input
                                    type="number"
                                    className="price_input"
                                    name="special_discount"
                                    placeholder="%"
                                    value={formData?.special_discount}
                                    onChange={(e)=> onChange(e.target.name, e.target.value)}
                                />
                            </div>
                            {errors?.special_discount && <span className="content required">{errors?.special_discount}</span>}
                        </div>
                        {/*spotlet extra discount end */}

                        {/*property capacity for cast, crew start */}
                        <div className="mt">
                            <label htmlFor='' className={`content text_color_1`}>What is the maximum capacity for cast, crew, and other teams allowed at your location for shooting purposes?{" "}<span className="required">*</span></label>
                            <div className="d-flex flex-row align-items-center flex-wrap" style={{gap : "10px"}}>
                                <input
                                    type="number"
                                    className="price_input"
                                    name="property_capacity"
                                    placeholder="Enter Number"
                                    value={formData?.property_capacity}
                                    onChange={(e)=> onChange(e.target.name, e.target.value)}
                                />
                                <label htmlFor='' className={`content text_color_1`}>people</label>
                            </div>
                            {errors?.property_capacity && <span className="content required"> {errors?.property_capacity} </span>}
                        </div>
                        {/*property capacity for cast, crew end */}

                        {/*Facility Features start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        {facilityFeatures()}
                        {/*Facility Features end */}

                        {/*Equipment policies start */}
                        {equipmentPolicies()}
                        {/*Equipment policies end */}

                        {/*Damage and restoration policies start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        {damageAndRestorationPolicies()}
                        {/*Damage and restoration policies end */}

                        {/*Noise Restrictions start */}
                        {noiseRestrictions()}
                        {/*Noise Restrictions end */}

                        {/*Additional Requirements start */}
                        {additionalRequirementsPolicies()}
                        {/*Additional Requirements end */}

                        {/* Cancellation Policy start */}
                        {cancellation()}
                        {/* Cancellation Policy end */}

                        {/* location general policies start */}
                        {locationGeneralPolicies()}
                        {/* location general policies end */}

                        {/* Custom Policy start */}
                        {customPolicies()}
                        {/* Custom Policy end */}

                    </div>

                    {/* Image Section start */}
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_5_sp_listing_film} alt="step_1" className="mb-2 sp_listing_right_image"/>
                        <span className="content text_color_1 text-center mb-2">Outline film-shooting policies, timings, equipment allowed, crew size limits, and indoor/outdoor access. Include noise restrictions and other key guidelines for filmmakers.</span>
                    </div>
                    {/* Image Section end */}
                </div>
            </div>
        </div>
        {/* Footer Section start */}
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" className="btn btn-primary sp_button" onClick={onSubmit}>Save & Continue</button>
        </div>
        {/* Footer Section start */}
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default FilmNew;