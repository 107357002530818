import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import ImageCropper from "./ImageCropper";
import CloseIcon from '@mui/icons-material/Close';
import { getProperty,updateProperty,uploadPropertyImages } from "../../services/api";
import { useState,useEffect,useRef } from "react";
import Loader from "./Loader";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import upload_svg from "../Images/BecomeHost/upload_svg.svg";
//images end

const FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"];
function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], Date.now() + filename, { type: mimeType });
      });
}

const MIN_IMAGES = 5
const MAX_IMAGES = 100

const Gallery = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const [errors, setErrors] = useState([]); 
    const navigate = useNavigate()
    const [bookingTypes,setBookingTypes] = useState({
        film : null,
        event : null,
        stay : null,
    })
    const [showLoader,setShowLoader] = useState(false)
    const [showLoaderImage,setShowLoaderImage] = useState(false)
    const [videoLink,setVideoLink] = useState('')
    const [files, setFiles] = useState({});
    const [imagesData, setImagesData] = useState([]);
    const uploarRef = useRef(null);
    const onClickInput = () => {
        if (uploarRef.current) {
            uploarRef.current.click();
          }
    }

    const checkFileNameExists = (fileName) => {
        // Check if fileName exists in the filesObject or imagesData
        return (
          files.hasOwnProperty(fileName) || // Check if fileName is a key in filesObject
          imagesData.some((image) => image.name === fileName) // Check if name exists in imagesData
        );
    };

    const onUploadFile = (event) => {
        if(imagesData.length + Object.keys(files).length + event.target.files.length > MAX_IMAGES  ){
            alert(`Maximum ${MAX_IMAGES} images only`)
            return null
        }
        const selectedFiles = [...event.target.files];
        const filterFiles = [];
        if (event.target.files && event.target.files.length > 0) {
            selectedFiles.forEach((file) => {
                if (!FILE_TYPES.includes(file.type)) {
                    event.target.value = null; // Clear input after processing all files
                    alert(`Only JPEG, JPG and PNG files allowed for upload.`);
                    return null; // Return early
                } else if (checkFileNameExists(file.name)) {
                    event.target.value = null; // Clear input after processing all files
                    alert(`The image you have uploaded already exists in the gallery. Please select a different image to upload`);
                    return null; // Return early
                } else {
                    filterFiles.push(file);
                    event.target.value = null; // Clear input after processing all files
                }
            });
          setFiles((prevFiles) => {
            const filesToAdd = [...filterFiles];
            const imageObj = {};
            filesToAdd.forEach((file) => {
              imageObj[file.name] = {
                file,
                imageSrc: URL.createObjectURL(file),
                croppedImage: "",
                title : "",
              };
            });
            return { ...prevFiles, ...imageObj };
          });
        }
    };
    const saveImages = (fileName, newCropped) => {
        setFiles((prevData) => {
          prevData[fileName].croppedImage = newCropped;
          return { ...prevData };
        });
        console.log(newCropped, fileName)
    };
    const handleDelete = async (fileName) => {
        setFiles((prevData) => {
          delete prevData[fileName];
          return { ...prevData };
        });
    };
    const handleDeleteUploaded = (value) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?");
        if(confirmDelete){
            setImagesData(prevImages => 
                prevImages.filter(image => image?.image !== value?.image)
            );
        }
    }
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            setImagesData(resp?.data?.images);
            setVideoLink(resp?.data?.video_link);
            setBookingTypes({
                film : resp?.data?.film || null,
                event : resp?.data?.event || null,
                stay : resp?.data?.stay || null,
            })
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    const uplaodFileToS3Bucket = async (item,type) => {
        const file = await urltoFile(
            item.croppedImage,
            item.file.name,
            "text/plain"
        );
        const mainImages = new FormData();
        mainImages.append("pic", file);
        mainImages.append("fileName", item.file.name);
        mainImages.append("locationId", location_id);
        mainImages.append("type",type);
        try{
            const response = await uploadPropertyImages(mainImages);
            return response.url
        }catch(error){
            console.log(error)
        }
    }
    const onBack = () => {
        navigate(`/apps/property/description/${location_id}`)
    }
    const checkUrlValidity = (url) => {
        const domainRegex = /(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
        const match = url.match(domainRegex);
        if (match) {
            const domain = match[1];
            if ( (domain === "youtube.com" || domain === "vimeo.com" || domain === "youtu.be") && (url.length > 26) ) {
                // URL is valid and from youtube or vimeo
                return true;
            } else {
                // URL is not from youtube or vimeo
                return false;
            }
        } else {
            // Not a valid URL format
            return false;
        }
    };
    const handleVideoLinkSubmit = async () => {
        const isValidUrl = checkUrlValidity(videoLink);
        return isValidUrl;
    }

    const validateTitles = () => {
        let valid = true;
        const newErrors = { ...errors };
    
        // Validate titles for imagesData
        imagesData.forEach((image, index) => {
            if (image.title && (image.title.length < 3 || image.title.length > 50)) {
                newErrors[index] = 'Title must be between 3 and 50 characters';
                valid = false;
            } else {
                delete newErrors[index]; // Remove error if title is valid
            }
        });
    
        // Validate titles for files
        Object.keys(files).forEach((fileName) => {
            const { title } = files[fileName];
            if (title && (title.length < 3 || title.length > 50)) {
                newErrors[fileName] = 'Title must be between 3 and 50 characters';
                valid = false;
            } else {
                delete newErrors[fileName]; // Remove error if title is valid
            }
        });
    
        setErrors(newErrors);
        return valid;
    };

    const onSubmit = async () => {
        if((imagesData.length + Object.keys(files).length) < MIN_IMAGES ){
            alert(`Minimum ${MIN_IMAGES} images required`)
            return null
        }
        if (!validateTitles()) {
            return null;
        }
        if (videoLink) {
            const isVideoUrlValid = await handleVideoLinkSubmit();
            if (!isVideoUrlValid) {
                alert("Invalid Video Url");
                return null;
            }
        }

        setShowLoaderImage(true);
        let uploadedImages = [];
        try {
            if(Object.keys(files).length > 0){
                await Promise.all(
                    Object.keys(files).map(async (each) => {
                    const url = await uplaodFileToS3Bucket(files[each], 'Images');
                    const reqImageFile = {
                        title : files[each]?.title,
                        image : url,
                        name : each,
                    }
                    uploadedImages.push(reqImageFile);
                    })
                );
            }
            await updateProperty({ images: [...imagesData,...uploadedImages],video_link : videoLink }, location_id);
            navigate(`/apps/property/contact-details/${location_id}`);
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoaderImage(false);
        }
    }
    const onChangeTitle = (e, fileName, type, index) => {
        const { value } = e?.target;
        const newErrors = { ...errors };
    
        // For image data or files, update the respective state
        if (type === "uploaded") {
            setImagesData(prevFiles =>
                prevFiles.map((file, i) =>
                    i === index ? { ...file, title: value } : file
                )
            );
        } else if (type === "blob") {
            setFiles(prevFiles => ({
                ...prevFiles,
                [fileName]: { ...prevFiles[fileName], title: value }
            }));
        }
    
        // Validate the title length
        if (value && (value.length < 3 || value.length > 50)) {
            newErrors[index || fileName] = 'Title must be between 3 and 50 characters';
        } else {
            delete newErrors[index || fileName]; // Remove error if title is valid
        }
    
        setErrors(newErrors);
    };
    // const validateForm = () => {
    //     const errors1 = {}
    //     `image${i}` :  
    // }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Upload Images & Videos</span>
                        <div className="w-100 d-flex flex-row justify-content-start align-items-start flex-wrap mt">
                            {imagesData && imagesData?.map((each,index)=>{
                                return(
                                    <div className="become_a_host_image_wrapper mr-2 mb-2">
                                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                            <span className="content text-success">Uploaded</span>
                                            <div className="align-self-end">
                                                <CloseIcon className="cursor" onClick={() => handleDeleteUploaded(each)}/>
                                            </div>
                                        </div>
                                        <img src={each?.image} alt={index+'1'}/>
                                        <div className="w-100 mt">
                                            <label className="content text_color_1">Add Title</label>
                                            <input
                                                type="text"
                                                className='become_a_host_text_input'
                                                placeholder="Add Name"
                                                onChange={(e) => onChangeTitle(e, each?.image, "uploaded",index)}
                                                autoComplete="off"
                                                value={each?.title}
                                            />
                                            {errors[index] && <p className="content required">{errors[index]}</p>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="become_a_host_gallery_section mt">
                            {Object.keys(files).map((fileName) => {
                                const { imageSrc, title } = files[fileName];
                                return (
                                    <div className="become_a_host_image_wrapper">
                                        <div className="align-self-end">
                                            <CloseIcon className="cursor" onClick={() => handleDelete(fileName)}/>
                                        </div>
                                        <ImageCropper
                                            imageToCrop={imageSrc}
                                            onImageCropped={(croppedImage) => {
                                            saveImages(fileName, croppedImage)
                                            }}
                                        />
                                        <div className="w-100 mt">
                                            <label className="content text_color_1">Add title</label>
                                            <input
                                                type="text"
                                                className='become_a_host_text_input'
                                                placeholder="Add Name"
                                                onChange={(e) => onChangeTitle(e, fileName, "blob")}
                                                autoComplete="off"
                                                value={title}
                                            />
                                            {errors[fileName] && <p className="content required">{errors[fileName]}</p>}
                                        </div>
                                    </div>
                                );
                                })}
                        </div>
                        {imagesData.length + Object.keys(files).length < MAX_IMAGES && (
                            <div className="become_a_host_gallery_upload_wrap mt">
                                <div className="become_a_host_gallery_upload_box cursor" onClick={onClickInput}>
                                    <img src={upload_svg} alt="upload image"/>
                                    <span className="sub_text heading_color_4">Upload your jpg or png files</span>
                                    <span className="content text_color_3">Min 5 images</span>
                                    <span className="content text_color_3">Max 100 images</span>
                                    <input 
                                        type="file"
                                        multiple
                                        data-max_length="20"
                                        className="d-none"
                                        id="upload_inputfile"
                                        accept='image/png, image/jpeg, image/jpg'
                                        ref={uploarRef}
                                        onChange={onUploadFile}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="w-100 mt">
                            <label htmlFor='video' className="content text_color_1">Video Url(please provide a You Tube or Vimeo) video link</label>
                                <input
                                    type="text"
                                    className='become_a_host_text_input'
                                    name="video"
                                    value={videoLink}
                                    id="video"
                                    onChange={(e)=>setVideoLink(e.target.value)}
                                    placeholder="Video link"
                                    autoComplete="off"
                                />
                        </div>
                    </div>
                    {/* <div className="become_a_host_sub_right_wrap">
                        <img src={step_1} alt="step_1" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">If your location is associated with more than one category, please select the one that fits best.</span>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" className="btn btn-primary sp_button" onClick={onSubmit}>Save & Continue</button>
        </div>
        {showLoader && <FullPageLoader/>}
        {showLoaderImage && <Loader text={'Uploading images, Please wait...'}/>}
        </>
    )
}
export default Gallery;