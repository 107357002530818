import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty,getAllCities } from "../../services/api";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import GoogleMapNew from "./GoogleMap";
import { useState,useEffect,useRef } from "react";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_2 from "../Images/BecomeHost/step_2.png";
import down_arrow_svg from "../Images/BecomeHost/down-arrow.svg";
//images end
import { DigitsNumberValidation, charactersStringValidation,charactersRequiredStringValidation } from "../Validations";

const CURRENCY = [
    {"country": "Afghanistan", "currency_code": "AFN"},
    {"country": "Albania", "currency_code": "ALL"},
    {"country": "Algeria", "currency_code": "DZD"},
    {"country": "Andorra", "currency_code": "EUR"},
    {"country": "Angola", "currency_code": "AOA"},
    {"country": "Antigua and Barbuda", "currency_code": "XCD"},
    {"country": "Argentina", "currency_code": "ARS"},
    {"country": "Armenia", "currency_code": "AMD"},
    {"country": "Australia", "currency_code": "AUD"},
    {"country": "Austria", "currency_code": "EUR"},
    {"country": "Azerbaijan", "currency_code": "AZN"},
    {"country": "Bahamas", "currency_code": "BSD"},
    {"country": "Bahrain", "currency_code": "BHD"},
    {"country": "Bangladesh", "currency_code": "BDT"},
    {"country": "Barbados", "currency_code": "BBD"},
    {"country": "Belarus", "currency_code": "BYN"},
    {"country": "Belgium", "currency_code": "EUR"},
    {"country": "Belize", "currency_code": "BZD"},
    {"country": "Benin", "currency_code": "XOF"},
    {"country": "Bhutan", "currency_code": "BTN"},
    {"country": "Bolivia", "currency_code": "BOB"},
    {"country": "Bosnia and Herzegovina", "currency_code": "BAM"},
    {"country": "Botswana", "currency_code": "BWP"},
    {"country": "Brazil", "currency_code": "BRL"},
    {"country": "Brunei", "currency_code": "BND"},
    {"country": "Bulgaria", "currency_code": "BGN"},
    {"country": "Burkina Faso", "currency_code": "XOF"},
    {"country": "Burundi", "currency_code": "BIF"},
    {"country": "Cabo Verde", "currency_code": "CVE"},
    {"country": "Cambodia", "currency_code": "KHR"},
    {"country": "Cameroon", "currency_code": "XAF"},
    {"country": "Canada", "currency_code": "CAD"},
    {"country": "Central African Republic", "currency_code": "XAF"},
    {"country": "Chad", "currency_code": "CDF"},
    {"country": "Chile", "currency_code": "CLP"},
    {"country": "China", "currency_code": "CNY"},
    {"country": "Colombia", "currency_code": "COP"},
    {"country": "Comoros", "currency_code": "KMF"},
    {"country": "Congo (Congo-Brazzaville)", "currency_code": "CDF"},
    {"country": "Congo (Democratic Republic of the Congo)", "currency_code": "CDF"},
    {"country": "Costa Rica", "currency_code": "CRC"},
    {"country": "Croatia", "currency_code": "HRK"},
    {"country": "Cuba", "currency_code": "CUP"},
    {"country": "Cyprus", "currency_code": "CYP"},
    {"country": "Czech Republic", "currency_code": "CZK"},
    {"country": "Denmark", "currency_code": "DKK"},
    {"country": "Djibouti", "currency_code": "DJF"},
    {"country": "Dominica", "currency_code": "XCD"},
    {"country": "Dominican Republic", "currency_code": "DOP"},
    {"country": "Ecuador", "currency_code": "USD"},
    {"country": "Egypt", "currency_code": "EGP"},
    {"country": "El Salvador", "currency_code": "SVC"},
    {"country": "Equatorial Guinea", "currency_code": "CFA"},
    {"country": "Eritrea", "currency_code": "ERN"},
    {"country": "Estonia", "currency_code": "EUR"},
    {"country": "Eswatini", "currency_code": "SZL"},
    {"country": "Ethiopia", "currency_code": "ETB"},
    {"country": "Fiji", "currency_code": "FJD"},
    {"country": "Finland", "currency_code": "EUR"},
    {"country": "France", "currency_code": "EUR"},
    {"country": "Gabon", "currency_code": "CDF"},
    {"country": "Gambia", "currency_code": "GMD"},
    {"country": "Georgia", "currency_code": "GEL"},
    {"country": "Germany", "currency_code": "EUR"},
    {"country": "Ghana", "currency_code": "GHS"},
    {"country": "Greece", "currency_code": "EUR"},
    {"country": "Grenada", "currency_code": "XCD"},
    {"country": "Guatemala", "currency_code": "GTQ"},
    {"country": "Guinea", "currency_code": "GNF"},
    {"country": "Guinea-Bissau", "currency_code": "GNF"},
    {"country": "Guyana", "currency_code": "GYD"},
    {"country": "Haiti", "currency_code": "HTG"},
    {"country": "Honduras", "currency_code": "HNL"},
    {"country": "Hungary", "currency_code": "HUF"},
    {"country": "Iceland", "currency_code": "ISK"},
    {"country": "India", "currency_code": "INR"},
    {"country": "Indonesia", "currency_code": "IDR"},
    {"country": "Iran", "currency_code": "IRR"},
    {"country": "Iraq", "currency_code": "IQD"},
    {"country": "Ireland", "currency_code": "EUR"},
    {"country": "Israel", "currency_code": "ILS"},
    {"country": "Italy", "currency_code": "EUR"},
    {"country": "Jamaica", "currency_code": "JMD"},
    {"country": "Japan", "currency_code": "JPY"},
    {"country": "Jordan", "currency_code": "JOD"},
    {"country": "Kazakhstan", "currency_code": "KZT"},
    {"country": "Kenya", "currency_code": "KES"},
    {"country": "Kiribati", "currency_code": "AUD"},
    {"country": "Korea (North)", "currency_code": "KPW"},
    {"country": "Korea (South)", "currency_code": "KRW"},
    {"country": "Kuwait", "currency_code": "KWD"},
    {"country": "Kyrgyzstan", "currency_code": "KGS"},
    {"country": "Laos", "currency_code": "LAK"},
    {"country": "Latvia", "currency_code": "LVL"},
    {"country": "Lebanon", "currency_code": "LBP"},
    {"country": "Lesotho", "currency_code": "LSL"},
    {"country": "Liberia", "currency_code": "LRD"},
    {"country": "Libya", "currency_code": "LYD"},
    {"country": "Liechtenstein", "currency_code": "CHF"},
    {"country": "Lithuania", "currency_code": "LTL"},
    {"country": "Luxembourg", "currency_code": "EUR"},
    {"country": "Madagascar", "currency_code": "MGA"},
    {"country": "Malawi", "currency_code": "MWK"},
    {"country": "Malaysia", "currency_code": "MYR"},
    {"country": "Maldives", "currency_code": "MVR"},
    {"country": "Mali", "currency_code": "XOF"},
    {"country": "Malta", "currency_code": "EUR"},
    {"country": "Marshall Islands", "currency_code": "USD"},
    {"country": "Mauritania", "currency_code": "MRU"},
    {"country": "Mauritius", "currency_code": "MUR"},
    {"country": "Mexico", "currency_code": "MXN"},
    {"country": "Micronesia", "currency_code": "USD"},
    {"country": "Moldova", "currency_code": "MDL"},
    {"country": "Monaco", "currency_code": "EUR"},
    {"country": "Mongolia", "currency_code": "MNT"},
    {"country": "Montenegro", "currency_code": "EUR"},
    {"country": "Morocco", "currency_code": "MAD"},
    {"country": "Mozambique", "currency_code": "MZN"},
    {"country": "Myanmar", "currency_code": "MMK"},
    {"country": "Namibia", "currency_code": "NAD"},
    {"country": "Nauru", "currency_code": "AUD"},
    {"country": "Nepal", "currency_code": "NPR"},
    {"country": "Netherlands", "currency_code": "EUR"},
    {"country": "New Zealand", "currency_code": "NZD"},
    {"country": "Nicaragua", "currency_code": "NIO"},
    {"country": "Niger", "currency_code": "NGN"},
    {"country": "Nigeria", "currency_code": "NGN"},
    {"country": "North Macedonia", "currency_code": "MKD"},
    {"country": "Norway", "currency_code": "NOK"},
    {"country": "Oman", "currency_code": "OMR"},
    {"country": "Pakistan", "currency_code": "PKR"},
    {"country": "Palau", "currency_code": "USD"},
    {"country": "Panama", "currency_code": "PAB"},
    {"country": "Papua New Guinea", "currency_code": "PGK"},
    {"country": "Paraguay", "currency_code": "PYG"},
    {"country": "Peru", "currency_code": "PEN"},
    {"country": "Philippines", "currency_code": "PHP"},
    {"country": "Poland", "currency_code": "PLN"},
    {"country": "Portugal", "currency_code": "EUR"},
    {"country": "Qatar", "currency_code": "QAR"},
    {"country": "Romania", "currency_code": "RON"},
    {"country": "Russia", "currency_code": "RUB"},
    {"country": "Rwanda", "currency_code": "RWF"},
    {"country": "Saint Kitts and Nevis", "currency_code": "XCD"},
    {"country": "Saint Lucia", "currency_code": "XCD"},
    {"country": "Saint Vincent and the Grenadines", "currency_code": "XCD"},
    {"country": "Samoa", "currency_code": "WST"},
    {"country": "San Marino", "currency_code": "EUR"},
    {"country": "Sao Tome and Principe", "currency_code": "STN"},
    {"country": "Saudi Arabia", "currency_code": "SAR"},
    {"country": "Senegal", "currency_code": "XOF"},
    {"country": "Serbia", "currency_code": "RSD"},
    {"country": "Seychelles", "currency_code": "SCR"},
    {"country": "Sierra Leone", "currency_code": "SLL"},
    {"country": "Singapore", "currency_code": "SGD"},
    {"country": "Slovakia", "currency_code": "EUR"},
    {"country": "Slovenia", "currency_code": "EUR"},
    {"country": "Solomon Islands", "currency_code": "SBD"},
    {"country": "Somalia", "currency_code": "SOS"},
    {"country": "South Africa", "currency_code": "ZAR"},
    {"country": "South Sudan", "currency_code": "SSP"},
    {"country": "Spain", "currency_code": "EUR"},
    {"country": "Sri Lanka", "currency_code": "LKR"},
    {"country": "Sudan", "currency_code": "SDG"},
    {"country": "Suriname", "currency_code": "SRD"},
    {"country": "Sweden", "currency_code": "SEK"},
    {"country": "Switzerland", "currency_code": "CHF"},
    {"country": "Syria", "currency_code": "SYP"},
    {"country": "Taiwan", "currency_code": "TWD"},
    {"country": "Tajikistan", "currency_code": "TJS"},
    {"country": "Tanzania", "currency_code": "TZS"},
    {"country": "Thailand", "currency_code": "THB"},
    {"country": "Timor-Leste", "currency_code": "USD"},
    {"country": "Togo", "currency_code": "XOF"},
    {"country": "Tonga", "currency_code": "TOP"},
    {"country": "Trinidad and Tobago", "currency_code": "TTD"},
    {"country": "Tunisia", "currency_code": "TND"},
    {"country": "Turkey", "currency_code": "TRY"},
    {"country": "Turkmenistan", "currency_code": "TMT"},
    {"country": "Tuvalu", "currency_code": "AUD"},
    {"country": "Uganda", "currency_code": "UGX"},
    {"country": "Ukraine", "currency_code": "UAH"},
    {"country": "United Arab Emirates", "currency_code": "AED"},
    {"country": "United Kingdom", "currency_code": "GBP"},
    {"country": "United States", "currency_code": "USD"},
    {"country": "Uruguay", "currency_code": "UYU"},
    {"country": "Uzbekistan", "currency_code": "UZS"},
    {"country": "Vanuatu", "currency_code": "VUV"},
    {"country": "Vatican City", "currency_code": "EUR"},
    {"country": "Venezuela", "currency_code": "VES"},
    {"country": "Vietnam", "currency_code": "VND"},
    {"country": "Yemen", "currency_code": "YER"},
    {"country": "Zambia", "currency_code": "ZMW"},
    {"country": "Zimbabwe", "currency_code": "ZWL"}
  ]
  

const BasicInformation = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const reqKey = process.env.REACT_APP_G_KEY;
    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    const [formData,setFormData] = useState({
        basic_info : {
            name : '',
            size : null,
            unit : 'Sq.Ft',
            food_type : '',
            in_house_parking : false,
            in_house_parking_cars : null,
            street_parking : null,
            valet_parking : null,
        },
        address : {
            country : '',
            state : '',
            city : '',
            area : '',
            zip_code : '',
            land_mark : '',
            place_id : '',
            latitude : '',
            longitude  : '',
            map_image : '',
            full_address : '',
        },
        currency : '',
    })
    const [errors,setErrors] = useState({})
    const [searchValue, setSearchValue] = useState(null);
    const onChangeBasic = (name,value) => {
        setFormData({
            ...formData,
            basic_info : {
                ...formData.basic_info,
                [name] : value,
            }
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }
    const onChangeAddress = (name,value) => {
        setFormData({
            ...formData,
            address : {
                ...formData.address,
                [name] : value,
            }
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }
    const handleLocationSelect = async (value) => {
        const [place] = await geocodeByPlaceId(value.place_id);
        const { lat, lng } = place.geometry.location;
        const { long_name: country = '' } = place.address_components.find(c => c.types.includes('country')) || {};
        const { long_name: state = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
        const { long_name: city = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
        const { long_name: area = '' } = place.address_components.find(c => c.types.includes("sublocality_level_1")) || {};
        const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
        const { long_name: landmark = '' } = place.address_components.find(c => c.types.includes('neighborhood')) || {};

        const countryData = CURRENCY.find(item => item.country.toLowerCase() === country.toLowerCase());
        setFormData({
            ...formData,
            address : {
                ...formData?.address,
                country : country,
                state : state,
                city : '',
                area : area,
                zip_code : postalCode,
                land_mark : landmark,
                place_id : place?.place_id,
                latitude : lat(),
                longitude  : lng(),
                full_address : place?.formatted_address

            },
            currency : countryData.currency_code
        })
        setCity('')
        console.log(country,state,city,area,postalCode,landmark)
        initLocationTypes(country,state)
    }
    const validateForm = () => {
        const newErrors = {
            "name": formData?.basic_info?.name ? charactersRequiredStringValidation( formData?.basic_info?.name,"Property name",3,50) : "Property name is required", 
            "size": formData?.basic_info?.size ? DigitsNumberValidation(formData?.basic_info?.size, "Property size", 1,10 ) : "Property size is required", 
            "food_type": formData?.basic_info?.food_type ? "" : 'This selection is required',
             ...(formData?.basic_info?.in_house_parking && {"in_house_parking_cars": formData?.basic_info?.in_house_parking_cars ? DigitsNumberValidation(formData?.basic_info?.in_house_parking_cars,'No of cars',1,5)  : "No of cars is required"}), 
            "street_parking": formData?.basic_info?.street_parking === true || formData?.basic_info?.street_parking === false ? "" : 'Street parking selection is required',
            'where' : (formData?.address?.latitude && formData?.address?.longitude) ? '' : 'Address is required',
            "country": (formData?.address?.latitude && formData?.address?.longitude) ?formData?.address?.country ? '' : "country is required" : '',
            "state": (formData?.address?.latitude && formData?.address?.longitude) ?formData?.address?.state ? '' : "State is required" : '',
            "city": (formData?.address?.latitude && formData?.address?.longitude) ?formData?.address?.city ? '' : "City is required" : '',
            "area": (formData?.address?.latitude && formData?.address?.longitude) ?formData?.address?.area ? '' : "Area is required" : '',
            "zip_code": (formData?.address?.latitude && formData?.address?.longitude) ?formData?.address?.zip_code ? '' : "Zip/Post/Pin Code is required" : '',
            "currency": (formData?.address?.latitude && formData?.address?.longitude) ?formData?.currency ? '' : "Currency is required" : '',
            "land_mark": formData?.address?.land_mark ? charactersStringValidation(formData?.address?.land_mark, "land mark", 3, 50) : "" ,

        };
        console.log(newErrors)
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById(key);
                if (inputElement){
                    const headerHeight = 130;
                    scrollToElementWithOffset(inputElement, headerHeight);
                }
                return false
            }
        }
        return true
    }
    const onBack = () => {
        if(location_id){
            navigate(`/apps/property/type-of-property/${location_id}`)
        }else{
            navigate('/apps/property/type-of-property')
        }
    }
    function trimFormData(formData) {
        // Check if the formData is an object
        if (typeof formData === 'object' && formData !== null) {
          if (Array.isArray(formData)) {
            // If it's an array, loop through each element and trim spaces in strings
            return formData.map(trimFormData);
          } else {
            // If it's an object, loop through each key-value pair
            const trimmedObj = {};
            for (const key in formData) {
              if (formData.hasOwnProperty(key)) {
                trimmedObj[key] = trimFormData(formData[key]);
              }
            }
            return trimmedObj;
          }
        }
      
        // If it's a string, trim leading and trailing spaces
        if (typeof formData === 'string') {
          return formData.trim();
        }
      
        // If it's null or undefined, return as-is
        return formData;
    }
    const onSubmit = async () => {
        if(!validateForm()){
            return null
        }
        try{
            setShowLoader(true)
            let resp
            resp = await updateProperty(trimFormData(formData),location_id)
            setShowLoader(false)
            navigate(`/apps/property/activities/${location_id}`)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }
    function scrollToElementWithOffset(element, offset) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
    }
    const cityRef = useRef(null);
    const cityInputRef = useRef(null);
    const [cities, setCities] = useState([]);
    const [city,setCity] = useState('');
    const [showCities,setShowCities] = useState(false)
    const [filteredCity,setFilteredCity] = useState([])
    const handleCityInputClick = () => {
        setShowCities(!showCities)
        if (!showCities && cityRef.current) {
            cityInputRef.current.focus();
        }
    }
    const onChangeCity = (e) => {
        setCity(e.target.value)
        const filteredData = cities?.filter((option) =>
        option?.label?.toLowerCase()?.includes(e.target.value.toLowerCase())
        );
        if(!e.target.value){
            setFormData({
                ...formData,
                address : {
                    ...formData.address,
                    city: ''
                }
            })
        }
        setFilteredCity(filteredData);
    }
    const handleOptionClickCity = (option) => {
        setCity('');
        const data = {
            ...formData,
            address : {
                ...formData.address,
                city: option.label,
            }
        }
        setFormData(data)
        setErrors({...errors,city : ''})
        setShowCities(false)
    }
    const initLocationTypes = async (country,state) => {
        try {
            const data = await getAllCities({country,state});
            const removeDiacritics = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            let objectsArray = []
            data?.data?.data?.map((item) => {
                const exists = objectsArray.some(each => each.label === removeDiacritics(item));
                if(!exists){
                    objectsArray.push(
                        {
                            label: removeDiacritics(item),
                            value: removeDiacritics(item)
                        }
                    )
                }
            });
            setCities(objectsArray);
            setFilteredCity(objectsArray);
        } catch (error) {
            // console.log(error?.response?.data?.message);
            const errorMsg = error?.response?.data?.message ? error?.response?.data?.message : "Some error occured"
        }
    };
    const handleClickOut = (event) => {
        if (
            cityRef.current && !cityRef.current.contains(event.target)
          ) {
            setShowCities(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOut);
        return () => {
          document.removeEventListener('click', handleClickOut);
        };
    }, []);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop()
    },[])
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            setFormData(
                {
                    basic_info : {
                        name : resp?.data?.basic_info?.name || '',
                        size : resp?.data?.basic_info?.size || null,
                        unit : resp?.data?.basic_info?.unit || 'Sq.Ft',
                        food_type : resp?.data?.basic_info?.food_type || '',
                        in_house_parking : resp?.data?.basic_info?.in_house_parking,
                        in_house_parking_cars : resp?.data?.basic_info?.in_house_parking_cars || null,
                        street_parking : resp?.data?.basic_info?.street_parking,
                        valet_parking : resp?.data?.basic_info?.valet_parking,
                    },
                    address : {
                        country : resp?.data?.address?.country || '',
                        state : resp?.data?.address?.state || '',
                        city : resp?.data?.address?.city || '',
                        area : resp?.data?.address?.area || '',
                        zip_code : resp?.data?.address?.zip_code || '',
                        land_mark : resp?.data?.address?.land_mark || '',
                        place_id : resp?.data?.address?.place_id || '',
                        latitude : resp?.data?.address?.latitude || '',
                        longitude  : resp?.data?.address?.longitude || '',
                        map_image : resp?.data?.address?.map_image || '',
                        full_address : resp?.data?.address?.full_address || '',
                    },
                    currency : resp?.data?.currency || '',
                }
            )
            setSearchValue(resp?.data?.address)
            setCity(resp?.data?.address?.city)
            initLocationTypes(resp?.data?.address?.country,resp?.data?.address?.state)
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Property Details</span>
                        <div className="become_a_host_BI_wrap">
                            <div className="w-100 mt">
                                <label htmlFor='name' className="content text_color_1">Property Name{' '}<label className="required">*</label></label>
                                <input
                                    type="text"
                                    className={errors?.name ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="name"
                                    value={formData?.basic_info?.name}
                                    id="name"
                                    onChange={(e)=>onChangeBasic('name',e.target.value)}
                                    placeholder="Enter Property Name"
                                    autoComplete="off"
                                />
                                {errors?.name && <span className="content required">{errors?.name}</span>}
                            </div>
                            <div className="position-relative w-100 mt">
                                <label htmlFor='size' className="content text_color_1">Property Size{' '}<label className="required">*</label></label>
                                <input
                                    type="number"
                                    className={errors?.size ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="size"
                                    value={formData?.basic_info?.size}
                                    id="size"
                                    onChange={(e)=>onChangeBasic('size',e.target.value)}
                                    placeholder="Enter Property Size"
                                    autoComplete="off"
                                />
                                <div className='become_a_host_BI_property_size_button_container'>
                                    <button type="button" onClick={()=> setFormData({...formData,basic_info : {...formData?.basic_info,unit : 'Sq.Ft'} })} className={formData?.basic_info?.unit === 'Sq.Ft' ?"become_a_host_BI_property_size_button_active" :  "become_a_host_BI_property_size_button"}>Sq.Ft</button>
                                    <button type="button" onClick={()=> setFormData({...formData,basic_info : {...formData?.basic_info,unit : 'Sq.Mts'}})} className={formData?.basic_info?.unit === 'Sq.Mts' ?"become_a_host_BI_property_size_button_active" :  "become_a_host_BI_property_size_button"}>Sq.Mts</button>
                                    <button type="button" onClick={()=> setFormData({...formData,basic_info : {...formData?.basic_info,unit : 'Sq.Yds'}})} className={formData?.basic_info?.unit === 'Sq.Yds' ?"become_a_host_BI_property_size_button_active" :  "become_a_host_BI_property_size_button"}>Sq.Yds</button>
                                </div>
                                {errors?.size && <span className="content required">{errors?.size}</span>}
                            </div>
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content text_color_1 mt">Is this property exclusively vegetarian?{' '}<label className="required">*</label></span>
                        <div className="BI_check_wrap mt">
                            <div className="BI_check_box_wrap">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='food_type_1'
                                    name='food_type'
                                    checked={formData?.basic_info?.food_type === 'Yes, only vegetarian food is allowed'}
                                    onChange={()=>onChangeBasic('food_type','Yes, only vegetarian food is allowed')}
                                />
                                <label htmlFor='food_type_1' className={`content text_color_4`}>Yes, only vegetarian food is allowed</label>
                            </div>
                            <div className="BI_check_box_wrap">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='food_type_2'
                                    name='food_type'
                                    checked={formData?.basic_info?.food_type === 'No, both vegetarian and non-vegetarian food are allowed'}
                                    onChange={()=>onChangeBasic('food_type','No, both vegetarian and non-vegetarian food are allowed')}
                                />
                                <label htmlFor='food_type_2' className={`content text_color_4`}>No, both vegetarian and non-vegetarian food are allowed</label>
                            </div>
                        </div>
                        {errors?.food_type && <span className="content required mt">{errors?.food_type}</span>}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_content heading_color_1 mt">Parking Facility</span>
                        <div className="d-flex flex-row justify-content-between align-items-start w-100 flex-wrap">
                            <div className="d-flex flex-column mt">
                                <div className="BI_check_box_wrap">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        id='in_house_parking'
                                        name='in_house_parking'
                                        checked={formData?.basic_info?.in_house_parking}
                                        onChange={(e)=>onChangeBasic('in_house_parking',e.target.checked)}
                                    />
                                    <label htmlFor='in_house_parking' className={`content text_color_4`}>In-house Parking (4 Wheelers):</label>
                                </div>
                                {formData?.basic_info?.in_house_parking && (
                                    <>
                                        <input
                                            type="number"
                                            className={errors?.in_house_parking_cars ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                            name="in_house_parking_cars"
                                            value={formData?.basic_info?.in_house_parking_cars }
                                            id="name"
                                            onChange={(e)=>onChangeBasic('in_house_parking_cars',e.target.value)}
                                            placeholder="No. of 4 wheelers"
                                            autoComplete="off"
                                        />
                                        {errors?.in_house_parking_cars && <span className="content required">{errors?.in_house_parking_cars}</span>}
                                    </>
                                )}
                            </div>
                            <div className="d-flex flex-column mt">
                                <span className="content text_color_1">Street Parking{' '}<label className="required">*</label></span>
                                <div className="d-flex flex-row mt">
                                    <div className="BI_check_box_wrap mr-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id='street_parking'
                                            name='street_parking'
                                            checked={formData?.basic_info?.street_parking === true}
                                            onChange={()=>onChangeBasic('street_parking',true)}
                                        />
                                        <label htmlFor='street_parking' className={`content text_color_4`}>Allowed</label>
                                    </div>
                                    <div className="BI_check_box_wrap">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id='street_parking_no'
                                            name='street_parking'
                                            checked={formData?.basic_info?.street_parking === false}
                                            onChange={()=>onChangeBasic('street_parking',false)}
                                        />
                                        <label htmlFor='street_parking_no' className={`content text_color_4`}>Not Allowed</label>
                                    </div>
                                </div>
                                {errors?.street_parking && <span className="content mt required">{errors?.street_parking}</span>}
                            </div>
                            <div className="d-flex flex-column mt">
                                <span className="content text_color_1">Valet Parking</span>
                                <div className="d-flex flex-row mt">
                                    <div className="BI_check_box_wrap mr-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id='valet_parking'
                                            name='valet_parking'
                                            checked={formData?.basic_info?.valet_parking === true}
                                            onChange={()=>onChangeBasic('valet_parking',true)}
                                        />
                                        <label htmlFor='valet_parking' className={`content text_color_4`}>Available</label>
                                    </div>
                                    <div className="BI_check_box_wrap">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id='valet_parking_no'
                                            name='valet_parking'
                                            checked={formData?.basic_info?.valet_parking === false}
                                            onChange={()=>onChangeBasic('valet_parking',false)}
                                        />
                                        <label htmlFor='valet_parking_no' className={`content text_color_4`}>Not Available</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_content heading_color_1 mt">Where{' '}<label className="required">*</label></span>
                        <div className="w-100 mt">
                            <GooglePlacesAutocomplete apiKey={reqKey}
                                selectProps={{
                                    searchValue,
                                    onChange: (text) => { handleLocationSelect(text.value) },
                                    placeholder:"Enter location/Pin/zip"
                                }}
                            />
                        </div>
                        {/* <img src={map} alt="map" className="w-100 mt"/> */}
                        {(formData?.address?.latitude && formData?.address?.longitude) && (
                            <>
                                <div className="w-100 mt">
                                    <GoogleMapNew handleLocationSelect={handleLocationSelect} markData={{lat : formData?.address?.latitude,lng : formData?.address?.longitude}}/>
                                </div>
                                <div className="become_a_host_BI_address_wrap mt">
                                    <div className="mt">
                                        <label htmlFor='country' className="content text_color_1">Country{' '}<label className="required">*</label></label>
                                        <input
                                            type="text"
                                            className={errors?.country ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                            name="country"
                                            value={formData?.address?.country}
                                            id="country"
                                            onChange={(e)=>onChangeAddress('country',e.target.value)}
                                            placeholder="Enter Country"
                                            autoComplete="off"
                                            disabled
                                        />
                                        {errors?.country && <span className="content required">{errors?.country}</span>}
                                    </div>
                                    <div className="mt">
                                        <label htmlFor='state' className="content text_color_1">State{' '}<label className="required">*</label></label>
                                        <input
                                            type="text"
                                            className={errors?.state ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                            name="state"
                                            value={formData?.address?.state}
                                            id="state"
                                            onChange={(e)=>onChangeAddress('state',e.target.value)}
                                            placeholder="Enter State"
                                            autoComplete="off"
                                            disabled
                                        />
                                        {errors?.state && <span className="content required">{errors?.state}</span>}
                                    </div>
                                    <div className="position-relative mt">
                                        <label htmlFor='city' className="content text_color_1">City{' '}<label className="required">*</label></label>
                                        <div className="w-100 d-flex" ref={cityRef} onClick={handleCityInputClick}>
                                            <input
                                                type="text"
                                                className={errors?.city ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                                name="city"
                                                value={city || formData?.address?.city}
                                                id="city"
                                                onChange={onChangeCity}
                                                ref={cityInputRef}
                                                placeholder="Enter City"
                                                autoComplete="off"
                                            />
                                            <img src={down_arrow_svg} style={{marginLeft : '-25px'}}/>
                                        </div>
                                        {showCities && (
                                                <ul className="sp_options_list">
                                                    {filteredCity?.length > 0 ? (
                                                        filteredCity.map((option, index) => (
                                                            <li
                                                            key={index}
                                                            onClick={() => handleOptionClickCity(option)}
                                                            className={`sp_option_item ${option.label === formData?.city ? 'highlighted' : ''}`}
                                                            >
                                                            {option.label}
                                                            </li>
                                                        ))
                                                    ) : (
                                                    <li className="sp_option_item">No Options</li>
                                                    )}
                                                </ul>
                                            )}
                                        {errors?.city && <span className="content required">{errors?.city}</span>}
                                    </div>
                                    
                                    <div className="mt">
                                        <label htmlFor='area' className="content text_color_1">Street/Area{' '}<label className="required">*</label></label>
                                        <input
                                            type="text"
                                            className={errors?.area ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                            name="area"
                                            value={formData?.address?.area}
                                            id="area"
                                            onChange={(e)=>onChangeAddress('area',e.target.value)}
                                            placeholder="Enter Area"
                                            autoComplete="off"
                                        />
                                        {errors?.area && <span className="content required">{errors?.area}</span>}
                                    </div>
                                    <div className="mt">
                                        <label htmlFor='zip_code' className="content text_color_1">Zip/Post/Pin Code{' '}<label className="required">*</label></label>
                                        <input
                                            type="number"
                                            className={errors?.zip_code ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                            name="zip_code"
                                            value={formData?.address?.zip_code }
                                            id="zip_code "
                                            onChange={(e)=>onChangeAddress('zip_code',e.target.value)}
                                            placeholder="Enter code"
                                            autoComplete="off"
                                        />
                                        {errors?.zip_code && <span className="content required">{errors?.zip_code}</span>}
                                    </div>
                                    <div className="mt">
                                        <label htmlFor='land_mark' className="content text_color_1">Land mark</label>
                                        <input
                                            type="text"
                                            className='become_a_host_text_input'
                                            name="land_mark"
                                            value={formData?.address?.land_mark }
                                            id="land_mark"
                                            onChange={(e)=>onChangeAddress('land_mark',e.target.value)}
                                            placeholder="Enter code"
                                            autoComplete="off"
                                        />
                                        {errors?.land_mark && <span className="content required">{errors?.land_mark}</span>}
                                    </div>
                                    <div className="mt">
                                        <label htmlFor='currency' className="content text_color_1">Currency</label>
                                        <input
                                            type="text"
                                            className='become_a_host_text_input'
                                            name="currency"
                                            value={formData?.currency }
                                            id="currency"
                                            onChange={(e)=>{setFormData({...formData,currency : e.target.value})}}
                                            placeholder="Select currency"
                                            autoComplete="off"
                                            disabled
                                        />
                                        {errors?.currency && <span className="content required">{errors?.currency}</span>}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_2} alt="step_2" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">Share your property’s essential details, from its name and size to parking options, map location, and currency preferences to streamline guest interactions and payments.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" onClick={onSubmit} className="btn btn-primary sp_button">Save & Continue</button>
        </div>
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default BasicInformation;