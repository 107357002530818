import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useEffect, useState } from "react";
import { useParams, Link, useNavigate,useLocation} from 'react-router-dom';
// import { addToFeatureProperty, addToTopRatedProperty } from '../services/api';
import { getProperty, getUsers,assignUser, updateListing, addSubFeatureExclusiveProperty } from '../services/api';
import { PageLoader } from "../services/PageLoader";
import Moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ConfirmationModel from '../../../modals/ConfirmationModal/ConfirmationModal';
import Calendar from './Calendar/Calendar';
import ReactPlayer from 'react-player';
import "./Listing.css";

const Listing = () => {
    const params = useParams()
    const location = useLocation();
    const edited = new URLSearchParams(location.search).get('edited')
    const navigate = useNavigate();
    const listingId : any = params.id
    const [data, setData] = useState<any>([]);
    const [usersData, setUsersData] = useState<any>([]);
    const [dropDownData, setDropDownData] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false)
    const [selectedUser,setSelectedUser] = useState<any>(null)
    const [showUserSelectionDiv, setShowUserSelectionDiv] = useState(false)
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [tile, setTile] = useState('Details')
    const onChangeTile = (value) => {
      setTile(value)
    }
    const addTags = async(tag) => {
      try{
          let resp : any;
          const eventType = tag?.split(" ")[0];
          const type = tag?.split(" ")[1];
          const dataObj = {
              type : type === "featured" ? "FEATURED" : "TOP_RATED" ,
              eventType,
              locationId : data?.location_id,
          };
          // if(tag === 'featured'){
          //     resp = await addToFeatureProperty({locationId : data?.location_id});
          // }
          // if(tag === 'toprated'){
          //     resp = await addToTopRatedProperty({locationId : data?.location_id});
          // }
          resp = await addSubFeatureExclusiveProperty({...dataObj});
          if(resp?.data){
              setModelBody(resp?.data?.message)
              setShow(true)
            }
          }catch(error:any){
            console.log(error?.response?.data?.message)
            setModelBody(error?.response?.data?.message)
            setShow(true)
      }
   }
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  //manage start
  const [bookingServices,setBookingServices] = useState<any>(
    {
      film : false,
      tv : false,
      event : false,
      stay : false,
    }
  );
  const onSettingsSubmit = async(e:any) => {
    e.preventDefault()
    setShowLoader(true)
    try{
        const resp = await updateListing({bookingServices,location_id : data?.location_id})
        console.log(resp?.data)
        setModelBody(resp?.data?.message)
        setShow(true)
    }catch(error:any) {
        console.log(error?.response?.data?.message)
        alert(error?.response?.data?.message)
    }
    setShowLoader(false)
}
  //manage end
  
  const renderData = (data) => {
    if (data === null || data === undefined || data === '') {
        return <span className='text-info'>**</span>;
    }

    if (Array.isArray(data)) {
        if (data.length === 0) {
            return <span className='text-info'>**</span>;
        }
        return (
            <ul className="list-group">
                {data.map((item, index) => (
                    <li key={index} className="list-group-item">
                        {renderData(item)}
                    </li>
                ))}
            </ul>
        );
    } else if (typeof data === 'object' && data !== null) {
        if (isEmptyObject(data)) {
            return <span className='text-info'>**</span>;
        }
        return (
            <ul className="list-group">
                {Object.entries(data).map(([key, value]) => (
                    <li key={key} className="list-group-item d-flex justify-content-between">
                        <strong>{key}:</strong> {renderData(value)}
                    </li>
                ))}
            </ul>
        );
    } else {
        return <span className='text-info'>{data.toString()}</span>;
    }
};
    const getData = async() => {
        setShowLoader(true)
        try{
            const resp = await getProperty(listingId)
            setData(resp?.data)
            if(resp?.data?.bookingServices) setBookingServices(resp?.data?.bookingServices)
        }catch(error : any){
            console.log(error?.response?.data?.message)
        }
        setShowLoader(false)
    }
    const handleUser = async()=> {/*  */
        if(usersData.length === 0){
            try{
                const resp = await getUsers('all')
                setUsersData(resp?.data)
                const temp = resp?.data.map((user)=> {
                    return {
                        label : `${user?.personalInfo?.email} ${user?.personalInfo?.mobile}`,
                        year : user?._id,
                    }
                })
                setDropDownData(temp)
            }catch(error){
                console.log(error)
            }
        }
        setShowUserSelectionDiv(true)
    }
    const handleSelect = (value) => {
        console.log(value)
        if(value){
            const user = usersData.filter((user) => user._id === value?.year)
            setSelectedUser(user[0])
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(selectedUser)
        if(!selectedUser) return alert('Select user')
        try{
            const resp = await assignUser(selectedUser?._id,listingId)
            setShowUserSelectionDiv(false)
            setSelectedUser(null)
            getData()
            alert(resp?.data?.message)
        }catch(error : any){
            console.log(error?.response?.data?.message)
            alert(error?.response?.data?.message)
        }
    }
    useEffect(()=>{
        getData()
    },[])

    // console.log("data?.user", data?.user);

    const policiesCard = (type : String) => {
      let policyList : any = {}
      switch (type) {
        case "film":
          policyList = data?.policies?.film;
          break;
        case "event":
          policyList = data?.policies?.event;
          break;
        case "stay":
          policyList = data?.policies?.stay;
          break;
        default:
          break;
      }

      return(
        <div className='list-group'>
          <p className='mt-2 mb-2 list-group-item'> Availability : Yes </p>
          <div className='mb-2 list-group-item'>
            <p className='mb-1'> Cancellation Policy : </p>
            <ul className="">
              <li className="">{policyList?.cancellation}</li>
            </ul>
          </div>
          <div className = "mt-2 mb-2 list-group-item">
            <p className='mb-1'> Property Static Policies : </p>
            <ul className="">
                {policyList?.staticPolices?.map((each,i)=>{
                    return(
                        <li key={i} className="">{each}</li>
                    )
                })}
            </ul>
          </div>

          <div className = "mt-2 mb-2 list-group-item">
            <p className='mb-1'> Host Selected Policies : </p>
            <>
                <ul className="">
                    <li className=''>
                        Id Proofs 
                        <ul>
                            {
                                policyList?.idProof?.map((each) => {
                                    return <li className=''> {each?.label} </li>
                                } )
                            }
                        </ul>
                    </li>
                    {
                        policyList?.music?.allowed && (
                            <li className=''>
                                Music allowed - {policyList?.music?.fullDay ? "24 Hours" : `${policyList?.music?.timings?.start} - ${policyList?.music?.timings?.end}` }
                            </li>
                        )
                    }
                    {
                        policyList?.outSideFood && (
                            <li className=''>
                                Outside food allowed
                            </li>
                        )
                    }
                    {
                        policyList?.smoking && (
                            <li className=''>
                                Smoking allowed
                            </li>
                        )
                    }
                    {
                        policyList?.nonVeg && (
                            <li className=''>
                                Non-Veg allowed
                            </li>
                        )
                    }
                    {
                        policyList?.caretaker?.available && (
                            <li className=''>
                                Caretaker available - {policyList?.caretaker?.fullDay ? "24 Hours" : `${policyList?.caretaker?.timings?.start} - ${policyList?.caretaker?.timings?.end}` }
                            </li>
                        )
                    }
                    {
                        policyList?.customPolicy && (
                            <li className=''>
                                Host Policies
                                <ul>
                                    {
                                        policyList?.customPolicies?.map((each) => {
                                            return <li className=''> {each} </li>
                                        } )
                                    }
                                </ul>
                            </li>
                        )
                    }
                </ul>
            </>
          </div>
      </div>
      )

    }

    const timingsCard = (type : String) => {
      let timings : any = {}
      switch (type) {
        case "film":
          timings = data?.pricing?.film?.timings;
          break;
        case "tv":
          timings = data?.pricing?.tv?.timings;
          break;
        case "event":
          timings = data?.pricing?.event?.timings;
          break;
        case "stay":
          timings = data?.pricing?.stay?.timings;
          break;
        default:
          break;
      }
      return(
        <div className="list-group" style={{width : "300px"}}>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Sunday : </span>
              </div>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">
                      {timings?.sunday ?
                          (timings?.sunday?.fullDay || (timings?.sunday === true )) ? 
                          type === "stay" ? "12:00PM - 11:00AM" : 'All Day'
                          : !timings?.sunday?.timings?.open ? 'Closed' : `${timings?.sunday?.timings?.open} -  ${timings?.sunday?.timings?.close}` 
                      : "Closed" }
                  </span>
              </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Monday : </span>
              </div>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">
                  {timings?.monday ?
                      (timings?.monday?.fullDay || (timings?.monday === true )) ?
                      type === "stay" ? "12:00PM - 11:00AM" : 'All Day'
                      : !timings?.monday?.timings?.open ? 'Closed' :  `${timings?.monday?.timings?.open} -  ${timings?.monday?.timings?.close}` 
                  : "Closed" }
                  </span>
              </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Tuesday : </span>
              </div>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">
                      {timings?.tuesday ?
                          (timings?.tuesday?.fullDay || (timings?.tuesday === true )) ?
                          type === "stay" ? "12:00PM - 11:00AM" : 'All Day'
                          : !timings?.tuesday?.timings?.open ? 'Closed' :  `${timings?.tuesday?.timings?.open} -  ${timings?.tuesday?.timings?.close}` 
                      : "Closed"}
                  </span>
              </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Wednesday : </span>
              </div>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">
                      {timings?.wednesday ?
                          (timings?.wednesday?.fullDay || (timings?.wednesday === true )) ?
                          type === "stay" ? "12:00PM - 11:00AM" : 'All Day'
                          : !timings?.wednesday?.timings?.open ? 'Closed' :  `${timings?.wednesday?.timings?.open} -  ${timings?.wednesday?.timings?.close}` 
                      : "Closed"}
                  </span>
              </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Thursday : </span>
              </div>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">
                      {timings?.thursday ?
                          (timings?.thursday?.fullDay || (timings?.thursday === true )) ? 
                          type === "stay" ? "12:00PM - 11:00AM" : 'All Day'
                          : !timings?.thursday?.timings?.open ? 'Closed' :  `${timings?.thursday?.timings?.open} -  ${timings?.thursday?.timings?.close}` 
                      : "Closed"}
                  </span>
              </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Friday : </span>
              </div>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">
                      {timings?.friday ?
                          (timings?.friday?.fullDay || (timings?.friday === true )) ?
                              type === "stay" ? "12:00PM - 11:00AM" : 'All Day'
                              : !timings?.friday?.timings?.open ? 'Closed' :  `${timings?.friday?.timings?.open} -  ${timings?.friday?.timings?.close}` 
                          : "Closed"}
                  </span>
              </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Saturday : </span>
              </div>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">
                      {timings?.saturday ?
                          (timings?.saturday?.fullDay || (timings?.saturday === true )) ?
                          type === "stay" ? "12:00PM - 11:00AM" : 'All Day'
                          : !timings?.saturday?.timings?.open ? 'Closed' :  `${timings?.saturday?.timings?.open} -  ${timings?.saturday?.timings?.close}` 
                      : "Closed"}
                  </span>
              </div>
          </div>
        </div>
      )
    }

    const filmTvCard = (pricingData : any) => {
      return(
        <div className="list-group" style={{width : "300px"}}>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Available : </span>
              </div>
              <div className="">
                Yes
              </div>
          </div>
          {pricingData["24Hours"]?.available ? (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">24 Hours : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  Yes
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Price : </span>
                </div>
                <div className="">
                  {pricingData["24Hours"]?.price}
                </div>
            </div>
            </div>
          ) : (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">24 Hours : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  No
                </div>
              </div>
            </div>
          ) }
          {pricingData["12Hours"]?.available ? (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">12 Hours : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  Yes
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Price : </span>
                </div>
                <div className="">
                  {pricingData["12Hours"]?.price}
                </div>
            </div>
            </div>
          ) : (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">12 Hours : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  No
                </div>
              </div>
            </div>
          ) }
          {pricingData["perHour"]?.available ? (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Per Hour : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  Yes
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Price : </span>
                </div>
                <div className="">
                  {pricingData["perHour"]?.price}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Minimum Booking Hrs : </span>
                </div>
                <div className="">
                  {pricingData["perHour"]?.minimumBookingHours}
                </div>
              </div>
            </div>
          ) : (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Per Hour : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  No
                </div>
              </div>
            </div>
          ) }
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Attendees : </span>
            </div>
            <div className="">
              {pricingData?.attendees}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Cleaning Fee : </span>
            </div>
            <div className="">
              {pricingData?.cleaningFee || "NA"}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Security Deposit : </span>
            </div>
            <div className="">
              {pricingData?.securityDeposit || "NA"}
            </div>
          </div>
        </div>
      )
    }
    const eventCard = (pricingData : any) => {
      return(
        <div className="list-group" style={{width : "300px"}}>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Available : </span>
              </div>
              <div className="">
                Yes
              </div>
          </div>
          {pricingData["venue"]?.available ? (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Venue : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  Yes
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Price : </span>
                </div>
                <div className="">
                  {pricingData["venue"]?.price}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Maximum Attendees : </span>
                </div>
                <div className="">
                  {pricingData["venue"]?.maxAttendees}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Above price applicable for : </span>
                </div>
                <div className="">
                  {pricingData["venue"]?.minimumBookingHours} Hrs
                </div>
              </div>
            </div>
          ) : (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Venue : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  No
                </div>
              </div>
            </div>
          ) }
          {pricingData["perPlate"]?.available ? (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Per Plate : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  Yes
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Pure Veg : </span>
                </div>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">{pricingData?.pureVeg ? "Yes" : "No"} </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Non Veg : </span>
                </div>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">{pricingData?.nonVeg ? "Yes" : "No"} </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">  Veg Price : </span>
                </div>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">{pricingData["perPlate"]?.vegPrice} </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Non Veg Price : </span>
                </div>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">{pricingData["perPlate"]?.nonVegPrice || "NA"} </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Min Attendees : </span>
                </div>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">{pricingData["perPlate"]?.minAttendees || "NA"} </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Max Attendees : </span>
                </div>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">{pricingData["perPlate"]?.maxAttendees || "NA"} </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Per Plate : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  No
                </div>
              </div>
            </div>
          ) }
          {pricingData["perHour"]?.available ? (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Per Hour : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  Yes
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Price : </span>
                </div>
                <div className="">
                  {pricingData["perHour"]?.price}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Minimum Booking Hrs : </span>
                </div>
                <div className="">
                  {pricingData["perHour"]?.minimumBookingHours}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1">Max Attendees : </span>
                </div>
                <div className="">
                  {pricingData["perHour"]?.maxAttendees}
                </div>
              </div>
            </div>
          ) : (
            <div className="list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Per Hour : </span>
              </div>
              <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
                <div className="prop_timings_day_box">
                    <span className="content text_color_1"> Available : </span>
                </div>
                <div className="">
                  No
                </div>
              </div>
            </div>
          ) }
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Cleaning Fee : </span>
            </div>
            <div className="">
              {pricingData?.cleaningFee || "NA"}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Security Deposit : </span>
            </div>
            <div className="">
              {pricingData?.securityDeposit || "NA"}
            </div>
          </div>
        </div>
      )
    }
    const stayCard = (pricingData : any) => {
      return(
        <div className="list-group" style={{width : "300px"}}>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1">Available : </span>
              </div>
              <div className="">
                Yes
              </div>
          </div>
          <div className="list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1">Sleeping Arrangements : </span>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Single Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.singleBed ? pricingData?.sleepingArrangements?.singleBed : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Twin Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.twinBed ? pricingData?.sleepingArrangements?.twinBed : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Queen Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.queenBed ? pricingData?.sleepingArrangements?.queenBed : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> King Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.kingBed ? pricingData?.sleepingArrangements?.kingBed : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Double Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.doubleBed ? pricingData?.sleepingArrangements?.doubleBed : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Bunk Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.bunkBed ? pricingData?.sleepingArrangements?.bunkBed : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Mattress : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.mattress ? pricingData?.sleepingArrangements?.mattress : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Sofa Cum Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.sofaCumBed ? pricingData?.sleepingArrangements?.sofaCumBed : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Futon : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.futon ? pricingData?.sleepingArrangements?.futon : 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Murphy Bed : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.murphyBed ? pricingData?.sleepingArrangements?.murphyBed : 0 }
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Tatami Mats : </span>
              </div>
              <div className="">
                {pricingData?.sleepingArrangements?.tatamiMats ? pricingData?.sleepingArrangements?.tatamiMats : 0 }
              </div>
            </div>
          </div>
          <div className="list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Facilities : </span>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Attached Bathrooms : </span>
              </div>
              <div className="">
                {pricingData?.attachedBathrooms || 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Common Bathrooms : </span>
              </div>
              <div className="">
                {pricingData?.commonBathrooms || 0}
              </div>
            </div>
          </div>
          <div className="list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Occupancy : </span>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Base Guests : </span>
              </div>
              <div className="">
                {pricingData?.baseGuests || 0}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Max Guests : </span>
              </div>
              <div className="">
                {pricingData?.maxGuests || 0}
              </div>
            </div>
          </div>
          <div className="list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Pricing : </span>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Weekday Price : </span>
              </div>
              <div className="">
                {pricingData?.weekdayPrice}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Weekend Price : </span>
              </div>
              <div className="">
                {pricingData?.weekendPrice}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "240px"}}>
              <div className="prop_timings_day_box">
                  <span className="content text_color_1"> Extra Guest Fee : </span>
              </div>
              <div className="">
                {pricingData?.extraGuestFee || "NA"}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Meal Option : </span>
            </div>
            <div className="">
              {pricingData?.mealOption}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Cleaning Fee : </span>
            </div>
            <div className="">
              {pricingData?.cleaningFee || "NA"}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between list-group-item" style={{width : "260px"}}>
            <div className="prop_timings_day_box">
                <span className="content text_color_1"> Security Deposit : </span>
            </div>
            <div className="">
              {pricingData?.securityDeposit || "NA"}
            </div>
          </div>
        </div>
      )
    }
    const pricingCard = (type : String) => {
      let pricingData : any = {}
      switch (type) {
        case "film":
          pricingData = data?.pricing?.film;
          return (filmTvCard(pricingData))
        case "tv":
          pricingData = data?.pricing?.tv;
          return (filmTvCard(pricingData))
        case "event":
          pricingData = data?.pricing?.event;
          return (eventCard(pricingData))
        case "stay":
          pricingData = data?.pricing?.stay;
          return (stayCard(pricingData))
        default:
          return <span> No Data Found </span>
      }
    }
    const videoCard = (dataObj) => {
      return(
        <div className='card-body p-9'>
          <label className='fw-bold text-dark'>Video Url : {dataObj?.[`${data?.location_id}`] || "NA"}</label>
          {dataObj?.[`${data?.location_id}`] && (
            <div className='col-lg-6' style={{height:"220px"}}>
              <ReactPlayer
                  url={dataObj?.[`${data?.location_id}`]}
                  // url={"https://youtu.be/T5wR5OSyLxs?si=nRz3cetyEg_iWXaY"}
                  width='100%'
                  height='100%'
                  controls
              />
            </div>
          )}
        </div>
      )
    } 

    return(
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img src={toAbsoluteUrl(data?.images?.[0]?.image)} alt='location image' />
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {data?.location_id}
                      </a>
                      <a href='#'>
                        <KTIcon iconName='verify' className='fs-1 text-primary' />
                      </a>
                    </div>

                    <div className='d-flex flex-column flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='save-deposit' className='fs-4 me-1' />
                        {data?.basic_info?.name}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='technology-3' className='fs-4 me-1' />
                        {data?.user?.personalInfo?.fullName}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTIcon iconName="technology-2" className='fs-4 me-1' />
                        {data?.location_type}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                        {data?.address?.city},{data?.address?.area}
                      </a>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between align-items-start my-4'>
                    <div className='me-2'>
                          {!showUserSelectionDiv && (<button className='btn btn-primary' onClick={handleUser}>Assign User</button>)}
                          {showUserSelectionDiv && (
                              <form  onSubmit={handleSubmit}>
                                  <div>
                                      {/* <select
                                          onChange={handleSelect}
                                          required
                                      >
                                          <option value='' hidden selected>Select user</option>
                                          {usersData?.map((user) => {
                                              return(
                                                  <option value={user?._id}>{user?.personalInfo?.email}{' '}{user?.personalInfo?.mobile}</option>
                                              )
                                          })}
                                      </select> */}
                                      <Autocomplete
                                          disablePortal
                                          id="disable-clearable"
                                          disableClearable
                                          options={dropDownData}
                                          sx={{ width: 300 }}
                                          renderInput={(params) => <TextField {...params} label='Select user' />}
                                          onChange={(event, newValue) => {
                                              handleSelect(newValue)
                                            }} 
                                          />
                                  </div>
                                  {selectedUser && (
                                      <div className='d-flex flex-column mt-2'>
                                          <span>Name : {selectedUser?.personalInfo?.fullName}</span>
                                          <span>Email : {selectedUser?.personalInfo?.email}</span>
                                          <span>Mobile : {selectedUser?.personalInfo?.mobile}</span>
                                      </div>
                                  )}
                                  <div className='d-flex justify-content-between mt-2'>
                                  <button type='button' className='btn btn-secondary' onClick={()=>{
                                      setShowUserSelectionDiv(false)
                                      setSelectedUser(null)
                                  }}>Cancel</button>
                                  <button type='submit' className='btn btn-primary'>Assign</button>
                                  </div>
                              </form>
                          )}
                    </div>
                    <div className="w-100">
                        <select onChange={(e)=> addTags(e.target.value)} id="kt_ecommerce_select2_country" className="form-select form-select-solid" name="add-category" data-kt-ecommerce-settings-type="select2_flags">
                        <option value='' disabled selected>Add to</option>
                            <option value='locations featured'>Featured</option>
                            { (data?.film || data?.pricing?.tv?.available) && <option value='film featured'>Film Featured</option> }
                            { data?.event && <option value='event featured'>Event Featured</option> }
                            { data?.stay && <option value='stay featured'>Stay Featured</option> }
                            <option value='locations toprated'>Exclusive</option>
                            { (data?.film || data?.pricing?.tv?.available) && <option value='film toprated'>Film Exclusive</option> }
                            { data?.event && <option value='event toprated'>Event Exclusive</option> }
                            { data?.stay && <option value='stay toprated'>Stay Exclusive</option> }
                        </select>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='book-open' className='fs-3 text-success me-2' />
                        <div className='fw-bold fs-6 text-gray-400'>Status</div>
                        </div>
                          <div className='fs-2 fw-bolder'>{data?.status}</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='star' className='fs-3 text-danger me-2' />
                        <div className='fw-bold fs-6 text-gray-400'>Plan</div>
                        </div>

                          <div className='fs-2 fw-bolder'>{data?.subscription}</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fw-bold fs-6 text-gray-400'>Views</div>
                        </div>

                          <div className='fs-2 fw-bolder'>{data?.views || '0'}</div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                      <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                      <span className='fw-bolder fs-6'>50%</span>
                    </div>
                    <div className='h-5px mx-3 w-100 bg-light mb-3'>
                      <div
                        className='bg-success rounded h-5px'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-between overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item nav-link text-active-primary me-6 '>
                  <div
                  style={{cursor:"pointer"}}
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (tile === 'Details' && 'active')
                  }
                  onClick={() => onChangeTile('Details')}
                  >
                    Property Details
                  </div>
                </li>
                <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    style={{cursor:"pointer"}}
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Manage' && 'active')
                    }
                    onClick={() => onChangeTile('Manage')}
                  >
                    Manage
                  </div>
                </li>
                <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    style={{cursor:"pointer"}}
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Calendar' && 'active')
                    }
                    onClick={() => onChangeTile('Calendar')}
                  >
                    Calendar
                  </div>
                </li>
                {/* <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Leads' && 'active')
                    }
                    onClick={() => onChangeTile('Leads')}
                  >
                    Leads
                  </div>
                </li> */}
                {/* <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Bookings' && 'active')
                    }
                    onClick={() => onChangeTile('Bookings')}
                  >
                    Bookings
                  </div>
                </li> */}
              </ul>
              <div className='btn btn-primary align-self-center'
                onClick={() => {
                  if(edited === 'true'){
                    navigate(`/apps/property/type-of-property/${data?.location_id}`);
                  }else{
                    navigate(`/apps/property/type-of-property/${data?.location_id}`);
                  }
                }}
              >
                Edit
              </div>
            </div>
          </div>
      </div>
      {showLoader && <PageLoader />}
      {show && (
        <ConfirmationModel
          show={show}
          handleClose={() => setShow(false)}
          handleOk={() => setShow(false)}
          title={data?.location_id}
          body={modelBody}
        />
      )}
      </>
    )
}
export default Listing;