import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty } from "../../services/api";
import { useState,useEffect } from "react";
import FullPageLoader from "./Loader";
import { charactersDigitsValidation,  DigitsNumberValidation, notRequiredNumberValidation  } from "../Validations";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_5 from "../Images/BecomeHost/step_5.png";
import CloseIcon from '@mui/icons-material/Close';
//images end

const PROPERTY_OFFERED = ['Entire Property','Shared Property','Private Cottage','Private Room']
const BEDS = ['King','Queen','Double','Single']
const TIME_OPTIONS = [
    { value: "00:00 AM", label: "00:00 AM" },
    { value: "01:00 AM", label: "01:00 AM" },
    { value: "02:00 AM", label: "02:00 AM" },
    { value: "03:00 AM", label: "03:00 AM" },
    { value: "04:00 AM", label: "04:00 AM" },
    { value: "05:00 AM", label: "05:00 AM" },
    { value: "06:00 AM", label: "06:00 AM" },
    { value: "07:00 AM", label: "07:00 AM" },
    { value: "08:00 AM", label: "08:00 AM" },
    { value: "09:00 AM", label: "09:00 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "01:00 PM", label: "01:00 PM" },
    { value: "02:00 PM", label: "02:00 PM" },
    { value: "03:00 PM", label: "03:00 PM" },
    { value: "04:00 PM", label: "04:00 PM" },
    { value: "05:00 PM", label: "05:00 PM" },
    { value: "06:00 PM", label: "06:00 PM" },
    { value: "07:00 PM", label: "07:00 PM" },
    { value: "08:00 PM", label: "08:00 PM" },
    { value: "09:00 PM", label: "09:00 PM" },
    { value: "10:00 PM", label: "10:00 PM" },
    { value: "11:00 PM", label: "11:00 PM" },
    { value: "11:59 PM", label: "11:59 PM" },
];
const MEAL_OPTIONS = ['No Complimentary','Free Breakfast','Free Breakfast & Lunch','Free Breakfast & Dinner','Free Breakfast, Lunch & Dinner']
const BASIC_ROOM = {
    name : '',
    size : '',
    no_of_beds : 1,
    type_of_bed : 'King',
    air_conditioned : false,
    attached_bathroom : false,
    guests : '',
    price : '',
    extra_guests : '',
    price_per_extra_guest : '',
}
const INITIALDATA = {
    property_offered_as : '',
    rooms : [BASIC_ROOM],
    guests : '',
    price : '',
    extra_guests : '',
    price_per_extra_guest : '',
    dicount : '',
    cleaning_fee : '',
    check_in : '',
    check_out : '',
    special_discount : '',
    meal_option :'',
    outdoor_activities : '',
    noise_restrictions :'',
    noise_restriction_time : '',
    alcohol_policy :'',
    alcohol_consumsion_policy : '',
    caretaker : '',
    cook : '',
    event_parties : '',
    visitor_policy : '',
    couple_friendly_policy : '',
    gathering : '',
    gathering_guidlines : '',
    id_proof : '',
    id_proof_match : '',
    valid_id_proof :'',
    early_access : '',
    custom_policies : '',

    full_refund : false,
    partial_refund : false,
    no_refund : false,
    full_refund_days : '',
    partial_refund_percentage : '',
    partial_refund_days : '',
    // no_refund_days : '',
}
const StayNew = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    const [formData,setFormData] = useState(INITIALDATA)
    const [errors,setErrors] = useState({})
    const [bookingTypes,setBookingTypes] = useState({
        film : null,
        event : null,
        stay : null,
    })
    const onRoomDetailsChange = (roomIndex,name,value) => {
        const updatedRooms = formData.rooms.map((room, index) => {
            if (index === roomIndex) {
                return { ...room, [name]: value };
            }
            return room
        });
    
        setFormData({
            ...formData,
            rooms: updatedRooms,
        });
    }
    const addRoom = () => {
        setFormData({
            ...formData,
            rooms : [
                ...formData.rooms,
                BASIC_ROOM,
            ]
        })
    }
    const deleteRoom = (roomIndex) => {
        const updatedRooms = formData.rooms.filter((_, index) => index !== roomIndex);
        setFormData({
          ...formData,
          rooms: updatedRooms,
        });
    }
    const onChange = (name,value) => {
        setFormData({
            ...formData,
            [name] : value,
            check_out : name === 'check_in' ? '' : name === 'check_out' ? value : formData?.check_out,
            ...((name === "full_refund" && value === true ) && { "no_refund" : false }),
            ...((name === "partial_refund" && value === true ) && { "no_refund" : false }),
            ...((name === "no_refund" && value === true ) && { "full_refund" : false, "full_refund_days" : "", "partial_refund" : false, "partial_refund_percentage" : "",  "partial_refund_days" : ""  }),
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }
    const onBack = () => {
        navigate(`/apps/property/amenities/${location_id}`)
    }
    const validateRooms = () => {
        for (let i = 0; i < formData.rooms.length; i++) {
            const room = formData.rooms[i];
            if (!room.name || !room.size || !room.no_of_beds || !room.type_of_bed) {
              return 'Fill all the required fields in rooms';
            }
            if (charactersDigitsValidation(room.name,"",3,50)) {
                return 'Invalid data in the rooms'
            }
            if (validateNumber(room.size)) {
              return 'Invalid data in the rooms';
            }
            if (formData?.property_offered_as !== 'Entire Property' && validateNumber(room.guests)) {
              return 'Invalid data in the rooms';
            }
            if (formData?.property_offered_as !== 'Entire Property' && validateNumber(room.price)) {
              return 'Invalid data in the rooms';
            }
            if (formData?.property_offered_as !== 'Entire Property' && validateNumber(room.extra_guests)) {
              return 'Invalid data in the rooms';
            }
            if (formData?.property_offered_as !== 'Entire Property' && validateNumber(room.price_per_extra_guest)) {
              return 'Invalid data in the rooms';
            }
          }
          return '';
    }
    function convertToMinutes(time) {
        const [hourMinute, period] = time.split(' ');
        let [hour, minute] = hourMinute.split(':').map(Number);
      
        // Convert to 24-hour format
        if (period === 'PM' && hour !== 12) hour += 12;
        if (period === 'AM' && hour === 12) hour = 0;
      
        return hour * 60 + minute; // Return total minutes
    }
    // const validateCheckOut = () => {
    //     if(!formData?.check_in){
    //         return ''
    //     }
    //     const checkInMinutes = convertToMinutes(formData?.check_in);
    //     const checkOutMinutes = convertToMinutes(formData?.check_out);
    //     if (checkOutMinutes <= checkInMinutes) {
    //         return 'Checkout time must be after check-in time';
    //     }else{
    //         return ''
    //     }
    // }
    const validateNumber = (value) => {
        if (value && Number.isInteger(Number(value)) && parseInt(value) > 0) {
            return '';
        } else {
            return 'Enter valid data';
        }    
    }
    const validateForm = () => {
        const newErrors = {
            property_offered_as : formData?.property_offered_as ? '' : 'This selection is required',
            rooms : formData?.property_offered_as ? validateRooms() : '',
            guests : formData?.property_offered_as === 'Entire Property' ? formData?.guests ? DigitsNumberValidation(formData?.guests,"Guests",1,5) : 'Guests is required' : '',
            price : formData?.property_offered_as === 'Entire Property' ? formData?.price ? DigitsNumberValidation(formData?.price,"Price per night",1,8) : 'Price per night is required' : '',
            extra_guests : formData?.extra_guests ? notRequiredNumberValidation(formData?.extra_guests,"Extra guests",1,8) : '',
            price_per_extra_guest : formData?.extra_guests ? formData?.price_per_extra_guest ? notRequiredNumberValidation(formData?.price_per_extra_guest,"Price per guest",1,8) : 'Price per guest is required' : '',
            dicount : formData?.dicount ? (formData?.dicount < 0 || formData?.dicount > 99) ? 'Discount should be 0 to 99' : '' : '',
            cleaning_fee : formData?.cleaning_fee ? notRequiredNumberValidation(formData?.cleaning_fee,"Cleaning fee",1,5) : '',
            special_discount : formData?.special_discount ? (formData?.special_discount < 0 || formData?.special_discount > 99) ? 'Discount should be 0 to 99' : '' : '',
            check_in : formData?.check_in ? '' : 'Check in is required',
            check_out : formData?.check_out ? "" : 'Check out is required',
            meal_option : formData?.meal_option ? '' : 'Meal  option is required',
            noise_restriction_time : formData?.noise_restrictions === 'Yes' ? formData?.noise_restriction_time ? '': 'select time' : '',

            cancellation : (formData?.full_refund||formData?.partial_refund|| formData?.no_refund) ? "" : 'Select minimum one cancellation policy',
            full_refund_days : formData?.full_refund === true ? formData?.full_refund_days ?  validateNumber(formData?.full_refund_days) : 'Add days' : '',
            partial_refund_percentage : formData?.partial_refund === true ? formData?.partial_refund_percentage ? (formData?.partial_refund_percentage < 0 || formData?.partial_refund_percentage > 99) ? 'Percentage should be 0 to 99' : '' : 'Add percentage' : '',
            partial_refund_days : formData?.partial_refund === true ? formData?.partial_refund_days ? validateNumber(formData?.partial_refund_days) : 'Add days' : '',
            // no_refund_days : formData?.no_refund === true ? formData?.no_refund_days ? validateNumber(formData?.no_refund_days) : 'Add hours' : '',
        }
        console.log(newErrors)
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById(key);
                if (inputElement){
                    const headerHeight = 130;
                    scrollToElementWithOffset(inputElement, headerHeight);
                }
                return false
            }
        }
        return true
    }
    function trimFormData(formData) {
        // Check if the formData is an object
        if (typeof formData === 'object' && formData !== null) {
          if (Array.isArray(formData)) {
            // If it's an array, loop through each element and trim spaces in strings
            return formData.map(trimFormData);
          } else {
            // If it's an object, loop through each key-value pair
            const trimmedObj = {};
            for (const key in formData) {
              if (formData.hasOwnProperty(key)) {
                trimmedObj[key] = trimFormData(formData[key]);
              }
            }
            return trimmedObj;
          }
        }
      
        // If it's a string, trim leading and trailing spaces
        if (typeof formData === 'string') {
          return formData.trim();
        }
      
        // If it's null or undefined, return as-is
        return formData;
    }
    const onSubmit = async () => {
        if(!validateForm()){
            // scrollToTop()
            return null
        }
        setShowLoader(true)
        try{
            const resp = await updateProperty({staycation : trimFormData(formData)},location_id)
        }catch(error){
            console.log(error)
            return null
        }
        setShowLoader(false)
        if(bookingTypes?.event){
            navigate(`/apps/property/event/${location_id}`)
            return null
        }
        if(bookingTypes?.film){
            navigate(`/apps/property/film/${location_id}`)
            return null
        }
        navigate(`/apps/property/description/${location_id}`)
    }
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            if(resp.data?.staycation?.rooms?.length > 0){
                setFormData({
                    ...resp.data?.staycation,
                })
            }
            setBookingTypes({
                film : resp?.data?.film || null,
                event : resp?.data?.event || null,
                stay : resp?.data?.stay || null,
            })
        }catch(error){
            console.log(error)
        }
        setShowLoader(false) 
    }
    function scrollToElementWithOffset(element, offset) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
    }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop()
    },[])
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub become_a_host_sub_stay">
                        <span className="sub_text heading_color_1">Staycation / Homestay Details</span>
                        <div className="d-flex flex-row mt">
                            <span className="content heading_color_1 mr-1">Property offered as{' '}<label className="required">*</label></span>
                            <span className="content text_color_1">(Select any one)</span>
                        </div>
                        <div className="TP_main_check_wrap mt">
                            {PROPERTY_OFFERED?.map((each,index)=> {
                                return(
                                    <div key={index} className="TP_main_check_box_wrap mb-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={each}
                                            name={each}
                                            checked={each === formData.property_offered_as}
                                            onChange={()=>onChange('property_offered_as',each)}
                                        />
                                        <label htmlFor={each} className={`content ${each === formData.property_offered_as ? 'active_label' : 'text_color_4'}`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        {errors?.property_offered_as && <span className="content required">{errors?.property_offered_as}</span>}
                        <hr className="w-100 mt-4 mb-4"/>
                        {(formData?.property_offered_as && formData?.property_offered_as === 'Entire Property') && (
                            <span className="content heading_color_1">Entire Property</span>
                        )}
                        {(formData?.property_offered_as && formData?.property_offered_as !== 'Entire Property') && (
                            <span className="content heading_color_1">Shared Property / Private Room / Private Cottage</span>
                        )}
                        {formData?.property_offered_as && (
                            <>
                                {errors?.rooms && <span className="content required">{errors?.rooms}</span>}
                                {formData?.rooms?.map((room,roomIndex) => {
                                    return(
                                        <div key={roomIndex} className="become_a_host_stay_room_wrap mt">
                                            {formData?.rooms?.length > 1 && (
                                                <div className="w-100 d-flex flex-row justify-content-end align-items-center">
                                                    <CloseIcon className="cursor" onClick={()=>deleteRoom(roomIndex)}/>
                                                </div>
                                            )}
                                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span className="content text_color_1 mr-2">Room name : {' '}<label className="required">*</label></span>
                                                <input
                                                    type="text"
                                                    className="price_input"
                                                    name="name"
                                                    placeholder="Enter room name"
                                                    value={room?.name}
                                                    onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                />
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                <span className="content text_color_1 mr-2">Size(sq. ft) : {' '}<label className="required">*</label></span>
                                                <input
                                                    type="number"
                                                    className="price_input"
                                                    name="size"
                                                    placeholder="Enter room size"
                                                    value={room?.size}
                                                    onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                />
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                <span className="content text_color_1 mr-2">No. of Beds : {' '}<label className="required">*</label></span>
                                                <select
                                                    className="price_input"
                                                    name="no_of_beds"
                                                    placeholder="Select number"
                                                    value={room?.no_of_beds}
                                                    onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                >
                                                    <option value={1} className="content text_color_1">1</option>
                                                    <option value={2} className="content text_color_1">2</option>
                                                    <option value={3} className="content text_color_1">3</option>
                                                    <option value={4} className="content text_color_1">4</option>
                                                    <option value={5} className="content text_color_1">5</option>
                                                </select>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                <span className="content text_color_1 mr-2">Type of Bed : {' '}<label className="required">*</label></span>
                                                <select
                                                    className="price_input"
                                                    name="type_of_bed"
                                                    placeholder="Select number"
                                                    value={room?.type_of_bed}
                                                    onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                >
                                                    {BEDS?.map((each,index) => {
                                                        return(
                                                            <option key={index} value={each} className="content text_color_1">{each}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                <span className="content text_color_1 mr-2">Air Conditioned : </span>
                                                <input
                                                    type="checkbox"
                                                    className="become_a_host_check"
                                                    name="air_conditioned"
                                                    checked={room?.air_conditioned}
                                                    onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.checked)}
                                                />
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                <span className="content text_color_1 mr-2">Attached Bathroom : </span>
                                                <input
                                                    type="checkbox"
                                                    className="become_a_host_check"
                                                    name="attached_bathroom"
                                                    checked={room?.attached_bathroom}
                                                    onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.checked)}
                                                />
                                            </div>
                                            {(formData?.property_offered_as && formData?.property_offered_as !== 'Entire Property') && (
                                                <>
                                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                        <span className="content text_color_1 mr-2">Guests Allowed : {' '}<label className="required">*</label></span>
                                                        <input
                                                            type="number"
                                                            className="price_input"
                                                            name="guests"
                                                            placeholder="Enter number"
                                                            value={room?.guests}
                                                            onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                        <span className="content text_color_1 mr-2">Price per Night : {' '}<label className="required">*</label></span>
                                                        <input
                                                            type="number"
                                                            className="price_input"
                                                            name="price"
                                                            placeholder="Enter number"
                                                            value={room?.price}
                                                            onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                        <span className="content text_color_1 mr-2">Extra Guests Allowed : </span>
                                                        <input
                                                            type="number"
                                                            className="price_input"
                                                            name="extra_guests"
                                                            placeholder="Enter number"
                                                            value={room?.extra_guests}
                                                            onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                                        <span className="content text_color_1 mr-2">Extra Person Charge : </span>
                                                        <input
                                                            type="number"
                                                            className="price_input"
                                                            name="price_per_extra_guest"
                                                            placeholder="Enter number"
                                                            value={room?.price_per_extra_guest}
                                                            onChange={(e)=> onRoomDetailsChange(roomIndex,e.target.name,e.target.value)}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )
                                })}
                                <div className='w-100 mt'>
                                    <button type="button" onClick={addRoom} className="btn btn-primary sp_button">Add Room</button>
                                </div>
                            </>
                        )}
                        {(formData?.property_offered_as && formData?.property_offered_as === 'Entire Property') && (
                            <>
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100">
                                    <div className="w-100 mt mr-1">
                                        <label htmlFor='guests' className="content text_color_1">No. of Guests Allowed{' '}<label className="required">*</label></label>
                                        <input
                                            type="number"
                                            className='become_a_host_text_input'
                                            name="guests"
                                            value={formData?.guests }
                                            id="guests"
                                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            placeholder="Enter Number"
                                            autoComplete="off"
                                        />
                                        {errors?.guests && <span className="content required">{errors?.guests}</span>}
                                    </div>
                                    <div className="w-100 mt">
                                        <label htmlFor='price' className="content text_color_1">Price per Night{' '}<label className="required">*</label></label>
                                        <input
                                            type="number"
                                            className='become_a_host_text_input'
                                            name="price"
                                            value={formData?.price }
                                            id="price"
                                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            placeholder="Enter Number"
                                            autoComplete="off"
                                        />
                                        {errors?.price && <span className="content required">{errors?.price}</span>}
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100">
                                    <div className="w-100 mt mr-1">
                                        <label htmlFor='extra_guests' className="content text_color_1">Extra Guests allowed (with Extra Charge)</label>
                                        <input
                                            type="number"
                                            className='become_a_host_text_input'
                                            name="extra_guests"
                                            value={formData?.extra_guests }
                                            id="extra_guests"
                                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            placeholder="Enter Number"
                                            autoComplete="off"
                                        />
                                        {errors?.extra_guests && <span className="content required">{errors?.extra_guests}</span>}
                                    </div>
                                    <div className="w-100 mt">
                                        <label htmlFor='price_per_extra_guest' className="content text_color_1">Extra charge per guest</label>
                                        <input
                                            type="number"
                                            className='become_a_host_text_input'
                                            name="price_per_extra_guest"
                                            value={formData?.price_per_extra_guest }
                                            id="price_per_extra_guest"
                                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            placeholder="Enter Number"
                                            autoComplete="off"
                                        />
                                        {errors?.price_per_extra_guest && <span className="content required">{errors?.price_per_extra_guest}</span>}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="mt w-100">
                            <label htmlFor='dicount' className="content text_color_1">Any Discounts on Weekdays (Mon - Thu)</label>
                            <input
                                type="number"
                                className='become_a_host_text_input'
                                name="dicount"
                                value={formData?.dicount }
                                id="dicount"
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                placeholder="Enter Percentage"
                                autoComplete="off"
                            />
                            {errors?.dicount && <span className="content required">{errors?.dicount}</span>}
                        </div>
                        <div className="mt w-100">
                            <label htmlFor='cleaning_fee' className="content text_color_1">Cleaning Fee (optional)</label>
                            <input
                                type="number"
                                className='become_a_host_text_input'
                                name="cleaning_fee"
                                value={formData?.cleaning_fee }
                                id="cleaning_fee"
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                placeholder="Enter Price"
                                autoComplete="off"
                            />
                            {errors?.cleaning_fee && <span className="content required">{errors?.cleaning_fee}</span>}
                        </div>
                        <span className="content heading_color_1 mt">Timings</span>
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100">
                            <div className="mt mr-1 w-100">
                                <span className="content text_color_1">Check In{' '}<label className="required">*</label></span>
                                <select
                                    className="become_a_host_text_input"
                                    name="check_in"
                                    placeholder="Select time"
                                    value={formData?.check_in}
                                    onChange={(e)=> onChange(e.target.name,e.target.value)}
                                >  
                                    <option value={''} selected disabled>Select time</option>
                                    {TIME_OPTIONS?.map((each,index) => {
                                        return(
                                            <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                        )
                                    })}
                                </select>
                                {errors?.check_in && <span className="content required">{errors?.check_in}</span>}
                            </div>
                            <div className="mt w-100">
                                <span className="content text_color_1">Check Out{' '}<label className="required">*</label></span>
                                <select
                                    className="become_a_host_text_input"
                                    name="check_out"
                                    placeholder="Select time"
                                    value={formData?.check_out}
                                    onChange={(e)=> onChange(e.target.name,e.target.value)}
                                >
                                    <option value={''} selected disabled>Select time</option>
                                    {TIME_OPTIONS?.map((each,index) => {
                                        return(
                                            <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                        )
                                    })}
                                </select>
                                {errors?.check_out && <span className="content required">{errors?.check_out}</span>}
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                            <span className="content text_color_1 mr-2">Can you offer a special discount to attract more SpotLet users?</span>
                            <input
                                type="number"
                                className="price_input"
                                name="special_discount"
                                placeholder="Enter percentage"
                                value={formData?.special_discount}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {errors?.special_discount && <span className="content required">{errors?.special_discount}</span>}
                        </div>
                        <span className="content text_color_1 mt">Complimentary Meals{' '}<label className="required">*</label></span>
                        <div className="TP_main_check_wrap flex-row mt">
                            {MEAL_OPTIONS?.map((each,index)=> {
                                return(
                                    <div key={index} className="TP_main_check_box_wrap mb-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={each}
                                            name='meal_option'
                                            checked={each === formData?.meal_option}
                                            onChange={(e)=>onChange(e.target.name,each)}
                                        />
                                        <label htmlFor={each} className={`content text_color_4`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        {errors?.meal_option && <span className="content required">{errors?.meal_option}</span>}



                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Outdoor Activities</span>
                        <span className="content text_color_1 mt">Do you allow outdoor activities (e.g., BBQs, filming)?</span>
                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='outdoor_activities_yes'
                                    name='outdoor_activities'
                                    checked={'Yes, with pre-approval' === formData?.outdoor_activities}
                                    onChange={(e)=>onChange(e.target.name,'Yes, with pre-approval')}
                                />
                                <label htmlFor='outdoor_activities_yes' className={`content text_color_4`}>Yes, with pre-approval</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='outdoor_activities_no'
                                    name='outdoor_activities'
                                    checked={'No' === formData?.outdoor_activities}
                                    onChange={(e)=>onChange(e.target.name,'No')}
                                />
                                <label htmlFor='outdoor_activities_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>

                        {/* Noise start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Noise Restrictions</span>

                        <span className="content text_color_1 mt">Do you have noise restrictions?</span>
                        <div className="d-flex flex-row justify-content-start align-items-center w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='noise_yes'
                                    name='noise_restrictions'
                                    checked={'Yes' === formData?.noise_restrictions}
                                    onChange={(e)=>onChange(e.target.name,'Yes')}
                                />
                                <label htmlFor='noise_yes' className={`content text_color_4`}>Yes</label>
                                <select
                                    className="price_input ml-2 "
                                    name="noise_restriction_time"
                                    placeholder="Select time"
                                    value={formData?.noise_restriction_time}
                                    onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    disabled={formData?.noise_restrictions === 'No'}
                                >
                                    <option value={''} disabled selected className="content text_color_1">Select time</option>
                                    {TIME_OPTIONS?.map((each,index) => {
                                        return(
                                            <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='noise_no'
                                    name='noise_restrictions'
                                    checked={'No' === formData?.noise_restrictions}
                                    onChange={(e)=>onChange(e.target.name,'No')}
                                />
                                <label htmlFor='noise_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>
                        {errors?.noise_restriction_time && <span className="content required">{errors?.noise_restriction_time}</span>}
                         {/* Noise end */}

                         {/* Alcohol Policy start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Alcohol Policy</span>

                        <span className="content text_color_1 mt">Is alcohol consumption allowed on the property?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='alcohol_policy_yes'
                                    name='alcohol_policy'
                                    checked={'Yes, but guests are expected to drink responsibly' === formData?.alcohol_policy}
                                    onChange={(e)=>onChange(e.target.name,'Yes, but guests are expected to drink responsibly')}
                                />
                                <label htmlFor='alcohol_policy_yes' className={`content text_color_4`}>Yes, but guests are expected to drink responsibly</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='alcohol_policy_no'
                                    name='alcohol_policy'
                                    checked={'No, alcohol consumption is not allowed' === formData?.alcohol_policy}
                                    onChange={(e)=>onChange(e.target.name,'No, alcohol consumption is not allowed')}
                                />
                                <label htmlFor='alcohol_policy_no' className={`content text_color_4`}>No, alcohol consumption is not allowed</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">Who is allowed to consume alcohol on the premises?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='alcohol_consumsion_policy_yes'
                                    name='alcohol_consumsion_policy'
                                    checked={'Only registered guests' === formData?.alcohol_consumsion_policy}
                                    onChange={(e)=>onChange(e.target.name,'Only registered guests')}
                                />
                                <label htmlFor='alcohol_consumsion_policy_yes' className={`content text_color_4`}>Only registered guests</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='alcohol_consumsion_policy_no'
                                    name='alcohol_consumsion_policy'
                                    checked={'Registered guests and their visitors' === formData?.alcohol_consumsion_policy}
                                    onChange={(e)=>onChange(e.target.name,'Registered guests and their visitors')}
                                />
                                <label htmlFor='alcohol_consumsion_policy_no' className={`content text_color_4`}>Registered guests and their visitors</label>
                            </div>
                        </div>
                        {/* Alcohol Policy end */}



                        {/* Caretaker & cook Availability start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Caretaker & cook Availability</span>

                        <span className="content text_color_1 mt">Is a caretaker available on-site for assistance?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='caretaker_yes'
                                    name='caretaker'
                                    checked={'Yes, available on-site for assistance' === formData?.caretaker}
                                    onChange={(e)=>onChange(e.target.name,'Yes, available on-site for assistance')}
                                />
                                <label htmlFor='caretaker_yes' className={`content text_color_4`}>Yes, available on-site for assistance</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='caretaker_no'
                                    name='caretaker'
                                    checked={'No, not available' === formData?.caretaker}
                                    onChange={(e)=>onChange(e.target.name,'No, not available')}
                                />
                                <label htmlFor='caretaker_no' className={`content text_color_4`}>No, not available</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">Is a cook available for guests?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='cook_yes'
                                    name='cook'
                                    checked={'Yes, available upon request (charges may apply).' === formData?.cook}
                                    onChange={(e)=>onChange(e.target.name,'Yes, available upon request (charges may apply).')}
                                />
                                <label htmlFor='cook_yes' className={`content text_color_4`}>Yes, available upon request (charges may apply).</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='cook_no'
                                    name='cook'
                                    checked={'No, cook services are not provided.' === formData?.cook}
                                    onChange={(e)=>onChange(e.target.name,'No, cook services are not provided.')}
                                />
                                <label htmlFor='cook_no' className={`content text_color_4`}>No, cook services are not provided.</label>
                            </div>
                        </div>
                        {/* Caretaker & cook Availability end */}



                        {/* Accommodation Policies start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Accommodation Policies</span>

                        <span className="content text_color_1 mt">Is the property available for events or parties?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='event_parties_yes'
                                    name='event_parties'
                                    checked={'No, only for stay purposes' === formData?.event_parties}
                                    onChange={(e)=>onChange(e.target.name,'No, only for stay purposes')}
                                />
                                <label htmlFor='event_parties_yes' className={`content text_color_4`}>No, only for stay purposes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='event_parties_no'
                                    name='event_parties'
                                    checked={'Yes, but prior approval is required for commercial events or parties' === formData?.event_parties}
                                    onChange={(e)=>onChange(e.target.name,'Yes, but prior approval is required for commercial events or parties')}
                                />
                                <label htmlFor='event_parties_no' className={`content text_color_4`}>Yes, but prior approval is required for commercial events or parties</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">What is the visitor policy?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='visitor_policy_yes'
                                    name='visitor_policy'
                                    checked={'Visitors are allowed during the day and must leave by 10:00 PM' === formData?.visitor_policy}
                                    onChange={(e)=>onChange(e.target.name,'Visitors are allowed during the day and must leave by 10:00 PM')}
                                />
                                <label htmlFor='visitor_policy_yes' className={`content text_color_4`}>Visitors are allowed during the day and must leave by 10:00 PM</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='visitor_policy_no'
                                    name='visitor_policy'
                                    checked={'Visitors are not allowed' === formData?.visitor_policy}
                                    onChange={(e)=>onChange(e.target.name,'Visitors are not allowed')}
                                />
                                <label htmlFor='visitor_policy_no' className={`content text_color_4`}>Visitors are not allowed</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">Is the property couple-friendly?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='couple_friendly_policy_yes'
                                    name='couple_friendly_policy'
                                    checked={'Yes, unmarried couples are welcome' === formData?.couple_friendly_policy}
                                    onChange={(e)=>onChange(e.target.name,'Yes, unmarried couples are welcome')}
                                />
                                <label htmlFor='couple_friendly_policy_yes' className={`content text_color_4`}>Yes, unmarried couples are welcome</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='couple_friendly_policy_no'
                                    name='couple_friendly_policy'
                                    checked={'No, only married couples are allowed' === formData?.couple_friendly_policy}
                                    onChange={(e)=>onChange(e.target.name,'No, only married couples are allowed')}
                                />
                                <label htmlFor='couple_friendly_policy_no' className={`content text_color_4`}>No, only married couples are allowed</label>
                            </div>
                        </div>
                        {/* Accommodation Policies end */}



                        {/* Events & party Guidelines start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Events & party Guidelines</span>

                        <span className="content text_color_1 mt">Is prior notification required for planning a gathering?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='gathering_yes'
                                    name='gathering'
                                    checked={'Yes, guests must inform the host in advance' === formData?.gathering}
                                    onChange={(e)=>onChange(e.target.name,'Yes, guests must inform the host in advance')}
                                />
                                <label htmlFor='gathering_yes' className={`content text_color_4`}>Yes, guests must inform the host in advance</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='gathering_no'
                                    name='gathering'
                                    checked={'No, prior notification is not required' === formData?.gathering}
                                    onChange={(e)=>onChange(e.target.name,'No, prior notification is not required')}
                                />
                                <label htmlFor='gathering_no' className={`content text_color_4`}>No, prior notification is not required</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">Are guests required to follow event guidelines during gatherings?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='gathering_guidlines_yes'
                                    name='gathering_guidlines'
                                    checked={'Yes, adherence to event guidelines is mandatory' === formData?.gathering_guidlines}
                                    onChange={(e)=>onChange(e.target.name,'Yes, adherence to event guidelines is mandatory')}
                                />
                                <label htmlFor='gathering_guidlines_yes' className={`content text_color_4`}>Yes, adherence to event guidelines is mandatory</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='gathering_guidlines_no'
                                    name='gathering_guidlines'
                                    checked={'No specific guidelines need to be followed' === formData?.gathering_guidlines}
                                    onChange={(e)=>onChange(e.target.name,'No specific guidelines need to be followed')}
                                />
                                <label htmlFor='gathering_guidlines_no' className={`content text_color_4`}>No specific guidelines need to be followed</label>
                            </div>
                        </div>
                        {/* Events & party Guidelines end */}



                        {/* Identification Policy start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Identification Policy</span>

                        <span className="content text_color_1 mt">Is ID proof required at check-in?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='id_proof_yes'
                                    name='id_proof'
                                    checked={`Yes, a valid government-issued photo ID (e.g., passport, driver’s license, Aadhaar card) is required` === formData?.id_proof}
                                    onChange={(e)=>onChange(e.target.name,`Yes, a valid government-issued photo ID (e.g., passport, driver’s license, Aadhaar card) is required`)}
                                />
                                <label htmlFor='id_proof_yes' className={`content text_color_4`}>Yes, a valid government-issued photo ID (e.g., passport, driver’s license, Aadhaar card) is required</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='id_proof_no'
                                    name='id_proof'
                                    checked={'No, ID proof is not required at check-in' === formData?.id_proof}
                                    onChange={(e)=>onChange(e.target.name,'No, ID proof is not required at check-in')}
                                />
                                <label htmlFor='id_proof_no' className={`content text_color_4`}>No, ID proof is not required at check-in</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">Does the name on the booking need to match the ID presented?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='id_proof_match_yes'
                                    name='id_proof_match'
                                    checked={'Yes, the name on the booking must match the name on the ID' === formData?.id_proof_match}
                                    onChange={(e)=>onChange(e.target.name,'Yes, the name on the booking must match the name on the ID')}
                                />
                                <label htmlFor='id_proof_match_yes' className={`content text_color_4`}>Yes, the name on the booking must match the name on the ID</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='id_proof_match_no'
                                    name='id_proof_match'
                                    checked={'No, the name on the booking does not need to match the ID' === formData?.id_proof_match}
                                    onChange={(e)=>onChange(e.target.name,'No, the name on the booking does not need to match the ID')}
                                />
                                <label htmlFor='id_proof_match_no' className={`content text_color_4`}>No, the name on the booking does not need to match the ID</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">What happens if valid ID is not provided at check-in?</span>
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt mr-2">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='valid_id_proof_yes'
                                    name='valid_id_proof'
                                    checked={'Entry will be denied, and no refund may be provided' === formData?.valid_id_proof}
                                    onChange={(e)=>onChange(e.target.name,'Entry will be denied, and no refund may be provided')}
                                />
                                <label htmlFor='valid_id_proof_yes' className={`content text_color_4`}>Entry will be denied, and no refund may be provided</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='valid_id_proof_no'
                                    name='valid_id_proof'
                                    checked={'Entry will be allowed, but additional verification may be needed' === formData?.valid_id_proof}
                                    onChange={(e)=>onChange(e.target.name,'Entry will be allowed, but additional verification may be needed')}
                                />
                                <label htmlFor='valid_id_proof_no' className={`content text_color_4`}>Entry will be allowed, but additional verification may be needed</label>
                            </div>
                        </div>
                        {/* Identification Policy end */}




                        {/* Cancellation Policy start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Cancellation Policy{' '}<span className="required">*</span></span>
                        <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='full_refund_yes'
                                name='full_refund'
                                checked={formData?.full_refund}
                                onChange={(e)=>onChange(e.target.name,formData?.full_refund ? false : true)}
                            />
                            <label htmlFor='full_refund_yes' className={`content text_color_4`}>Full refund for cancellations made up to{' '}
                            <input
                                type="number"
                                className="price_input"
                                name="full_refund_days"
                                placeholder=""
                                style={{width : '46px'}}
                                value={formData?.full_refund_days}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                                {' '}days before the stay.</label>
                        </div>
                        {errors?.full_refund_days && <span className="content required">{errors?.full_refund_days}</span>}
                        <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='partial_refund_yes'
                                name='partial_refund'
                                checked={formData?.partial_refund}
                                onChange={(e)=>onChange(e.target.name,formData?.partial_refund ? false : true)}
                            />
                            <label htmlFor='partial_refund_yes' className={`content text_color_4`}>Partial refund of{' '}
                            <input
                                type="number"
                                className="price_input"
                                name="partial_refund_percentage"
                                placeholder=""
                                style={{width : '46px'}}
                                value={formData?.partial_refund_percentage}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {' '}% if canceled within{' '}
                            <input
                                type="number"
                                className="price_input"
                                name="partial_refund_days"
                                placeholder=""
                                style={{width : '46px'}}
                                value={formData?.partial_refund_days}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {' '}days before check-in.
                            </label>
                        </div>
                        <div className="d-flex flex-row justify-content-start align-items-start" style={{gap : '5px'}}>
                            {errors?.partial_refund_percentage && <span className="content required">{errors?.partial_refund_percentage}</span>}
                            {errors?.partial_refund_days && <span className="content required">{errors?.partial_refund_percentage &&'and'}</span>}
                            {errors?.partial_refund_days && <span className="content required">{errors?.partial_refund_days}</span>}
                        </div>
                        <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='no_refund_yes'
                                name='no_refund'
                                checked={formData?.no_refund}
                                onChange={(e)=>onChange(e.target.name,formData?.no_refund ? false : true)}
                            />
                            <label htmlFor='no_refund_yes' className={`content text_color_4`}>No refund 
                                {/* for cancellations made less than{' '} */}
                                {/* <input
                                    type="number"
                                    className="price_input"
                                    name="no_refund_days"
                                    placeholder=""
                                    style={{width : '46px'}}
                                    value={formData?.no_refund_days}
                                    onChange={(e)=> onChange(e.target.name,e.target.value)}
                                /> */}
                                {/* {' '}hours before check-in. */}
                            </label>
                        </div>
                        {/* {errors?.no_refund_days && <span className="content required">{errors?.no_refund_days}</span>} */}
                        {errors?.cancellation && <span className="content required">{errors?.cancellation}</span>}
                        {/* Cancellation Policy end */}



                        {/* Custom Policy start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Do you have any specific Policies / Guidelines to mention?</span>
                        <textarea
                            rows={4}
                            columns={55}
                            id='custom_policies'
                            name='custom_policies'
                            value={formData?.custom_policies}
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                            placeholder='Enter your custom policies'
                            className='become_a_host_text_input mt'
                            />
                        {/* Custom Policy end */}
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_5} alt="step_5" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">List accommodations, pricing, timings, and policies. Describe facilities like caretakers or cooks and specify property types like shared rooms or private cottages.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" className="btn btn-primary sp_button" onClick={onSubmit}>Save & Continue</button>
        </div>
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default StayNew;