import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

import TypeOfProperty from './Pages/TypeOfProperty'
import BasicInformation from './Pages/BasicInformation'
import Activities from './Pages/Activities'
import Amenities from './Pages/Amenities'
import StayNew from './Pages/StayNew'
import EventNew from './Pages/EventNew'
import FilmNew from './Pages/FilmNew'
import Description from './Pages/Description'
import Gallery from './Pages/Gallery'
import ContactDetails from './Pages/ContactDetails'
const locationsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Property Management',
    path: '/apps/property/type-of-property/*',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PropertyManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='type-of-property/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Type of Property</PageTitle>
              <TypeOfProperty/>
            </>
          }
        />
        <Route
          path='basic-information/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Property Details</PageTitle>
              <BasicInformation/>
            </>
          }
        />
        <Route
          path='activities/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Type of Events Allowed</PageTitle>
              <Activities/>
            </>
          }
        />
        <Route
          path='amenities/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>General Amenities & Features</PageTitle>
              <Amenities/>
            </>
          }
        />
        <Route
          path='Stay/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Staycation / Homestay Details</PageTitle>
              <StayNew/>
            </>
          }
        />
        <Route
          path='event/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Venue Information</PageTitle>
              <EventNew/>
            </>
          }
        />
        <Route
          path='film/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Film / Tv Shooting Access & Usage Policies</PageTitle>
              <FilmNew/>
            </>
          }
        />
        <Route
          path='description/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Property Description</PageTitle>
              <Description/>
            </>
          }
        />
        <Route
          path='gallery/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Upload Images & Videos</PageTitle>
              <Gallery/>
            </>
          }
        />
        <Route
          path='contact-details/*'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Property Owner / Manager Details</PageTitle>
              <ContactDetails/>
            </>
          }
        />
        </Route>
      <Route index element={<Navigate to='/apps/property/type-of-property/*' />} />
    </Routes>
  )
}

export default PropertyManagementPage
