import { DataGrid } from "@mui/x-data-grid";
import "./dataTable.scss";
import Moment from 'moment';
import { KTIcon } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import { useNavigate, Link } from "react-router-dom";
import VerifyModal from "../Modals/VerifyModal";
import VerifyReasonModal from "../Modals/VeirifyReasonModal";
import ListingDetailsModal from "../Modals/ListingDetailsModal";
import { useState } from "react";

export const LocationDataTable = ({
  tableName,
  data,
  totalCount,
  editLocation,
  deactivateLocation,
  verifyLocation,
  approveLocation,
  rejectLocation,
  activateLocation,
  deleteListing,
  editedLisitng,
  moveListing,
}) => {
  let navigate = useNavigate();
  const { currentUser } = useAuth();
  const [showVerify, setShowVerify] = useState(false);
  const [showVerifyReason, setShowVerifyReason] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [lisitngId, setListingId] = useState("");
  const [type, setType] = useState("");
  const [showDetails, setShowDetails] = useState("");
  const [detailsModalBody, setDetailsModalBody] = useState({});
  const  [moveType,setMoveType] = useState('')
  const TYPES = [
    {
      type: "edit",
      func: editLocation,
    },
    {
      type: "Deactivated",
      func: deactivateLocation,
    },
    {
      type: "verify",
      func: verifyLocation,
    },
    {
      type: "Approved",
      func: approveLocation,
    },
    {
      type: "Rejected",
      func: rejectLocation,
    },
    {
      type: "activate",
      func: activateLocation,
    },
    {
      type: "Delete",
      func: deleteListing,
    },
    {
      type: "Deny",
      func: deleteListing,
    },
    {
      type: "move",
      func: moveListing,
    },
  ];
  const confirmVerify = () => {
    for (let i of TYPES) {
      if(type === 'edit'){
        navigate(`/apps/property/type-of-property/${lisitngId}`);
        return null;
      }
      if (i.type === type) {
        i.func(lisitngId, type,moveType);
        return null;
      }
    }
  };
  const confirmVerifyReason = (data) => {
    for (let i of TYPES) {
      if (i.type === type) {
        i.func(lisitngId, type, data);
        return null;
      }
    }
  };
  const onAction = (id, type,moveType) => {
    setListingId(id);
    setType(type);
    if(moveType) setMoveType(moveType)
    setModalTitle(`${type.charAt(0).toUpperCase() + type.slice(1)} ${tableName === "Edited_locations" ? "Editing" : "Listing"}`);
    setModalBody(`Are you sure, You want to ${type} ${tableName === "Edited_locations" ? "Editing" : "Listing"} ?`);
    setShowVerify(true);
  };
  const onActionReason = (id, type) => {
    setListingId(id);
    setType(type);
    setModalTitle(`${type.charAt(0).toUpperCase() + type.slice(1)} Listing`);
    setModalBody(`Are you sure, You want to ${type} Lisitng ?`);
    setShowVerifyReason(true);
  };
  const handleDetails = (data) => {
    setDetailsModalBody(data);
    setShowDetails(true);
  };
  const columns = [
    {
      field: "location_id",
      headerName: "Location ID",
      width: 150,
      renderHeader: () => <th className="min-w-150px">Location ID</th>,
      renderCell: (record) => (
        <div className="text-dark fw-bold text-hover-primary fs-6">
          {record.row.location_id}
        </div>
      ),
    },
    {
      field: "country",
      headerName: "Region",
      width: 130,
      renderHeader: () => <th className="min-w-150px">Region</th>,
      renderCell: (record) => (
        <div className="text-dark fw-bold text-hover-primary fs-6">
          {record.row?.address.country}
        </div>
      ),
    },
    {
      field: "location_type",
      headerName: "Location Type",
      width: 150,
      renderHeader: () => <th className="min-w-150px">Location Type</th>,
      renderCell: (record) => (
        <div className="text-dark fw-bold text-hover-primary fs-6">
          {record.row?.location_type}
        </div>
      ),
    },
    {
      field: "user_id",
      headerName: "User",
      width: 250,
      renderHeader: () => <th className="min-w-150px">User</th>,
      renderCell: (record) => (
        <div className="text-dark fw-bold text-hover-primary fs-6">
          {record.row?.user_id}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,
      renderHeader: () => <th className="min-w-150px">Date</th>,
      renderCell: (record) => (
        <div className="text-dark fw-bold text-hover-primary fs-6">
          {Moment(record.row.createdAt).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderHeader: () => <th className="min-w-150px">Status</th>,
      renderCell: (record) => (
        <div className="text-dark fw-bold text-hover-primary fs-6">
            <span className={`badge
              badge-light-primary
              ${tableName === 'Approved_Locations' && 'badge-light-success'}
              ${tableName === 'Location_Requests' && 'badge-light-warning'}
              ${tableName === 'Location_for_Approval' && 'badge-light-warning'}
              ${tableName === 'Rejected_Locations' && 'badge-light-danger'}
              ${tableName === 'Deactivated_Locations' && 'badge-light-danger'}
              ${tableName === 'Edited_locations' && 'badge-light-warning'}
              `}>
              {record.row?.status}
            </span>
        </div>
      ),
    },
    {
      headerName: "Actions",
      width: 300,
      renderHeader: () => <th className="min-w-150px">Actions</th>,
      renderCell: (record) => (
        <div className="d-flex align-items-center w-100">
          {(record?.row?.status && currentUser?.permissions?.[tableName]?.read) &&(
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              title="View Location"
            >
              <Link
                to={`/apps/listing-management/${record.row.location_id}?edited=${editedLisitng}`}
                style={{ textDecoration: "none" }}
              >
                <KTIcon iconName="magnifier" className="fs-3" />
              </Link>
            </div>
          )}
          {currentUser?.permissions?.[tableName]?.write && (
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => onAction(record?.row?.location_id, "edit")}
              title="Edit Location"
            >
              <KTIcon iconName="pencil" className="fs-3" />
            </div>
          )}
          {(verifyLocation && currentUser?.permissions?.[tableName]?.write) && (
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => onAction(record.row?.location_id, "verify")}
              title="Verify Location"
            >
              <KTIcon iconName="verify" className="fs-3" />
            </div>
          )}
          {(approveLocation && currentUser?.permissions?.[tableName]?.write ) && (
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => onAction(record.row?.location_id, "Approved")}
              title="Approve Location"
            >
              <KTIcon iconName="verify" className="fs-3" />
            </div>
          )}
          {(activateLocation && currentUser?.permissions?.[tableName]?.write)  && (
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => onAction(record.row?.location_id, "activate")}
              title="Activate Location"
            >
              <KTIcon iconName="verify" className="fs-3" />
            </div>
          )}
          {(rejectLocation && currentUser?.permissions?.[tableName]?.write) && (
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() =>
                onActionReason(record.row?.location_id, "Rejected")
              }
              title="Reject Location"
            >
              <KTIcon iconName="cross-circle" className="fs-3" />
            </div>
          )}
          {(deactivateLocation && currentUser?.permissions?.[tableName]?.write) && (
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() =>
                onActionReason(record?.row?.location_id, "Deactivated")
              }
              title="Deactivated Location"
            >
              <KTIcon iconName="cross-circle" className="fs-3" />
            </div>
          )}
          {(moveListing && currentUser?.permissions?.[tableName]?.write) && (
            <select className="form-select form-select-solid" style={{width : '120px'}} onChange={(e)=> onAction(record?.row?.location_id,'move', e.target.value,)}>
              <option value={''} selected hidden disabled>Move</option>
              {currentUser?.permissions?.Move_to_Listing_Requests?.write && <option value={'Under Review'}>Listing Requests</option>}
              {currentUser?.permissions?.Move_to_Listing_for_Approval?.write && <option value={'Verified'}>Listing for Approval</option>}
              {currentUser?.permissions?.Move_to_Approved_Listing?.write && <option value={'Approved'}>Approved Listing</option>}
            </select>
          )}
          {(deleteListing && currentUser?.permissions?.[tableName]?.delete) && (
            <div
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() =>
                onAction(record?.row?.location_id, tableName === 'Edited_locations' ? 'Deny' : 'Delete')
              }
              title={tableName === 'Edited_locations' ? 'Deny Edit' : 'Delete Location'}
            >
              <KTIcon iconName="trash" className="fs-3" />
            </div>
          )}
          {(record?.row?.logs?.reason && (record?.row?.status === 'Deactivated' || record?.row?.status === 'Rejected')) && (
            <button
              type="button"
              onClick={() => handleDetails(record?.row?.logs)}
              title="View Details"
              className="btn btn-light btn-active-light-primary my-1"
            >
              Details
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        pageSize={50}
        rowCount={totalCount}
        //rowsPerPageOptions={[9]}
        getRowId={(row) => row.location_id}
        //paginationMode="server"
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid var(--bs-gray-100)",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
        }}
      />
      {showVerify && (
        <VerifyModal
          show={showVerify}
          title={modalTitle}
          body={modalBody}
          onHide={() => setShowVerify(false)}
          OnSuccess={() => {
            setShowVerify(false);
            confirmVerify();
          }}
        />
      )}
      {showVerifyReason && (
        <VerifyReasonModal
          show={showVerifyReason}
          title={modalTitle}
          body={modalBody}
          onHide={() => setShowVerifyReason(false)}
          OnSuccess={(data) => {
            setShowVerifyReason(false);
            confirmVerifyReason(data);
          }}
        />
      )}
      {showDetails && (
        <ListingDetailsModal
          show={showDetails}
          title={"Details"}
          data={detailsModalBody}
          onHide={() => setShowDetails(false)}
        />
      )}
    </div>
  );
};
