import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty } from "../../services/api";
import { useState,useEffect } from "react";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_9 from "../Images/BecomeHost/step_9.png";
//images end

const OWNERTYPES = ['Property Owner','Agent','Manager','Housing Association','Tenant']
const LANGUAGES = ['English','Hindi','Telugu']
const countryPhoneCodes = [
    { label: "Afghanistan", value: "+93", flag: "🇦🇫" },
    { label: "Algeria", value: "+213", flag: "🇩🇿" },
    { label: "Angola", value: "+244", flag: "🇦🇴" },
    { label: "Argentina", value: "+54", flag: "🇦🇷" },
    { label: "Australia", value: "+61", flag: "🇦🇺" },
    { label: "Austria", value: "+43", flag: "🇦🇹" },
    { label: "Belgium", value: "+32", flag: "🇧🇪" },
    { label: "Benin", value: "+229", flag: "🇧🇯" },
    { label: "Brazil", value: "+55", flag: "🇧🇷" },
    { label: "Burkina Faso", value: "+226", flag: "🇧🇫" },
    { label: "China", value: "+86", flag: "🇨🇳" },
    { label: "Colombia", value: "+57", flag: "🇨🇴" },
    { label: "Cuba", value: "+53", flag: "🇨🇺" },
    { label: "Denmark", value: "+45", flag: "🇩🇰" },
    { label: "Djibouti", value: "+253", flag: "🇩🇯" },
    { label: "Egypt", value: "+20", flag: "🇪🇬" },
    { label: "France", value: "+33", flag: "🇫🇷" },
    { label: "Germany", value: "+49", flag: "🇩🇪" },
    { label: "Greece", value: "+30", flag: "🇬🇷" },
    { label: "India", value: "+91", flag: "🇮🇳" },
    { label: "Indonesia", value: "+62", flag: "🇮🇩" },
    { label: "Iran", value: "+98", flag: "🇮🇷" },
    { label: "Japan", value: "+81", flag: "🇯🇵" },
    { label: "Kenya", value: "+254", flag: "🇰🇪" },
    { label: "Liberia", value: "+231", flag: "🇱🇷" },
    { label: "Libya", value: "+218", flag: "🇱🇾" },
    { label: "Malaysia", value: "+60", flag: "🇲🇾" },
    { label: "Mexico", value: "+52", flag: "🇲🇽" },
    { label: "Morocco", value: "+212", flag: "🇲🇦" },
    { label: "Myanmar", value: "+95", flag: "🇲🇲" },
    { label: "Netherlands", value: "+31", flag: "🇳🇱" },
    { label: "Nigeria", value: "+234", flag: "🇳🇬" },
    { label: "Pakistan", value: "+92", flag: "🇵🇰" },
    { label: "Philippines", value: "+63", flag: "🇵🇭" },
    { label: "Poland", value: "+48", flag: "🇵🇱" },
    { label: "Russia", value: "+7", flag: "🇷🇺" },
    { label: "Singapore", value: "+65", flag: "🇸🇬" },
    { label: "South Africa", value: "+27", flag: "🇿🇦" },
    { label: "South Korea", value: "+82", flag: "🇰🇷" },
    { label: "Spain", value: "+34", flag: "🇪🇸" },
    { label: "Sri Lanka", value: "+94", flag: "🇱🇰" },
    { label: "Sweden", value: "+46", flag: "🇸🇪" },
    { label: "Switzerland", value: "+41", flag: "🇨🇭" },
    { label: "Turkey", value: "+90", flag: "🇹🇷" },
    { label: "United Kingdom", value: "+44", flag: "🇬🇧" },
    { label: "United States", value: "+1", flag: "🇺🇸" },
    { label: "Vietnam", value: "+84", flag: "🇻🇳" }
  ];

const ContactDetails = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    const [status,setStatus] = useState('')
    const [formData,setFormData] = useState({
        contact_person_designation : '',
        contact_person_name : '',
        contact_person_number :'',
        contact_person_email :'',
        alternate_person_name : '',
        alternate_person_number : '',
        alternate_person_email : '',
        languages : [],
        privacy_policy : null,
        terms_and_conditions : null,
        sharing_information : null,
    })
    const [errors,setErrors] = useState({})
    const onChange = (name,value) => {
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }
    const onChangeInArrey = (name,value) => {
        setFormData((prevState) => {
            const updatedPolicies = prevState?.[name]?.includes(value)
              ? prevState?.[name]?.filter(policy => policy !== value)
              : [...prevState?.[name], value];
            
            return { ...prevState, [name]: updatedPolicies };
        });
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }
    const validatePhoneNumber = (mobile) => {
        // Extract country code from the mobile number
        const countryCode = countryPhoneCodes.find(({ value }) => mobile.startsWith(value))?.value;
        if (!countryCode) {
        return 'Invalid mobile number';
        }
        // Check if the country code exists in the list
        const country = countryPhoneCodes.find(c => c.value === countryCode.toString());
        if (!country) {
        return `Invalid mobile number`;
        }
        // Country-specific patterns
        const countrySpecificPatterns = {
        "+91": /^[6-9]\d{9}$/,       // India: 10 digits, starts with 6-9
        "+1": /^\d{10}$/,            // US/Canada: 10 digits
        "+44": /^\d{10}$/,           // UK: 10 digits
        "+61": /^\d{9}$/,            // Australia: 9 digits
        "+81": /^\d{10}$/,           // Japan: 10 digits
        "+49": /^\d{10,11}$/,        // Germany: 10-11 digits
        "+55": /^\d{10,11}$/,        // Brazil: 10-11 digits
        "+33": /^\d{9}$/,            // France: 9 digits
        "+62": /^\d{9,13}$/,         // Indonesia: 9-13 digits
        "+94": /^\d{9}$/,            // Sri Lanka: 9 digits
        "+27": /^\d{9}$/,            // South Africa: 9 digits
        "+82": /^\d{9,11}$/,         // South Korea: 9-11 digits
        "+234": /^\d{10}$/,          // Nigeria: 10 digits
        "+212": /^\d{9}$/,           // Morocco: 9 digits
        "+84": /^\d{9,11}$/,         // Vietnam: 9-11 digits
        "+52": /^\d{10}$/,           // Mexico: 10 digits
        "+7": /^\d{10}$/,            // Russia: 10 digits
        "+20": /^\d{9}$/,            // Egypt: 9 digits
        };
        // Use the country-specific pattern if defined, otherwise use a default pattern
        const pattern = countrySpecificPatterns[countryCode.toString()] || /^\d{7,14}$/;
        // Remove country code and validate the remaining number
        const numberWithoutCode = mobile.replace(countryCode, '');
        return pattern.test(numberWithoutCode) ? '' : 'Invalid mobile number';
    };
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return regex.test(email) ? '' : 'Invalid email format';
    };
    const validateName = (name) => {
        const regex = /^[A-Za-z\s]+$/;
        const trimmedName = name.trim();
        if (!trimmedName) {
            return "Contact name is required";
        } else if (!regex.test(trimmedName)) {
            return 'Contact name can only contain letters';
        } else if (trimmedName.length < 2) {
            return 'Contact name should be minimum 2 letters';
        }
        return '';
    };
    const validateForm = () => {
        const newErrors = {
            "contact_person_designation": formData?.contact_person_designation ? '' : "Designation is required", 
            "contact_person_name": formData?.contact_person_name ? validateName(formData?.contact_person_name) : "Contact person name is required", 
            "contact_person_number": formData.contact_person_number ? validatePhoneNumber(formData.contact_person_number) : 'Contact number is required',
            "contact_person_email": formData.contact_person_email ? validateEmail(formData.contact_person_email) : 'Email is required',
            "privacy_policy": formData?.privacy_policy === true || formData?.privacy_policy === false ? "" : 'Privacy policy selection is required',
            "terms_and_conditions": formData?.terms_and_conditions === true || formData?.terms_and_conditions === false ? "" : 'Terms and conditions selection is required',
            "sharing_information": formData?.sharing_information === true || formData?.sharing_information === false ? "" : 'This selection is required',
            'alternate_person_number': formData.alternate_person_number ? validatePhoneNumber(formData.alternate_person_number) : '',
            'alternate_person_email': formData.alternate_person_email ? validateEmail(formData.alternate_person_email) : '',
            'languages': formData?.languages?.length > 0 ? '' : "Select atlease one language",
        };
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById(key);
                if (inputElement){
                    const headerHeight = 130;
                    scrollToElementWithOffset(inputElement, headerHeight);
                }
                return false
            }
        }
        return true
    }
    const onBack = () => {
        navigate(`/apps/property/gallery/${location_id}`)
    }
    function trimFormData(formData) {
        // Check if the formData is an object
        if (typeof formData === 'object' && formData !== null) {
          if (Array.isArray(formData)) {
            // If it's an array, loop through each element and trim spaces in strings
            return formData.map(trimFormData);
          } else {
            // If it's an object, loop through each key-value pair
            const trimmedObj = {};
            for (const key in formData) {
              if (formData.hasOwnProperty(key)) {
                trimmedObj[key] = trimFormData(formData[key]);
              }
            }
            return trimmedObj;
          }
        }
      
        // If it's a string, trim leading and trailing spaces
        if (typeof formData === 'string') {
          return formData.trim();
        }
      
        // If it's null or undefined, return as-is
        return formData;
    }
    const onSubmit = async () => {
        if(validateForm()){
            try{
                setShowLoader(true)
                let resp
                if(status === 'Incomplete'){
                    resp = await updateProperty({property_manager_details : trimFormData(formData)},location_id)
                    setShowLoader(false)
                    alert('Property is updated')
                    navigate('/')
                }else{
                    if(status === 'Edited'){
                        resp = await updateProperty({property_manager_details : trimFormData(formData),status : 'Under Review'},location_id)
                    }else{
                        resp = await updateProperty({property_manager_details : trimFormData(formData)},location_id)
                    }
                    setShowLoader(false)
                    alert('Property is updated')
                    navigate('/')
                }
            }catch(error){
                console.log(error)
            }
        }
    }
    function scrollToElementWithOffset(element, offset) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
    }
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop()
    },[])
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            setFormData({
                contact_person_designation : resp?.data?.property_manager_details?.contact_person_designation || '',
                contact_person_name : resp?.data?.property_manager_details?.contact_person_name || '',
                contact_person_number : resp?.data?.property_manager_details?.contact_person_number || '',
                contact_person_email : resp?.data?.property_manager_details?.contact_person_email || '',
                alternate_person_name : resp?.data?.property_manager_details?.alternate_person_name || '',
                alternate_person_number : resp?.data?.property_manager_details?.alternate_person_number || '',
                alternate_person_email : resp?.data?.property_manager_details?.alternate_person_email || '',
                languages : resp?.data?.property_manager_details?.languages || [],
                privacy_policy : resp?.data?.property_manager_details?.privacy_policy || null,
                terms_and_conditions : resp?.data?.property_manager_details?.terms_and_conditions || null,
                sharing_information : resp?.data?.property_manager_details?.sharing_information || null,
            })
            setStatus(resp?.data?.status)
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }

    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    console.log(formData)
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Property Owner / Manager Details</span>
                        <span className="content heading_color_1 mt">Contact Person managing this property{' '}<label className="required">*</label></span>
                        <div className="d-flex flex-column w-100 mt">
                            {OWNERTYPES?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_wrap mb-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={each}
                                            name='contact_person_designation'
                                            checked={each === formData?.contact_person_designation}
                                            onChange={()=>onChange('contact_person_designation',each)}
                                        />
                                        <label htmlFor={each} className={`content text_color_4}`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        {errors?.contact_person_designation && <span className="content required">{errors?.contact_person_designation}</span>}
                        <div className="d-flex flex-column w-100 mt">
                            <label htmlFor='contact_person_name' className="content text_color_1">Contact Person Name{' '}<label className="required">*</label></label>
                            <input
                                type="text"
                                className={errors?.contact_person_name ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                name="contact_person_name"
                                value={formData?.contact_person_name}
                                id="contact_person_name"
                                onChange={(e)=>onChange('contact_person_name',e.target.value)}
                                placeholder="Please enter a name"
                                autoComplete="off"
                            />
                            {errors?.contact_person_name && <span className="content required">{errors?.contact_person_name}</span>}
                        </div>
                        <div className="become_a_host_CD_wrap">
                            <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='contact_person_number' className="content text_color_1">Contact Number{' '}<label className="required">*</label></label>
                                <input
                                    type="text"
                                    className={errors?.contact_person_number ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="contact_person_number"
                                    value={formData?.contact_person_number}
                                    id="contact_person_number"
                                    onChange={(e)=>onChange('contact_person_number',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.contact_person_number && <span className="content required">{errors?.contact_person_number}</span>}
                            </div>
                            <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='contact_person_email' className="content text_color_1">Email Address{' '}<label className="required">*</label></label>
                                <input
                                    type="text"
                                    className={errors?.contact_person_email ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="contact_person_email"
                                    value={formData?.contact_person_email}
                                    id="contact_person_email"
                                    onChange={(e)=>onChange('contact_person_email',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.contact_person_email && <span className="content required">{errors?.contact_person_email}</span>}
                            </div>
                        </div>
                        <div className="d-flex flex-column w-100 mt">
                            <label htmlFor='alternate_person_name' className="content text_color_1">Alternate Person's Name</label>
                            <input
                                type="text"
                                className={errors?.alternate_person_name ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                name="alternate_person_name"
                                value={formData?.alternate_person_name}
                                id="alternate_person_name"
                                onChange={(e)=>onChange('alternate_person_name',e.target.value)}
                                placeholder="Please enter a name"
                                autoComplete="off"
                            />
                            {errors?.alternate_person_name && <span className="content required">{errors?.alternate_person_name}</span>}
                        </div>
                        <div className="become_a_host_CD_wrap">
                            <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='alternate_person_number' className="content text_color_1">Contact Number{' '}<label className="required">*</label></label>
                                <input
                                    type="text"
                                    className={errors?.alternate_person_number ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="alternate_person_number"
                                    value={formData?.alternate_person_number}
                                    id="alternate_person_number"
                                    onChange={(e)=>onChange('alternate_person_number',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.alternate_person_number && <span className="content required">{errors?.alternate_person_number}</span>}
                            </div>
                            <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='alternate_person_email' className="content text_color_1">Email Address{' '}<label className="required">*</label></label>
                                <input
                                    type="text"
                                    className={errors?.alternate_person_email ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="alternate_person_email"
                                    value={formData?.alternate_person_email}
                                    id="alternate_person_email"
                                    onChange={(e)=>onChange('alternate_person_email',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.alternate_person_email && <span className="content required">{errors?.alternate_person_email}</span>}
                            </div>
                        </div>
                        <span className="content heading_color_1 mt">What languages do you speak?{' '}<label className="required">*</label></span>
                        <div className="d-flex flex-row flex-wrap w-100 mt">
                            {LANGUAGES?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_wrap mb-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={each}
                                            name='languages'
                                            checked={formData?.languages?.includes(each)}
                                            onChange={()=>onChangeInArrey('languages',each)}
                                        />
                                        <label htmlFor={each} className={`content text_color_4}`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        {errors?.languages && <span className="content required">{errors?.languages}</span>}
                        <span className="content heading_color_1 mt">Terms and Conditions</span>
                        <div className="TP_check_box_wrap mt w-100">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='privacy_policy'
                                name='privacy_policy'
                                checked={formData?.privacy_policy === true}
                                disabled
                            />
                            <label htmlFor='privacy_policy' className={`content text_color_4}`}>I have read and agree to the privacy policy{' '}<label className="required">*</label></label>
                        </div>
                        {errors?.privacy_policy && <span className="content required">{errors?.privacy_policy}</span>}
                        <div className="TP_check_box_wrap mt w-100">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='terms_and_conditions'
                                name='terms_and_conditions'
                                checked={formData?.terms_and_conditions === true}
                                disabled
                            />
                            <label htmlFor='terms_and_conditions' className={`content text_color_4}`}>I agree to the Term and Conditions{' '}<label className="required">*</label></label>
                        </div>
                        {errors?.terms_and_conditions && <span className="content required">{errors?.terms_and_conditions}</span>}
                        <div className="TP_check_box_wrap mt w-100">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='sharing_information'
                                name='sharing_information'
                                checked={formData?.sharing_information}
                                disabled
                            />
                            <label htmlFor='sharing_information' className={`content text_color_4}`}>I Consent to SpotLet using my information{' '}<label className="required">*</label></label>
                        </div>
                        {errors?.sharing_information && <span className="content required">{errors?.sharing_information}</span>}
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_9} alt="step_9" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">Add contact information for the property owner or manager. Ensure smooth communication for inquiries, bookings, and listing updates.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" className="btn btn-primary sp_button" onClick={onSubmit}>{status === 'Incomplete' ? 'Save & Continue' : 'Submit'}</button>
        </div>
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default ContactDetails;