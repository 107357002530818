import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FullPageLoader = ({text}) => {
    return(
        <div>
            <Backdrop
                sx={{ display : 'flex',flexDirection : 'column',color: '#EA4335', zIndex: (theme) => theme.zIndex.drawer+1 }}
                open={true}
            >   
                <div className='mb-2'>
                    <span className='content text_color_2'>{text}</span>
                </div>
                <CircularProgress color="inherit" size = {100} thickness = {2}/>
            </Backdrop>
        </div>
    )
}
export default FullPageLoader;