import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty } from "../../services/api";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_4 from "../Images/BecomeHost/step_4.png";
import { useState,useEffect } from "react";
//images end

const RESIDENTIAL_INDOOR_SPACES = [
    "Living Room",
    "Dining Room",
    "Kitchen",
    "Bedroom",
    "Bathroom",
    "Reading Room",
    "Library",
    "Home Theater",
    "Game Room",
    "Wine Cellar",
    "Laundry Room",
    "Basement",
    "Gym/Fitness Room",
    "Kids Play Room",
    "Storage Room",
    "Sauna/Steam Room",
    "Walk-in Closet"
]
const RESIDENTIAL_OUTDOOR_SPACES = [
    "Garden/Yard",
    "Patio/Deck",
    "Swimming Pool Area",
    "Outdoor Dining Area",
    "Rooftop Terrace",
    "Gazebo",
    "Outdoor Kitchen",
    "BBQ Area",
    "Fire Pit Area",
    "Tennis Court",
    "Basketball Court",
    "Pond/Fountain Area",
    "Children's Play Area",
    "Greenhouse",
    "Vegetable Garden",
    "Driveway/Parking Area"
]
const COMMERCIAL_INDOOR_SPACES = [
    "Lobby/Reception Area",
    "Conference Room",
    "Private Offices",
    "Open Workspaces",
    "Break Room/Cafeteria",
    "Meeting Room",
    "Training Room",
    "IT/Data Center",
    "Storage Room",
    "Server Room",
    "Restrooms",
    "Showroom/Retail Space",
    "Warehouse",
    "Workshop/Studio",
    "Exhibit/Display Area",
    "Waiting Area",
    "Copy/Printing Room",
    "Gym/Fitness Room (for employee use)"
]
const COMMERCIAL_OUTDOOR_SPACES = [
    "Parking Lot",
    "Outdoor Seating Area",
    "Loading Dock",
    "Service Yard",
    "Entrance Courtyard",
    "Landscaped Gardens",
    "Smoking Area",
    "Bike Parking Area",
    "Rooftop Garden",
    "Outdoor Advertisement Spaces (e.g., billboard area)",
    "Drive-thru Area"
]
const EVENT_INDOOR_SPACES = [
    "Ballroom",
    "Banquet Hall",
    "Conference Room",
    "Exhibition Hall",
    "Auditorium",
    "Green Room",
    "VIP Lounge",
    "Private Dining Room",
    "Meeting Rooms",
    "Dance Floor",
    "Stage Area",
    "Restrooms/Changing Rooms",
    "Bar Area",
    "Coat Check Room",
    "Kitchen (for catering)"
]
const EVENT_OUTDOOR_SPACES = [
    "Courtyard",
    "Garden",
    "Outdoor Pavilion",
    "Rooftop Terrace",
    "Poolside Area",
    "Event Lawn",
    "Patio",
    "Tented Area",
    "Outdoor Stage",
    "Parking Area",
    "Fire Pit Lounge",
    "Walkway for Processions"
]
const RECREATIONAL_INDOOR_SPACES = [
    "Fitness Center/Gym",
    "Spa",
    "Indoor Pool",
    "Game Room (Billiards, Table tennis etc..)",
    "Bowling Alley",
    "Theater/Cinema Room",
    "Yoga/Wellness Studio",
    "Climbing Wall (indoor)",
    "Locker Rooms",
    "Cafe/Lounge Area",
    "Skating Rink (indoor)",
    "Arcade Room",
    "Sauna/Steam Room"
]
const RECREATIONAL_OUTDOOR_SPACES = [
    "Tennis Court",
    "Basketball Court",
    "Golf Course",
    "Outdoor Pool",
    "Running Track",
    "Children’s Playground",
    "Amphitheater",
    "Picnic Area",
    "Sports Field (soccer, baseball)",
    "Equestrian Area",
    "Nature Trail/Hiking Path",
    "Skate Park",
    "Mini-Golf Course",
    "Rock Climbing Area",
    "Zip Line/Course",
    "Fishing Pond/Lake"
]
const UNIQUE_INDOOR_SPACES = [
    "Art Gallery",
    "Exhibit/Display Room",
    "Wine Tasting Room",
    "Workshop/Creative Studio",
    "Soundproof Recording Studio",
    "Library/Archives",
    "Meditation Room",
    "Antique Room",
    "Observatory",
    "Vault/Safe Room",
    "Private Theater/Cinema",
    "Museum Room",
    "Experimental Kitchen",
    "VR Experience Room",
    "Game Simulation Room"
]
const UNIQUE_OUTDOOR_SPACES = [
    "Rooftop Observatory/Deck",
    "Zen Garden",
    "Heritage Courtyard",
    "Eco-Pond/Conservation Area",
    "Sculpture Garden",
    "Terrace Garden",
    "Maze/Garden Labyrinth",
    "Open-Air Theatre",
    "Wildlife Habitat/Reserve",
    "Outdoor Art Display Area",
    "Outdoor Meditation Area",
    "Agricultural/Farm Land",
    "Helipad",
    "Stargazing Area",
    "Historic Monument Area"
]



const AMENITIES = [
    {
        name: "Wifi",
        sub_items: []
    },
    {
        name: "Air Conditioning",
        sub_items: [
            "Centralised",
            "Room Controlled"
        ]
    },
    {
        name: "Power Backup",
        sub_items: [
            "Generator",
            "Inverter",
            "Generator & Inverter"
        ]
    },
    {
        name: "Drinking Water",
        sub_items: [
            "Purifier",
            "Bottled Water"
        ]
    },
    {
        name: "Television",
        sub_items: [
            "Smart Tv",
            "Android Tv",
            "Apple Tv"
        ]
    },
    {
        name: "Sofa",
        sub_items: []
    },
    {
        name: "Study Table",
        sub_items: []
    },
    {
        name: "Coffee and tea making facilities",
        sub_items: []
    },
    {
        name: "Clean Towels & Linens",
        sub_items: []
    },
    {
        name: "Comfortable bedding",
        sub_items: []
    },
    {
        name: "Washing Machine",
        sub_items: []
    },
    {
        name: "Refrigerator",
        sub_items: []
    },
    {
        name: "Fully Equipped Kitchen",
        sub_items: []
    },
    {
        name: "Microwave",
        sub_items: []
    },
    {
        name: "Stove/Induction Cooktop",
        sub_items: []
    },
    {
        name: "Utensils Provided",
        sub_items: []
    },
    {
        name: "Dining Table",
        sub_items: []
    },
    {
        name: "Barbeque",
        sub_items: []
    },
    {
        name: "Geyser",
        sub_items: []
    },
    {
        name: "First Aid Kit",
        sub_items: []
    },
    {
        name: "Fire Extinguisher",
        sub_items: []
    },
    {
        name: "Security",
        sub_items: []
    }
];
const ENTERTAINMENT = [
    "Net Cricket",
    "Cricket Ground",
    "Badminton Court",
    "Tennis Court",
    "Basketball Court",
    "Swimming Pool",
    "Cycling",
    "Archery"
]
const FEATURES = [
    {
      name: "Gym/Fitness Room",
      sub_items: []
    },
    {
      name: "Swimming Pool",
      sub_items: [
        "Indoor",
        "Outdoor",
        "Heated"
      ]
    },
    {
      name: "Jacuzzi/Hot Tub",
      sub_items: []
    },
    {
      name: "Sauna/Steam Room",
      sub_items: []
    },
    {
      name: "Wheelchair-Accessible Entrance",
      sub_items: []
    },
    {
      name: "Wheelchair-Friendly Restrooms",
      sub_items: []
    },
    {
      name: "Elevator Access",
      sub_items: []
    },
    {
      name: "EV Charging Station",
      sub_items: []
    },
    {
      name: "Outdoor furniture",
      sub_items: []
    }
  ];

const Amenities = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    const [formData,setFormData] = useState({
        amenities : {},
        features : {},
        entertainment : [],
        indoor_spaces : [],
        outdoor_spaces : [],
    })
    const [activeIndoor,setActiveIndoor] = useState([])
    const [activeOutdoor,setActiveOutdoor] = useState([])
    const [bookingTypes,setBookingTypes] = useState({
        film : null,
        event : null,
        stay : null,
    })
    const handleAmenitiesMainCheck = (name, isChecked,subItems) => {
        setFormData(prevState => {
          const newAmenities = {...prevState.amenities}
          if (isChecked) {
            newAmenities[name] = subItems.length > 0 ? { subOption: null } : {subOption: false};
          } else {
            delete newAmenities[name];
          }
          const newState = { ...prevState,amenities : {...newAmenities} };
          return newState;
        });
    };
    const handleAmenitiesSubCheck = (name, subOption) => {
        setFormData(prevState => {
          const newAmenities = {...prevState.amenities}
          if (newAmenities[name]) {
            newAmenities[name].subOption = subOption;
          }
          const newState = { ...prevState,amenities : {...newAmenities} };
          return newState;
        });
    };

    const handleFeaturesMainCheck = (name, isChecked,subItems) => {
        setFormData(prevState => {
          const newFeatures = {...prevState.features}
          if (isChecked) {
            newFeatures[name] = subItems.length > 0 ? { subOption: null } : {subOption: false};
          } else {
            delete newFeatures[name];
          }
          const newState = { ...prevState,features : {...newFeatures} };
          return newState;
        });
    };
    const handleFeaturesSubCheck = (name, subOption) => {
        setFormData(prevState => {
          const newFeatures = {...prevState.features}
          if (newFeatures[name]) {
            newFeatures[name].subOption = subOption;
          }
          const newState = { ...prevState,features : {...newFeatures} };
          return newState;
        });
    };
    const onSelectIndoor = (each) => {
        setFormData({
            ...formData,
            indoor_spaces: formData?.indoor_spaces?.includes(each)
                ? formData.indoor_spaces.filter(item => item !== each)
                : [...formData.indoor_spaces, each]
        });
    }
    const onSelectOutdoor = (each) => {
        setFormData({
            ...formData,
            outdoor_spaces: formData?.outdoor_spaces?.includes(each)
                ? formData.outdoor_spaces.filter(item => item !== each)
                : [...formData.outdoor_spaces, each]
        });
    }
    const onSelectEntertainment = (each) => {
        setFormData({
            ...formData,
            entertainment: formData?.entertainment?.includes(each)
                ? formData.entertainment.filter(item => item !== each)
                : [...formData.entertainment, each]
        });
    }
    const onBack = () => {
        navigate(`/apps/property/activities/${location_id}`)
    }
    const validateForm = () => {
        const amenitiesCount = Object.keys(formData.amenities).length;
        if (amenitiesCount < 5) {
            alert('Minimum 5 amenities are required')
            return false
        }

        for(const main of Object.keys(formData.amenities)){
            if(formData?.amenities?.[main].subOption === null){
                alert(`Please select a sub-option for ${main}`)
                return false
            }
        }
        for(const main of Object.keys(formData.features)){
            if(formData?.features?.[main].subOption === null){
                alert(`Please select a sub-option for ${main}`)
                return false
            }
        }
        return true
    }
    const onSubmit = async () => {
        if(validateForm()){
            try{
                setShowLoader(true)
                await updateProperty(formData,location_id)
                if(bookingTypes?.stay){
                    setShowLoader(false)
                    navigate(`/apps/property/stay/${location_id}`)
                    return null
                }
                if(bookingTypes?.event){
                    setShowLoader(false)
                    navigate(`/apps/property/event/${location_id}`)
                    return null
                }
                if(bookingTypes?.film){
                    setShowLoader(false)
                    navigate(`/apps/property/film/${location_id}`)
                    return null
                }
            }catch(error){
                console.log(error)
                setShowLoader(false)
            }
        }
    }
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            setFormData({
                amenities : resp?.data?.amenities || {},
                features : resp?.data?.features || {},
                entertainment : resp?.data?.entertainment || [],
                indoor_spaces : resp?.data?.indoor_spaces || [],
                outdoor_spaces : resp?.data?.outdoor_spaces || [],
            })
            switch (resp?.data?.property_type) {
                case 'Residential Properties':
                    setActiveIndoor(RESIDENTIAL_INDOOR_SPACES)
                    setActiveOutdoor(RESIDENTIAL_OUTDOOR_SPACES)
                    break;
                case 'Commercial Properties':
                    setActiveIndoor(COMMERCIAL_INDOOR_SPACES)
                    setActiveOutdoor(COMMERCIAL_OUTDOOR_SPACES)
                    break;
                case 'Event Spaces':
                    setActiveIndoor(EVENT_INDOOR_SPACES)
                    setActiveOutdoor(EVENT_OUTDOOR_SPACES)
                    break;
                case 'Recreational Properties':
                    setActiveIndoor(RECREATIONAL_INDOOR_SPACES)
                    setActiveOutdoor(RECREATIONAL_OUTDOOR_SPACES)
                    break;
                case 'Special/Unique Category Properties':
                    setActiveIndoor(UNIQUE_INDOOR_SPACES)
                    setActiveOutdoor(UNIQUE_OUTDOOR_SPACES)
                    break;
            
                default:
                    setActiveIndoor(RESIDENTIAL_INDOOR_SPACES)
                    setActiveOutdoor(RESIDENTIAL_OUTDOOR_SPACES)
                    break;
            }
            setBookingTypes({
                film : resp?.data?.film || null,
                event : resp?.data?.event || null,
                stay : resp?.data?.stay || null,
            })
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop()
    },[])
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">General Amenities & Features</span>
                        <span className="content heading_color_1 mt">Amenities</span>
                        <div className="TP_check_amenities_wrap mt">
                            {AMENITIES?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_amenities_wrap mt-4">
                                        <div className="w-100 d-flex flex-row justify-content-start align-items-center">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id={'Amenities' + each.name}
                                                name={each.name}
                                                checked={Object.keys(formData?.amenities)?.includes(each.name)}
                                                onChange={(e) =>handleAmenitiesMainCheck(each.name, e.target.checked,each.sub_items)}
                                            />
                                            <label htmlFor={'Amenities' + each.name} className={`content text_color_4`}>{each.name}</label>
                                        </div>
                                        {(each.sub_items.length > 0 && formData?.amenities?.[each.name]) && (
                                            <>
                                            {each?.sub_items?.map((subItem) => {
                                                return(
                                                    <div className="w-100 d-flex flex-row justify-content-start align-items-center ml-4 mt">
                                                        <input
                                                            type="checkbox"
                                                            className="become_a_host_check"
                                                            id={'Amenities' + subItem}
                                                            name={subItem}
                                                            checked={formData?.amenities?.[each.name]?.subOption === subItem}
                                                            onChange={() => handleAmenitiesSubCheck(each.name, subItem)}                           
                                                        />
                                                        <label htmlFor={'Amenities' + subItem} className={`content text_color_4`}>{subItem}</label>
                                                    </div>
                                                )
                                            })}
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1 mt">Features</span>
                        <div className="TP_check_amenities_wrap mt">
                            {FEATURES?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_amenities_wrap mt-4">
                                        <div className="w-100 d-flex flex-row justify-content-start align-items-center">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id={'Features' + each.name}
                                                name={each.name}
                                                checked={formData?.features?.[each.name] !== undefined}
                                                onChange={(e) =>handleFeaturesMainCheck(each.name, e.target.checked,each.sub_items)}
                                            />
                                            <label htmlFor={'Features' + each.name} className={`content text_color_4`}>{each.name}</label>
                                        </div>
                                        {(each.sub_items.length > 0 && formData?.features?.[each.name]) && (
                                            <>
                                            {each?.sub_items?.map((subItem) => {
                                                return(
                                                    <div className="w-100 d-flex flex-row justify-content-start align-items-center ml-4 mt">
                                                        <input
                                                            type="checkbox"
                                                            className="become_a_host_check"
                                                            id={'Features' + subItem}
                                                            name={subItem}
                                                            checked={formData?.features?.[each.name]?.subOption === subItem}
                                                            onChange={() => handleFeaturesSubCheck(each.name, subItem)}                           
                                                        />
                                                        <label htmlFor={'Features' + subItem} className={`content text_color_4`}>{subItem}</label>
                                                    </div>
                                                )
                                            })}
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1 mt">Sports & Fitness</span>
                        <div className="TP_check_wrap mt">
                            {ENTERTAINMENT?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_wrap mb-4">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={'Sports & Fitness' + each}
                                            name={each}
                                            checked={formData?.entertainment?.includes(each)}
                                            onChange={()=>onSelectEntertainment(each)}
                                        />
                                        <label htmlFor={'Sports & Fitness' + each} className={`content text_color_4`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_text heading_color_1">Available Indoor & Outdoor Spaces</span>
                        <span className="content heading_color_1 mt">Indoor Rooms</span>
                        <div className="TP_check_wrap mt">
                            {activeIndoor?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_wrap mb-4">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={'Indoor Rooms' + each}
                                            name={each}
                                            checked={formData?.indoor_spaces?.includes(each)}
                                            onChange={()=>onSelectIndoor(each)}
                                        />
                                        <label htmlFor={'Indoor Rooms' + each} className={`content text_color_4`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1 mt">Outdoor Spaces</span>
                        <div className="TP_check_wrap mt">
                            {activeOutdoor?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_wrap mb-4">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={'Outdoor Spaces' + each}
                                            name={each}
                                            checked={formData?.outdoor_spaces?.includes(each)}
                                            onChange={()=>onSelectOutdoor(each)}
                                        />
                                        <label htmlFor={'Outdoor Spaces' + each} className={`content text_color_4`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_4} alt="step_4" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">Highlight your property’s amenities and features. Provide details about indoor and outdoor spaces to showcase the full potential of your venue.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" className="btn btn-primary sp_button" onClick={onSubmit}>Save & Continue</button>
        </div>
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default Amenities;