import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty, getAllTags } from "../../services/api";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_3 from "../Images/BecomeHost/step_3.png";
import up_arrow from "../Images/BecomeHost/up_arrow.svg";
import down_arrow from "../Images/BecomeHost/down_arrow.svg";
import { useState,useEffect } from "react";
//images end

const Activities = () => {
    const [init, setInit] = useState(false);
    const [data, setData] = useState({
        film : [],
        stay : [],
        personal : [],
        corporate : [],
    });
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    const [bookingTypes,setBookingTypes] = useState({
        film : null,
        event : null,
        stay : null,
    })
    const [formData,setFormData] = useState({
        corporate_activities : [],
        personal_activities : [],
        film_shooting_activities : [],
        stay_activities : [],
    })
    const [types,setTypes] = useState({
        corporate : true,
        personal : true,
        film : true,
        stay : true,
    })
    const onHandleType = (name) => {
        setTypes({
            ...types,
            [name] : !types?.[name]
        })
    }
    const onSelectType = (name, value) => {
        setFormData(prevFormData => {
            const currentValue = prevFormData?.[name] || [];
            let updatedValue;
            if (currentValue.includes(value)) {
                updatedValue = currentValue.filter(item => item !== value);
            } else {
                updatedValue = [...currentValue, value];
            }
            return {
                ...prevFormData,
                [name]: updatedValue,
            };
        });
    };
    const onBack = () => {
        navigate(`/apps/property/basic-information/${location_id}`)
    }
    const onSubmit = async () => {
        if((formData?.corporate_activities.length === 0 && bookingTypes?.event) || 
            (formData?.personal_activities.length === 0 && bookingTypes?.event) ||
            (formData?.film_shooting_activities.length === 0 && bookingTypes?.film) ||
            (formData?.stay_activities.length === 0 && bookingTypes?.stay)
        ){
            alert('Please select atleast one activity in showing sections')
            return null
        }
        try{
            setShowLoader(true)
            await updateProperty(formData,location_id)
            setShowLoader(false)
            navigate(`/apps/property/amenities/${location_id}`)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            setFormData({
                corporate_activities : resp?.data?.corporate_activities || [],
                personal_activities : resp?.data?.personal_activities || [],
                film_shooting_activities : resp?.data?.film_shooting_activities || [],
                stay_activities : resp?.data?.stay_activities || [],
            })
            setBookingTypes({
                film : resp?.data?.film || null,
                event : resp?.data?.event || null,
                stay : resp?.data?.stay || null,
            })
        }catch(error){
            console.log(error)
        }
        setInit(true);
        setShowLoader(false)
    }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    const getTagsData = async () => {
        setShowLoader(true);
        try{
            const resp = await getAllTags({...bookingTypes});
            // console.log("resp?.data", resp?.data);
            setData({
                film : [...resp?.data?.filmTags],
                stay : [...resp?.data?.stayTags],
                corporate : [...resp?.data?.corporateTags],
                personal : [...resp?.data?.personalTags],
            })
        }catch(error){
            // console.log(error?.response?.data?.message);
            const errorMsg = error?.response?.data?.message ? error?.response?.data?.message : "Some error occured";
            console.log("getTagsData", errorMsg);
        }
        setShowLoader(false)
    }
    useEffect(()=> {
        scrollToTop();
        if (init) getTagsData();
    },[init]);
    return (
        <>
       <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Type of Events Allowed</span>
                        {bookingTypes?.event && (
                            <>
                                <hr className="w-100 mt-4 mb-4"/>
                                <div className="d-flex flex-row justify-content-between align-items-center w-100 cursor" onClick={()=>onHandleType('corporate')}>
                                    <span className="content heading_color_1">This Property is Suitable for below Corporate Event activities</span>
                                    <img src={types?.corporate ? up_arrow : down_arrow} alt="arrow"/>
                                </div>
                                {types?.corporate && (
                                    <>
                                        <div className="TP_check_box_wrap mt mb-4">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id='corporate_select_all'
                                                name='corporate_activities'
                                                onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        [e.target.name] : e.target.checked ? [...data?.corporate] : []
                                                    })
                                                }}
                                            />
                                            <label htmlFor='corporate_select_all' className={`content text_color_4`}>Select all</label>
                                        </div>
                                        <div className="TP_check_wrap mt">
                                            {data?.corporate?.map((each,index) => {
                                                return(
                                                    <div key={index} className="TP_check_box_wrap mb-4">
                                                        <input
                                                            type="checkbox"
                                                            className="become_a_host_check"
                                                            id={'corporate' + each}
                                                            name='corporate_activities'
                                                            checked={formData?.corporate_activities?.includes(each)}
                                                            onChange={()=>onSelectType('corporate_activities',each)}
                                                        />
                                                        <label htmlFor={'corporate' + each} className={`content text_color_4`}>{each}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                                <hr className="w-100 mt-4 mb-4"/>
                                <div className="d-flex flex-row justify-content-between align-items-center w-100 cursor" onClick={()=>onHandleType('personal')}>
                                    <span className="content heading_color_1">This Property is Suitable for below Personal Event activities</span>
                                    <img src={types?.personal ? up_arrow : down_arrow} alt="arrow"/>
                                </div>
                                {types?.personal && (
                                    <>
                                        <div className="TP_check_box_wrap mt mb-4">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id='personal_select_all'
                                                name='personal_activities'
                                                onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        [e.target.name] : e.target.checked ? [...data?.personal] : []
                                                    })
                                                }}
                                            />
                                            <label htmlFor='personal_select_all' className={`content text_color_4`}>Select all</label>
                                        </div>
                                        <div className="TP_check_wrap mt">
                                            {data?.personal?.map((each,index) => {
                                                return(
                                                    <div key={index} className="TP_check_box_wrap mb-4">
                                                        <input
                                                            type="checkbox"
                                                            className="become_a_host_check"
                                                            id={'personal'+each}
                                                            name='personal_activities'
                                                            checked={formData?.personal_activities?.includes(each)}
                                                            onChange={()=>onSelectType('personal_activities',each)}
                                                        />
                                                        <label htmlFor={'personal'+each} className={`content text_color_4`}>{each}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {bookingTypes?.film && (
                            <>
                                <hr className="w-100 mt-4 mb-4"/>
                                <div className="d-flex flex-row justify-content-between align-items-center w-100 cursor" onClick={()=>onHandleType('film')}>
                                    <span className="content heading_color_1">This Property is Suitable for below Shooting activities</span>
                                    <img src={types?.film ? up_arrow : down_arrow} alt="arrow"/>
                                </div>
                                {types?.film && (
                                    <>
                                        <div className="TP_check_box_wrap mt mb-4">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id='film_select_all'
                                                name='film_shooting_activities'
                                                onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        [e.target.name] : e.target.checked ? [...data?.film] : []
                                                    })
                                                }}
                                            />
                                            <label htmlFor='film_select_all' className={`content text_color_4`}>Select all</label>
                                        </div>
                                        <div className="TP_check_wrap mt">
                                            {data?.film?.map((each,index) => {
                                                return(
                                                    <div key={index} className="TP_check_box_wrap mb-4">
                                                        <input
                                                            type="checkbox"
                                                            className="become_a_host_check"
                                                            id={'film'+each}
                                                            name='film_shooting_activities'
                                                            checked={formData?.film_shooting_activities?.includes(each)}
                                                            onChange={()=>onSelectType('film_shooting_activities',each)}
                                                        />
                                                        <label htmlFor={'film'+each} className={`content text_color_4`}>{each}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {bookingTypes?.stay && (
                            <>
                                <hr className="w-100 mt-4 mb-4"/>
                                <div className="d-flex flex-row justify-content-between align-items-center w-100 cursor" onClick={()=>onHandleType('stay')}>
                                    <span className="content heading_color_1">This Property is Suitable for below Staycation/Homestay activities</span>
                                    <img src={types?.stay ? up_arrow : down_arrow} alt="arrow"/>
                                </div>
                                {types?.stay && (
                                    <>
                                        <div className="TP_check_box_wrap mt mb-4">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id='stay_select_all'
                                                name='stay_activities'
                                                onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        [e.target.name] : e.target.checked ? [...data?.stay] : []
                                                    })
                                                }}
                                            />
                                            <label htmlFor='stay_select_all' className={`content text_color_4`}>Select all</label>
                                        </div>
                                        <div className="TP_check_wrap mt">
                                            {data?.stay?.map((each,index) => {
                                                return(
                                                    <div key={index} className="TP_check_box_wrap mb-4">
                                                        <input
                                                            type="checkbox"
                                                            className="become_a_host_check"
                                                            id={'stay'+each}
                                                            name='stay_activities'
                                                            checked={formData?.stay_activities?.includes(each)}
                                                            onChange={()=>onSelectType('stay_activities',each)}
                                                        />
                                                        <label htmlFor={'stay'+each} className={`content text_color_4`}>{each}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_3} alt="step_3" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">Specify the activities your property can host—staycations, weddings, reunions, ad film shoots, and more. Let potential clients know what experiences you offer..</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" onClick={onSubmit} className="btn btn-primary sp_button">Save & Continue</button>
        </div>
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default Activities;