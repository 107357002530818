import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import { getPropertiesByStatus ,locationBookings,updatePropertyStatus, addPropertyToEdit} from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { LocationsListFilter } from "../LocationsListFilter";
import { Box, CircularProgress } from "@mui/material";
import { LocationDataTable } from "../LocationDataTable";
import {Modal,Button} from 'react-bootstrap'
import { useAuth } from "../../../auth";
import Reports from "../../Reports/Reports";


const ApprovedLocations = () => {
    const {getSideBarCount} = useAuth()
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>([]);
    const [locationCount,setLocationCount] = useState<any>(0)
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [filterData,setFilterData] = useState<any>({});
    const region = localStorage.getItem("region");
    

    const getLocationData = async () => {
        try{
            // const countResp = await getCounListingsByStatus({status : "Approved", searchTerm: searchTerm.toLocaleUpperCase(),...filterData});
            const resp = await getPropertiesByStatus({page : 1,items : 50,status : "Approved", searchTerm: searchTerm.toLocaleUpperCase(),...filterData, region });
            setLocationCount(Math.ceil(resp?.data?.count/50)) //items per page is 50 So, dividing with 50 to make no of pages appear
            const locData = resp?.data?.data;
            setData(locData);
            setIsLoading(false);
        }catch(error:any){
            console.log(error?.response?.data?.message)
        }
    }

	useEffect(() => {
        getLocationData()
	}, [searchTerm,filterData]);

    const handleChange = async(event,value) => {
        try{
            const resp = await getPropertiesByStatus({page : value,items : 50,status : "Approved", searchTerm: searchTerm.toLocaleUpperCase(),...filterData, region});
            setData(resp?.data?.data);
        }catch(error:any){
            console.log(error?.response?.data?.message)
        }
    };

    const editLocation = async (location_id) => {
        try{
            const resp = await addPropertyToEdit(location_id)
            // alert(resp?.data)
            navigate(`../editedlisting/${location_id}`);
        }catch(error : any){
            console.log(error)
            alert(error?.response?.data?.message)
        }
    }
    const deactivateLocation = async (location_id,type,data) => {
        try{
            const locationResp = await locationBookings(location_id)
            if(locationResp?.data?.count === 0){
                const resp = await updatePropertyStatus(location_id, "Deactivated",data);
                alert("Location : " + location_id + " Deactivated sucessfully")
                getLocationData();
                getSideBarCount()
            }else{
                alert('locations has active bookings')
            }
        }catch(error:any){
            console.log(error?.response?.data?.message)
            alert(error?.response?.data?.message)
        }
    }

     const getView = () => {
        if (isLoading) {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="85vh"
            >
              <CircularProgress size={50} />
            </Box>
          );
        }
    
        return (
          <>
            <LocationDataTable
                    tableName="Approved_Locations"
                    data={data} 
                    totalCount={locationCount}
                    editLocation={editLocation} 
                    deactivateLocation={deactivateLocation}
                    verifyLocation={undefined}
                    approveLocation={undefined}
                    rejectLocation={undefined}
                    activateLocation={undefined}
                    deleteListing={undefined}
                    editedLisitng={true}
                    moveListing={false}
                />
          </>
        );
      };

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search by id/type/user/city'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                    <Reports table='approved'/>
                    <LocationsListFilter filterFormData={setFilterData}/>
                </div>
            </div>
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                    {/* begin::Table */}
                        {getView()}
                    {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
                </div>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Featured Locations</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modelBody}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                        Ok
                    </Button>
                    </Modal.Footer>
                </Modal>
                <div style={{display : 'flex',flexDirection : 'row-reverse',marginRight : "12px"}}>
                    <Stack spacing={2}>
                        <Pagination count={locationCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange}/>
                    </Stack>
                </div>
        </div>

    );
};

export default ApprovedLocations;